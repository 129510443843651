import styled from 'styled-components';

const NoContentWrapper = styled.div`
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #d7e6ff;
  }

  .name {
    flex: 1 0 0;
    color: #1a202c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .badge {
    display: flex;
    height: 24px;
    padding: 2px 8px 2px 6px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: #f1b44c;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`;

const Styles = {
  NoContentWrapper,
};

export default Styles;
