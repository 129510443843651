import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

import { CardKeywordGroupStyles } from './styles';

const CardKeywordGroup = ({ title, value, keyIcon, fileIcon, onClickFunction, soon }) => {
  const { t: translate } = useTranslation();

  return (
    <CardKeywordGroupStyles
      className="d-flex justify-content-between"
      onClick={() => onClickFunction()}
      disabled={soon}
    >
      <div className={soon ? 'd-flex my-auto gap-2' : 'd-flex flex-column justify-content-between'}>
        <span className={soon ? 'card-title m-0' : 'card-title'}>{title}</span>
        {soon && (
          <Badge className="badge-soon d-flex align-items-center gap-05" pill>
            <div className="dot" />
            {translate('Soon')}
          </Badge>
        )}
        {!soon && (
          <div className="d-flex align-items-center gap-2">
            <span className="card-info">{value}</span>
          </div>
        )}
      </div>
      {keyIcon && (
        <div className="icon-key d-flex justify-content-center align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
          >
            <g clipPath="url(#clip0_9787_21964)">
              <path
                d="M26 36.6065C28.5604 34.0461 28.8786 30.0771 26.9525 27.1688L29.182 24.9393L31.3033 27.0606L33.4246 24.9393L31.3033 22.8179L33.4246 20.6966L36.6066 23.8786L38.7279 21.7573L35.5459 18.5753L36.6066 17.5146L34.4853 15.3933L24.8312 25.0474C21.9228 23.1213 17.9538 23.4395 15.3934 25.9999C12.4692 28.9242 12.4692 33.6823 15.3934 36.6065C18.3176 39.5308 23.0758 39.5308 26 36.6065ZM17.5147 28.1212C19.2691 26.3669 22.1244 26.3669 23.8787 28.1212C25.633 29.8756 25.633 32.7309 23.8787 34.4852C22.1244 36.2395 19.2691 36.2395 17.5147 34.4852C15.7604 32.7309 15.7604 29.8756 17.5147 28.1212Z"
                fill="#FAF5FF"
              />
            </g>
            <defs>
              <clipPath id="clip0_9787_21964">
                <rect
                  width="36"
                  height="36"
                  fill="white"
                  transform="translate(0.544159 26) rotate(-45)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
      {fileIcon && (
        <div className="icon-file d-flex justify-content-center align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M26.5373 11.4478C26.472 11.3065 26.3906 11.1718 26.276 11.0572L18.276 3.05717C18.1613 2.9425 18.0266 2.86117 17.8853 2.79584C17.8453 2.77717 17.8026 2.7665 17.76 2.75184C17.648 2.7145 17.5333 2.6905 17.4146 2.68384C17.3866 2.68117 17.3613 2.6665 17.3333 2.6665H7.99998C6.52931 2.6665 5.33331 3.8625 5.33331 5.33317V26.6665C5.33331 28.1372 6.52931 29.3332 7.99998 29.3332H24C25.4706 29.3332 26.6666 28.1372 26.6666 26.6665V11.9998C26.6666 11.9718 26.652 11.9465 26.6493 11.9172C26.6426 11.7985 26.62 11.6838 26.5813 11.5718C26.568 11.5292 26.556 11.4878 26.5373 11.4478ZM22.1146 10.6665H18.6666V7.2185L22.1146 10.6665ZM7.99998 26.6665V5.33317H16V11.9998C16 12.7372 16.596 13.3332 17.3333 13.3332H24L24.0026 26.6665H7.99998Z"
              fill="#FAF5FF"
            />
            <path
              d="M10.6667 15.9998H21.3334V18.6665H10.6667V15.9998ZM10.6667 21.3332H21.3334V23.9998H10.6667V21.3332ZM10.6667 10.6665H13.3334V13.3332H10.6667V10.6665Z"
              fill="#FAF5FF"
            />
          </svg>
        </div>
      )}
    </CardKeywordGroupStyles>
  );
};

CardKeywordGroup.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  keyIcon: PropTypes.bool,
  fileIcon: PropTypes.bool,
  onClickFunction: PropTypes.func,
  soon: PropTypes.bool,
};

export default CardKeywordGroup;
