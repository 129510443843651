import styled from 'styled-components';

export const HeaderKeywordMonitoringStyled = styled.div`
  display: flex;
  flex-direction: column;

  .container-full {
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 0.5rem;
    border: 1px solid #ced4da;
    background: #ffffff;
  }

  .container-inline {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 1.4rem;
    padding: 1.5rem 1.5rem 1.5rem 0.125rem;
  }
`;
