import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Badge, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { map, size, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../../../helpers/firebaseHelper';
import { formatDateTimestampNumber } from 'utils';
import {
  guidelineDeliveryTypeOptionsKanban,
  guidelineTypeOptions,
  initialGuidelineKanbanState,
} from '../../../../constants/index';
import { KanbanCardGuidelineLayout } from './styles';
import BadgeDenied from '../Badge-Denied';
import ButtonArchive from '../Button-Archive';
import ButtonView from '../Button-View';
import MenuDropdown from './components/Menu-Dropdown';
import UserAvatar from 'components/UserAvatar';

const KanbanCardGuideline = ({
  dragging,
  data,
  setDraggingFromColumn,
  setGuidelineDataClicked,
  toggleModalArchive,
}) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const accountId = user.account;
  const [guideline, setGuideline] = useState();
  const [isProcessing, setIsProcessing] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isDenied, setIsDenied] = useState(false);
  const [hasTaskOverview, setHasTaskOverview] = useState(false);
  const [isLastRow, setIsLastRow] = useState(false);

  const firebaseHelper = getFirebaseBackend();

  const primaryKeyword =
    data?.primaryKeyword.charAt(0).toUpperCase() + data?.primaryKeyword.slice(1);

  const getGuidelineType = (type) => {
    for (const option of guidelineTypeOptions) {
      if (option.value === type) {
        return option.label;
      }
    }
  };

  const setGuidelineData = useCallback(async () => {
    const guidelineData = cloneDeep(data);

    guidelineData.assignedToNames = await Promise.all(
      guidelineData.assignedTo.map(async (member) => await firebaseHelper.userName(member)),
    );

    if (guidelineData.responsibleToApproval) {
      guidelineData.responsibleToApprovalNames = await Promise.all(
        guidelineData.responsibleToApproval.map(
          async (member) => await firebaseHelper.userName(member),
        ),
      );
    }

    if (guidelineData.comments) {
      guidelineData.comments = Object.values(guidelineData.comments);
    }

    setGuideline(guidelineData);
  }, [data]);

  const handleDragStart = () => {
    const guidelineColumnFormatted = _.lowerCase(guideline.column);

    const guidelineColumnId = (
      initialGuidelineKanbanState.find(
        (column) => _.lowerCase(column.title) === guidelineColumnFormatted,
      ) || {}
    ).id;

    setDraggingFromColumn(guidelineColumnId);
  };

  const handleDragEnd = () => {
    setDraggingFromColumn('');
  };

  const toggleModalArchiveIndividual = (guidelineId, accountId, column) => {
    toggleModalArchive();
    setGuidelineDataClicked([
      {
        guidelineId,
        accountId,
        column,
      },
    ]);
  };

  function handleRetry() {
    switch (guideline?.column) {
      case 'BACKLOG':
        retryEndpoint('regenerateGuideline');
        break;
      case 'CONTENT_STRUCTURE':
        retryEndpoint('approveGuideline');
        break;
      case 'PRE_REVIEW':
        retryEndpoint('regenerateContent');
        break;
      case 'INTERNAL_REVIEW':
        retryEndpoint('internalReview');
        break;
      case 'APPROVED':
        retryEndpoint('approveContent');
        break;
      case 'PUBLISHED':
        retryEndpoint('publishContent');
        break;
      default:
        break;
    }
  }

  async function retryEndpoint(url) {
    try {
      if (!guideline?.id || !accountId) throw new Error('Guideline ID or Account ID is missing');
      const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
      const firebaseToken = await firebaseHelper.getIdToken();
      setIsProcessing(true);
      setIsError(false);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/${url}`,
        {
          accountId,
          guidelineId: guideline?.id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );
      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
      setIsError(true);
      console.log(error);
    }
  }

  const deadlineColor = () => {
    const deliveryDate = guideline?.deliveryDate;
    if (!deliveryDate) return '#adb5bd';

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    today = today.getTime();

    let oneDayBeforeDeliveryDate = new Date(deliveryDate);
    oneDayBeforeDeliveryDate.setDate(oneDayBeforeDeliveryDate.getDate() - 1);
    oneDayBeforeDeliveryDate.setHours(0, 0, 0, 0);
    oneDayBeforeDeliveryDate = oneDayBeforeDeliveryDate.getTime();

    let oneWeekBeforeDeliveryDate = new Date(deliveryDate);
    oneWeekBeforeDeliveryDate.setDate(oneWeekBeforeDeliveryDate.getDate() - 7);
    oneWeekBeforeDeliveryDate.setHours(0, 0, 0, 0);
    oneWeekBeforeDeliveryDate = oneWeekBeforeDeliveryDate.getTime();

    switch (true) {
      case today < oneWeekBeforeDeliveryDate:
        return '#adb5bd';
      case today >= oneDayBeforeDeliveryDate && today <= deliveryDate:
        return '#f46a6a';
      case today > deliveryDate:
        return '#c53030';
      default:
        return '#f1b44c';
    }
  };

  useEffect(() => {
    setGuidelineData();
  }, [setGuidelineData]);

  useEffect(() => {
    if (guideline) {
      const { error, processing } = guideline;
      setIsError(Boolean(error) && Boolean(!processing));
      setIsProcessing(Boolean(processing));
      setIsWaitingForApproval(
        guideline?.column === 'GUIDELINE_APPROVAL' || guideline?.column === 'CONTENT_APPROVAL',
      );
      setIsDenied(
        (guideline?.column === 'GUIDELINE_APPROVAL' || guideline?.column === 'CONTENT_APPROVAL') &&
          guideline?.denied,
      );
      setHasTaskOverview(guideline?.column === 'CONTENT_REVIEW');
      setIsLastRow(guideline?.column === 'PUBLISHED');
    }
  }, [guideline]);

  useEffect(() => {
    if (dragging) handleDragStart();
    if (!dragging) handleDragEnd();
  }, [dragging]);

  return (
    <React.Fragment key={guideline?.id}>
      <KanbanCardGuidelineLayout>
        <Card className="card-responsive">
          <CardBody
            className={`board-width d-flex flex-column border-top-color-${guideline?.deliveryType}`}
          >
            {isWaitingForApproval && !isDenied && (
              <Badge
                color="light"
                style={{
                  marginBottom: '16px',
                  fontSize: '12px !important',
                  maxWidth: 'min-content',
                }}
              >
                {translate('Waiting for approval')}
              </Badge>
            )}
            {isDenied && (
              <div style={{ marginBottom: '16px' }}>
                <BadgeDenied />
              </div>
            )}
            {guideline?.count && (
              <div className="mb-3 d-flex justify-content-between">
                <div className="d-flex gap-1">
                  {guideline?.count && (
                    <Badge className="badge-number">{`${guideline.count < 10 ? 0 : ''}${guideline.count}`}</Badge>
                  )}
                  {guideline?.deliveryType && (
                    <Badge className={`badge-type badge-type-${guideline?.deliveryType}`}>
                      {guidelineDeliveryTypeOptionsKanban
                        .find((type) => type.value === guideline?.deliveryType)
                        ?.label.toUpperCase()}
                    </Badge>
                  )}
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <span>
                    {formatDateTimestampNumber(guideline?.createdAt, translate('date-enUS'))}
                  </span>
                  <MenuDropdown
                    guidelineColumn={guideline?.column}
                    guidelineId={guideline?.id}
                    isProcessing={isProcessing}
                    googleDocId={guideline?.googleDocId}
                  />
                </div>
              </div>
            )}
            <div
              className={
                hasTaskOverview || !guideline?.count
                  ? 'd-flex align-items-start justify-content-between mb-3'
                  : 'mb-3'
              }
            >
              <h5
                className="card-title-guideline"
                style={
                  !guideline?.count ? { width: '90%' } : hasTaskOverview ? { width: '80%' } : {}
                }
              >
                {guideline?.deliveryType
                  ? guideline?.deliveryType === 'PRODUCTION' ||
                    guideline?.deliveryType === 'PRODUCTION_AND_UNIFICATION'
                    ? primaryKeyword
                    : guideline?.reviewLink
                  : primaryKeyword}
              </h5>
              <div className="d-flex gap-2">
                {hasTaskOverview && (
                  <div className="d-flex align-items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6 2.25H12C14.0711 2.25 15.75 3.92893 15.75 6V12C15.75 14.0711 14.0711 15.75 12 15.75H6C3.92893 15.75 2.25 14.0711 2.25 12V6C2.25 3.92893 3.92893 2.25 6 2.25Z"
                        stroke="#A0AEC0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.4375 10.6875L6.75 9"
                        stroke="#A0AEC0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.25 7.875L8.4375 10.6875"
                        stroke="#A0AEC0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {guideline?.comments?.filter((item) => item.type === 'task' && item.done)
                      .length || 0}
                    {'/'}
                    {guideline?.comments?.filter((item) => item.type === 'task').length || 0}
                  </div>
                )}
                {!guideline?.count && (
                  <MenuDropdown
                    guidelineColumn={guideline?.column}
                    guidelineId={guideline?.id}
                    isProcessing={isProcessing}
                    googleDocId={guideline?.googleDocId}
                  />
                )}
              </div>
            </div>
            <div className="second-row">
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex align-items-center"
                  style={{
                    lineHeight: '15px',
                    color: guideline?.column !== 'PUBLISHED' ? deadlineColor() : '#adb5bd',
                  }}
                >
                  {deadlineColor() === '#c53030' && guideline?.column !== 'PUBLISHED' ? (
                    <box-icon type="solid" name="error-circle" color="#c53030" size="15px" />
                  ) : (
                    <box-icon
                      type="regular"
                      name="calendar-alt"
                      color={guideline?.column !== 'PUBLISHED' ? deadlineColor() : '#adb5bd'}
                      size="15px"
                    />
                  )}
                  <span className="info--text">
                    {guideline?.column !== 'PUBLISHED'
                      ? guideline?.deliveryDate
                        ? formatDateTimestampNumber(guideline?.deliveryDate, translate('date-enUS'))
                        : 'N/A'
                      : formatDateTimestampNumber(guideline?.publishedDate, translate('date-enUS'))}
                  </span>
                </div>
                <div className="d-flex gap-1 align-items-center">
                  <i className="bx bx-text" />
                  <span>{guideline?.totalSize}</span>
                </div>
              </div>
              <div className="info--row d-flex align-items-center" style={{ lineHeight: '15px' }}>
                <box-icon type="regular" name="purchase-tag-alt" color="#ADB5BD" size="15px" />
                <span className="info--text">
                  {guideline?.type ? getGuidelineType(guideline.type) : 'N/A'}
                </span>
              </div>
            </div>

            <div className="avatar-element">
              <div className="avatar-group">
                {!isWaitingForApproval && (
                  <React.Fragment>
                    {map(guideline?.assignedTo, (member, index) => {
                      if (!(index < 2)) return;

                      return (
                        <div className="avatar-group-item" key={index}>
                          <div
                            alt={`${translate('Photo of')} ${guideline?.assignedToNames[index]}`}
                            id={`card-${member}-${guideline?.id}`}
                          >
                            <UserAvatar userId={member} />
                          </div>
                          <UncontrolledTooltip
                            defaultOpen={false}
                            flip
                            placement="right"
                            target={`card-${member}-${guideline?.id}`}
                          >
                            {guideline?.assignedToNames[index]}
                          </UncontrolledTooltip>
                        </div>
                      );
                    })}
                    {size(guideline?.assignedTo) > 2 && (
                      <div className="avatar-group-item">
                        <div className="avatar-xs">
                          <span
                            className="avatar-title rounded-circle text-white font-size-16"
                            style={{ backgroundColor: 'black' }}
                          >
                            {`${size(guideline?.assignedTo) - 2}+`}
                          </span>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {isWaitingForApproval && (
                  <React.Fragment>
                    {map(guideline?.responsibleToApproval, (member, index) => {
                      if (!(index < 2)) return;

                      return (
                        <div className="avatar-group-item" key={index}>
                          <div
                            alt={`${translate('Photo of')} ${
                              guideline?.responsibleToApprovalNames[index]
                            }`}
                            id={`card-${member}-${guideline?.id}`}
                          >
                            <UserAvatar userId={member} />
                          </div>
                          <UncontrolledTooltip
                            defaultOpen={false}
                            flip
                            placement="right"
                            target={`card-${member}-${guideline?.id}`}
                          >
                            {guideline?.responsibleToApprovalNames[index]}
                          </UncontrolledTooltip>
                        </div>
                      );
                    })}
                    {size(guideline?.responsibleToApproval) > 2 && (
                      <div className="avatar-group-item">
                        <div className="avatar-xs">
                          <span
                            className="avatar-title rounded-circle text-white font-size-16"
                            style={{ backgroundColor: 'black' }}
                          >
                            {`${size(guideline?.responsibleToApproval) - 2}+`}
                          </span>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>

              {!isProcessing && !isError && !isWaitingForApproval && !isLastRow && (
                <button
                  className="btn-card continue"
                  onClick={() => navigate(`/guideline/${guideline.id}`)}
                >
                  {translate('Continue')}
                </button>
              )}

              {!isProcessing && !isError && isWaitingForApproval && (
                <>
                  {guideline.denied ? (
                    <ButtonArchive
                      onClickFunction={() =>
                        toggleModalArchiveIndividual(guideline.id, accountId, guideline.column)
                      }
                    />
                  ) : guideline.column === 'CONTENT_APPROVAL' ? (
                    <ButtonView
                      onClickFunction={() => navigate(`/content/${guideline.id}/content-approval`)}
                    />
                  ) : (
                    <ButtonView
                      onClickFunction={() =>
                        navigate(`/guideline/${guideline.id}/guideline-approval`)
                      }
                    />
                  )}
                </>
              )}

              {isLastRow && (
                <ButtonView
                  onClickFunction={() => navigate(`/content/${guideline.id}/published-content`)}
                />
              )}

              {isProcessing && (
                <button disabled className="btn-card loading">
                  {translate('Loading')}
                </button>
              )}

              {isError && (
                <button className="btn-card try-again" onClick={handleRetry}>
                  {translate('Try again')}
                </button>
              )}
            </div>
          </CardBody>
        </Card>
      </KanbanCardGuidelineLayout>
    </React.Fragment>
  );
};

KanbanCardGuideline.propTypes = {
  cardBag: PropTypes.bool,
  data: PropTypes.object,
  dragging: PropTypes.any,
  setDraggingFromColumn: PropTypes.func,
  draggingFromColumn: PropTypes.string || PropTypes.number,
  setGuidelineDataClicked: PropTypes.func,
  toggleModalApprove: PropTypes.func,
  toggleModalArchive: PropTypes.func,
};

const propsAreEqual = (prevProps, nextProps) => {
  const isDataEquals = JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  const isDraggingEquals = prevProps.dragging === nextProps.dragging;

  return isDataEquals && isDraggingEquals;
};

export default memo(KanbanCardGuideline, propsAreEqual);
