import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';

const SubmitButton = ({
  backgroundColor = '#344792',
  color,
  justifyLeft = false,
  disabled,
  text,
  isLoading,
  ...other
}) => {
  const { t: translate } = useTranslation();

  return (
    <Row className={`${justifyLeft ? '' : 'justify-content-end'}`}>
      <Col lg="10">
        <Button
          type="submit"
          color={color}
          style={{
            backgroundColor,
            minWidth: '7rem',
            minHeight: '2.5rem',
            maxHeight: '2.5rem',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={disabled}
          {...other}
        >
          {isLoading ? (
            <div
              className="spinner-border text-light"
              role="status"
              style={{
                width: '1.5rem',
                height: '1.5rem',
                position: 'absolute',
              }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            translate(text)
          )}
        </Button>
      </Col>
    </Row>
  );
};

SubmitButton.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  justifyLeft: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default SubmitButton;
