import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/useAuth';
import { HeaderKeywordMonitoringStyled } from './styles';
import AccountLogo from 'components/Account-Logo';
import ButtonDownloadCSV from '../Button-Download-CSV';
import ButtonAddKeyword from '../Button-Add-Keyword';
import ButtonFilter from '../Button-Filter';
import FilterKeywordMonitoring from '../Filter-Keyword-Monitoring';

const HeaderKeywordMonitoring = ({
  groups,
  tags,
  chartData,
  toggleModal,
  fetchPerformanceData,
  setFilters,
  isLoading,
}) => {
  const { user } = useAuth();
  const isAdminStatus = ['Super Admin', 'Admin'].includes(user.adminStatus);
  const [filterOpen, setFilterOpen] = useState(false);

  const toggle = () => setFilterOpen((prevState) => !prevState);

  return (
    <HeaderKeywordMonitoringStyled>
      <div className="container-full">
        <div className="container-inline">
          <AccountLogo />
          <div className="d-flex w-100 justify-content-end gap-3 h-100">
            <ButtonDownloadCSV chartData={chartData} disabled={isLoading} />
            <ButtonFilter onClickFunction={toggle} disabled={isLoading} />
            {isAdminStatus && (
              <ButtonAddKeyword onClickFunction={toggleModal} disabled={isLoading} />
            )}
          </div>
        </div>
        {filterOpen && (
          <FilterKeywordMonitoring
            groups={groups}
            tags={tags}
            fetchPerformanceData={fetchPerformanceData}
            setFilters={setFilters}
          />
        )}
      </div>
    </HeaderKeywordMonitoringStyled>
  );
};

HeaderKeywordMonitoring.propTypes = {
  groups: PropTypes.array,
  tags: PropTypes.array,
  chartData: PropTypes.array,
  toggleModal: PropTypes.func,
  fetchPerformanceData: PropTypes.func,
  setFilters: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default HeaderKeywordMonitoring;
