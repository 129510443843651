const { default: styled } = require('styled-components');

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-clip: padding-box;
  background-size: cover;
  background-position: center center;
  height: 36px;
  width: 36px;
  background-color: #556ee6;
  color: white;
  animation: none;
  &[data-is-loading='true'] {
    span {
      display: none;
    }
    background-color: #f8f9fa;
    animation: loading-img 2s alternate-reverse infinite;
  }

  @keyframes loading-img {
    0% {
      background-color: #f8f9fa;
    }
    50% {
      background-color: #f6f6f6;
    }
    100% {
      background-color: #adb5bd;
    }
  }

  &[data-has-photo='true'] {
    span {
      display: none;
    }
  }
  span {
    font-size: 1rem;
  }
`;

const Styles = {
  AvatarContainer,
};

export default Styles;
