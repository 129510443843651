import React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles';
import getFile from 'helpers/getFile';
import { getInitials } from 'utils/getInitials';
import CurrentUserAvatar from './CurrentUserAvatar';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useQuery } from '@tanstack/react-query';

const UserAvatar = ({ userId }) => {
  const ref = React.useRef(null);
  const firebaseHelper = getFirebaseBackend();
  const photoQuery = useQuery({
    queryKey: ['user', 'avatar', userId],
    queryFn: async () => {
      return await getFile.userAvatar(userId);
    },
    cacheTime: 1000 * 5,
    staleTime: 1000 * 5,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const userQuery = useQuery({
    queryKey: ['user', userId],
    queryFn: async () => {
      return await firebaseHelper.getUser(userId);
    },
    cacheTime: 1000 * 5,
    staleTime: 1000 * 5,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <Styles.AvatarContainer
      ref={ref}
      data-is-loading={photoQuery.isLoading}
      data-has-photo={Boolean(photoQuery.data)}
      style={
        photoQuery.data
          ? {
              backgroundImage: `url('${photoQuery.data}')`,
            }
          : {}
      }
    >
      {userQuery.data && <span>{getInitials(userQuery.data.name)}</span>}
    </Styles.AvatarContainer>
  );
};

UserAvatar.propTypes = {
  userId: PropTypes.string.isRequired,
};

UserAvatar.CurrentUser = CurrentUserAvatar;

export default UserAvatar;
