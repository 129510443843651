import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  Tooltip,
  UncontrolledTooltip,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PerformanceTableStyles } from './styles';
import CustomPagination from '../Pagination';

const PerformanceTable = ({ data, performanceData, fetchPerformanceData }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [averagePositionIsActive, setAveragePositionIsActive] = useState(true);
  const [groupsIsActive, setGroupsIsActive] = useState(true);
  // const [urlIsActive, setUrlIsActive] = useState(true);
  const [linesByPage, setLinesByPage] = useState(() => {
    return parseInt(localStorage.getItem('linesByPage')) || 10;
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [view, setView] = useState('keywords');

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const dataToRender =
    view === 'keywords' ? performanceData.groupedKeywords : performanceData.byGroup;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}&limit=${linesByPage}`);
    fetchPerformanceData();
  };

  const handleLinesByPageChange = (e) => {
    const newLinesByPage = parseInt(e.target.value);
    setLinesByPage(newLinesByPage);
    setCurrentPage(1);
    localStorage.setItem('linesByPage', newLinesByPage);
    navigate(`?page=1&limit=${newLinesByPage}`);
    fetchPerformanceData();
  };

  const separatePhrase = (phrase) => {
    const [label, value] = phrase.split('\n');

    return { label, value };
  };

  const copyKeywords = () => {
    const keywords = performanceData.allKeywords.map((keyword) => keyword).join('\n');
    navigator.clipboard.writeText(keywords);

    setTooltipOpen(true);

    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page')) || 1;
    const limit = parseInt(query.get('limit')) || 10;
    setCurrentPage(page);
    setLinesByPage(limit);
    if (view === 'keywords') setTotalPages(Math.ceil(performanceData.totalGroupedKeywords / limit));
    if (view === 'groups') setTotalPages(Math.ceil(performanceData.totalByGroup / limit));
  }, [location.search, view]);

  return (
    <PerformanceTableStyles>
      <div className="table-header d-flex justify-content-between align-items-center">
        <span>{translate('Keyword')}</span>
        <div className="d-flex gap-2">
          <button
            id="btn-card-copy"
            className="btn-card copy"
            onClick={copyKeywords}
            disabled={view === 'groups'}
          >
            {translate('Copy words')}
            <i className="bx bx-copy" />
          </button>
          <div className="d-flex">
            <button
              className={view === 'keywords' ? 'btn-card next keywords' : 'btn-card keywords copy'}
              onClick={() => {
                setView('keywords');
                setCurrentPage(1);
              }}
            >
              <i className="bx bx-list-ol" />
              {translate('Keywords')}
            </button>
            <button
              className={view === 'groups' ? 'btn-card next groups' : 'btn-card copy groups'}
              onClick={() => {
                setView('groups');
                setCurrentPage(1);
              }}
            >
              <i className="bx bx-folder" />
              {translate('Groups')}
            </button>
          </div>
          <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-config">
            <DropdownToggle className="btn-card config">
              <i className="bx bx-cog" />
            </DropdownToggle>
            <DropdownMenu className="px-2 py-1 mt-1" right>
              <FormGroup switch>
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center gap-2 py-1 hover-gray">
                    <Input
                      type="switch"
                      id="averagePosition"
                      checked={averagePositionIsActive}
                      onClick={() => setAveragePositionIsActive(!averagePositionIsActive)}
                    />
                    <Label for="averagePosition" check className="m-0">
                      {translate('Average position')}
                    </Label>
                  </div>
                  {/* <div className="d-flex align-items-center gap-2">
                    <Input
                      type="switch"
                      id="url"
                      checked={urlIsActive}
                      onClick={() => setUrlIsActive(!urlIsActive)}
                    />
                    <Label for="url" check className="m-0">
                      {translate('URL')}(s)
                    </Label>
                  </div> */}
                  {view === 'keywords' && (
                    <div className="d-flex align-items-center gap-2">
                      <Input
                        type="switch"
                        id="groups"
                        checked={groupsIsActive}
                        onClick={() => setGroupsIsActive(!groupsIsActive)}
                      />
                      <Label for="groups" check className="m-0">
                        {translate('Group')}(s)
                      </Label>
                    </div>
                  )}
                </div>
              </FormGroup>
            </DropdownMenu>
          </Dropdown>
          <Tooltip placement="top" target="btn-card-copy" isOpen={tooltipOpen}>
            {translate('Copied')}
          </Tooltip>
        </div>
      </div>
      <div className="performance-table">
        <Table>
          <thead>
            <tr>
              <th className="header-keyword">
                {view === 'keywords' && translate('Keyword')}
                {view === 'groups' && translate('Groups')}
              </th>
              {averagePositionIsActive && (
                <th
                  className="header-keyword text-center"
                  style={{
                    width: '5.375rem',
                    minWidth: '5.375rem',
                  }}
                >
                  {translate('Average position')}
                </th>
              )}
              {/* {urlIsActive && (
                <th
                  className="header-keyword text-center"
                  style={{
                    width: '5.375rem',
                    minWidth: '5.375rem',
                  }}
                >
                  URL
                </th>
              )} */}
              {groupsIsActive && view === 'keywords' && (
                <th
                  className="header-keyword text-center"
                  style={{
                    width: '10rem',
                    minWidth: '10rem',
                  }}
                >
                  {translate('Groups')}
                </th>
              )}
              {data.labels.slice().map((labelGroup, index) => {
                const { label, value } = separatePhrase(labelGroup);

                return (
                  <th key={`${labelGroup}${index}`} style={{ width: '6rem', minWidth: '6rem' }}>
                    <div className="header-label">{label}</div>
                    <div className="header-value">{value}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {dataToRender.map((dataObj, index) => {
              return (
                <tr key={`${dataObj.name}${index}`}>
                  <td>
                    {/* <div className="body-search-tag">
                      {translate(
                        `${keyword.type.charAt(0).toUpperCase()}${keyword.type
                          .slice(1)
                          .toLowerCase()}`,
                      )}
                    </div> */}
                    <div>{dataObj.name}</div>
                  </td>
                  {averagePositionIsActive && (
                    <td className="text-center">
                      {dataObj.averagePosition === null ? '-' : dataObj.averagePosition}
                    </td>
                  )}
                  {/* {urlIsActive && (
                    <td className="text-center">
                      <i className="bx bx-link" />
                    </td>
                  )} */}
                  {groupsIsActive && view === 'keywords' && (
                    <td className="text-center">{dataObj.groupNames}</td>
                  )}
                  {data?.weeks.map((labelGroup, index) => {
                    const weekData = dataObj.dates.find((week) => week.week === labelGroup);

                    if (!weekData) {
                      return (
                        <>
                          <td
                            key={`${labelGroup}${index}`}
                            className="body-search-value"
                            id={`not-collected-${index}`}
                            style={{ width: '6rem', minWidth: '6rem' }}
                          >
                            <div className="d-flex flex-column text-center container-not-collected">
                              <i className="bx bx-info-circle" />
                              <span>{translate('Not collected')}</span>
                            </div>
                          </td>
                          <UncontrolledTooltip target={`not-collected-${index}`} placement="top">
                            {translate(
                              'The keyword was not registered on the date of this collection',
                            )}
                          </UncontrolledTooltip>
                        </>
                      );
                    }

                    const previousLabel = data.weeks[index + 1];
                    const previousWeek = dataObj.dates.find(
                      (week) => String(week.week) === String(previousLabel),
                    );

                    const total = previousWeek ? weekData.bestPlace - previousWeek.bestPlace : null;

                    return (
                      <td key={`${labelGroup}${index}`} className="body-search-value">
                        <div>{weekData.bestPlace === 0 ? '-' : weekData.bestPlace}</div>
                        {previousWeek && weekData.bestPlace && total < 0 ? (
                          <div className="total-growth total-growth-negative d-flex align-items-center">
                            <i className="bx bxs-down-arrow" style={{ color: '#38a169' }} />
                            {total}
                          </div>
                        ) : previousWeek?.bestPlace !== 0 && total > 0 ? (
                          <div className="total-growth total-growth-positive">
                            <i className="bx bxs-up-arrow" style={{ color: '#e53e3e' }} />+{total}
                          </div>
                        ) : (
                          <div style={{ height: '1rem' }} />
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="table-footer d-flex justify-content-end align-items-center">
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="lines-by-page">
            <Label className="w-100" htmlFor="linesByPage">
              {translate('Lines by page')}:
            </Label>
            <Input
              type="select"
              name="select"
              id="linesByPage"
              value={linesByPage}
              onChange={handleLinesByPageChange}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </div>
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            linesByPage={linesByPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </PerformanceTableStyles>
  );
};

PerformanceTable.propTypes = {
  data: PropTypes.object,
  performanceData: PropTypes.array,
  fetchPerformanceData: PropTypes.func,
};

export default PerformanceTable;
