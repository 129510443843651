import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import { ButtonFilterStyles } from './styles';

const ButtonFilter = ({ onClickFunction, disabled }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonFilterStyles>
      <Button onClick={() => onClickFunction()} disabled={disabled}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 2.83333H14" stroke="#343A40" strokeWidth="1.5" strokeLinejoin="round" />
          <path d="M2 2.83333H9.33333" stroke="#343A40" strokeWidth="1.5" strokeLinejoin="round" />
          <path
            d="M6.6665 6.75002C6.25229 6.75002 5.9165 7.08581 5.9165 7.50002C5.9165 7.91423 6.25229 8.25002 6.6665 8.25002V6.75002ZM6.6665 8.25002H13.9998V6.75002H6.6665V8.25002Z"
            fill="#343A40"
          />
          <path d="M2 7.50002H4" stroke="#343A40" strokeWidth="1.5" strokeLinejoin="round" />
          <path d="M12 12.1666H14" stroke="#343A40" strokeWidth="1.5" strokeLinejoin="round" />
          <path d="M2 12.1666H9.33333" stroke="#343A40" strokeWidth="1.5" strokeLinejoin="round" />
          <path
            d="M6.27614 6.55721C6.79684 7.07791 6.79684 7.92213 6.27614 8.44283C5.75545 8.96353 4.91122 8.96353 4.39052 8.44283C3.86983 7.92213 3.86983 7.07791 4.39052 6.55721C4.91122 6.03651 5.75544 6.03651 6.27614 6.55721"
            stroke="#343A40"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.6096 11.2238C12.1303 11.7445 12.1303 12.5888 11.6096 13.1095C11.0889 13.6302 10.2447 13.6302 9.72402 13.1095C9.20332 12.5888 9.20332 11.7445 9.72402 11.2238C10.2447 10.7031 11.0889 10.7031 11.6096 11.2238"
            stroke="#343A40"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        {translate('Filters')}
      </Button>
    </ButtonFilterStyles>
  );
};

ButtonFilter.propTypes = {
  onClickFunction: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ButtonFilter;
