import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useAuth } from './useAuth';

const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const firebaseHelper = getFirebaseBackend();

  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user?.account && !account) {
      const fetchAccount = async (accountId) => {
        try {
          const accountData = await firebaseHelper.getAccount(accountId);
          setAccount(accountData);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };

      fetchAccount(currentAccountId);
    } else {
      setIsLoading(false);
    }
  }, [user]);

  return (
    <AccountContext.Provider value={{ account, isLoading }}>{children}</AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
