import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Button, Row, Col, Card, CardBody } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import { normalizeString } from '../../utils/';
import '../../assets/scss/datatables.scss';

const ListDeactivated = () => {
  const { t: translate } = useTranslation();
  const [users, setUsers] = useState([]);
  const [deactivatedUsers, setDeactivatedUsers] = useState([]);
  const firebaseHelper = getFirebaseBackend();

  const statusCustomization = {
    Pending: (
      <p style={{ color: 'rgba(241, 180, 76, 1)', margin: '0 0' }}>{translate('Pending')}</p>
    ),
    Activated: (
      <p style={{ color: 'rgba(52, 195, 143, 1)', margin: '0 0' }}>{translate('Activated')}</p>
    ),
    Deactivated: (
      <p style={{ color: 'rgba(244, 106, 106, 1)', margin: '0 0' }}>{translate('Deactivated')}</p>
    ),
  };

  const columns = [
    {
      dataField: 'name',
      text: translate('Name'),
      sort: true,
    },
    {
      dataField: 'email',
      text: translate('Email'),
      sort: true,
    },
    {
      dataField: 'account',
      text: translate('Account'),
      sort: true,
    },
    {
      dataField: 'status',
      text: translate('Status'),
      sort: true,
    },
    {
      dataField: 'action',
      text: translate('Action'),
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'asc',
    },
  ];

  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: deactivatedUsers.length },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: deactivatedUsers.length,
    custom: true,
    sizePerPageList,
  };

  const { SearchBar } = Search;

  const deactivatedTotal = (acc, cur) => (cur.status === 'Pending' ? ++acc : acc);
  const activatedTotal = (acc, cur) => (cur.status === 'Activated' ? ++acc : acc);
  const pendingTotal = (acc, cur) => (cur.status === 'Pending' ? ++acc : acc);

  async function fetchAllUsers() {
    const usersObj = await firebaseHelper.getAllUsers();
    setUsers(usersObj);
  }

  function actionButton({ id }) {
    return (
      <div style={{ display: 'flex' }}>
        <Link to={`/edit-user?id=${id}`}>
          <Button style={{ border: 'none', backgroundColor: '#E0E0E0' }}>
            <i className="bx bx-pencil" style={{ color: '#495057' }}></i>{' '}
            <span style={{ color: 'rgba(106, 113, 135, 1)' }}>{translate('Edit')}</span>
          </Button>
        </Link>
      </div>
    );
  }

  async function processDeactivatedUsers() {
    const processedUsers = await Promise.all(
      Object.values(users)
        .filter((value) => value.status === 'Deactivated')
        .map(async (user) => {
          const accountName = await firebaseHelper.accountName(user.account);

          return {
            id: user.id,
            name: user.name,
            email: user.email,
            account: accountName,
            status: statusCustomization[normalizeString(user.status)],
            action: actionButton(user),
          };
        }),
    );
    setDeactivatedUsers(processedUsers);
  }

  useEffect(() => {
    if (!users) return;

    processDeactivatedUsers();
  }, [users]);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content" style={{ minHeight: '100vh' }}>
        <MetaTags>
          <title>{translate('List Deactivated Users')} | Ectools</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12" className="mb-4">
              <h4 className="mb-0 font-size-18">
                {translate('Deactivated Users')} (
                {deactivatedUsers !== null ? Object.values(users).reduce(deactivatedTotal, 0) : 0})
              </h4>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={deactivatedUsers}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={deactivatedUsers}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2 d-flex justify-content-between">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={translate('Search')}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="8" className="d-flex flex-row-reverse align-items-center">
                                <Link to="/create-user">
                                  <Button
                                    style={{
                                      backgroundColor: 'rgba(85, 110, 230, 1)',
                                      borderColor: 'rgba(85, 110, 230, 1)',
                                    }}
                                  >
                                    <i className="bx bx-plus-circle"></i>{' '}
                                    {translate('Create new User')}
                                  </Button>
                                </Link>
                                <Link to="/list-pending">
                                  <p className="mx-4 mb-0" style={{ color: '#ADB5BD' }}>
                                    {translate('Pending Users')} (
                                    {users !== null
                                      ? Object.values(users).reduce(pendingTotal, 0)
                                      : 0}
                                    )
                                  </p>
                                </Link>
                                <Link to="/list-users">
                                  <p className="mx-4 mb-0" style={{ color: '#ADB5BD' }}>
                                    {translate('See all users')} (
                                    {users !== null
                                      ? Object.values(users).reduce(activatedTotal, 0)
                                      : 0}
                                    )
                                  </p>
                                </Link>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    hover
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ListDeactivated.displayName = 'List Deactivated Users Page';

export default ListDeactivated;
