import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { CommentSection, LessonQnA } from './styles';
import UserAvatar from 'components/UserAvatar';

const LessonComments = ({ accountId, lessonId }) => {
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const { t: translate } = useTranslation();
  const [comments, setComments] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const inputRef = useRef();

  const handleComment = async (event) => {
    event.preventDefault();

    const value = inputRef.current?.value.trim();
    if (value) {
      const commentKey = await firebaseHelper.newCommentKey({
        accountId,
        id: lessonId,
      });
      firebaseHelper.registerComment({
        accountId: accountId,
        columnId: lessonId,
        id: commentKey,
        by: user.id,
        content: value,
      });
    }

    inputRef.current.value = '';

    isBtnDisabled();
    fetchComments();
  };

  const handleKey = (event) => {
    const keyCode = event.keyCode;
    const shiftKey = event.shiftKey;

    if (keyCode === 13 && !shiftKey) {
      handleComment(event);
    }
  };

  const isBtnDisabled = () => {
    const value = inputRef.current?.value.trim();
    setBtnDisabled(!value);
  };

  const fetchComments = async () => {
    const commentsObj = await firebaseHelper.getAllComments({
      accountId,
      taskId: lessonId,
    });

    if (!commentsObj) return;

    const sortedComments = Object.values(commentsObj).sort((a, b) => {
      if (a.createdAt < b.createdAt) return -1;
      if (a.createdAt > b.createdAt) return 1;
      return 0;
    });

    const newComments = await Promise.all(
      sortedComments.map(async (comment) => {
        const byName = await firebaseHelper.userName(comment.by);
        return { ...comment, byName };
      }),
    );

    setComments(newComments);
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <LessonQnA>
      <section>
        <span>{translate('Questions and Answers')}</span>
        <textarea
          placeholder={translate('Ask here your question...')}
          ref={inputRef}
          onKeyDown={handleKey}
          onChange={isBtnDisabled}
        />
        <button onClick={handleComment} disabled={btnDisabled}>
          {translate('Send')}
        </button>
      </section>

      {Boolean(comments.length) && (
        <CommentSection>
          <h4>{translate('Questions')}</h4>

          {comments.map((comment, index) => {
            return (
              <div className="course-comment" key={comment.id}>
                <div>
                  <UserAvatar userId={comment.by} />

                  <div className="course-comment-box">
                    <div className="course-comment-name">{comment.byName}</div>
                    <div className="course-comment-content">{comment.content}</div>
                  </div>
                </div>

                {index < comments.length - 1 && <hr />}
              </div>
            );
          })}
        </CommentSection>
      )}
    </LessonQnA>
  );
};

LessonComments.displayName = 'Lesson Comments Component';

LessonComments.propTypes = {
  accountId: PropTypes.string,
  lessonId: PropTypes.string,
};

export default LessonComments;
