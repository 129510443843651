import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { PerformanceChartStyles } from './styles';

const NUMBER_OF_WEEKS = 12;

const PerformanceChart = ({ data }) => {
  const { t: translate } = useTranslation();

  const tooltipOrder = ['1 a 3', '4 a 6', '7 a 10', '11 a 20', '20+', 'Sem ranking'];

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      text: translate('Number of keywords'),
      fontSize: 14,
      position: 'left',
      fontStyle: 'normal',
      fontColor: '#718096',
    },
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      filter: (tooltipItem) => tooltipItem.yLabel !== 0,
      itemSort: (a, b) => {
        const aLabel = data.datasets[a.datasetIndex].label;
        const bLabel = data.datasets[b.datasetIndex].label;
        return tooltipOrder.indexOf(aLabel) - tooltipOrder.indexOf(bLabel);
      },
      callbacks: {
        title: (tooltipItems) => tooltipItems[0].xLabel,
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label;
          return `${label}: ${tooltipItem.yLabel}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            fontSize: 14,
            callback: function (value) {
              return value.split('\n');
            },
          },
          barPercentage: 0.6,
        },
      ],
      yAxes: [
        {
          stacked: false,
          gridLines: {
            display: true,
            borderDash: [2, 2],
            drawBorder: false,
            lineWidth: 2,
          },
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };

  const sortedData = {
    labels: data.labels.slice(0, NUMBER_OF_WEEKS),
    weeks: data.weeks.slice(0, NUMBER_OF_WEEKS),
    datasets: data.datasets.slice().sort((a, b) => {
      const totalA = a.data.reduce((acc, value) => acc + value, 0);
      const totalB = b.data.reduce((acc, value) => acc + value, 0);
      return totalA - totalB;
    }),
  };

  return (
    <PerformanceChartStyles>
      <div className="container-chart-subtitle d-flex justify-content-between">
        {data.datasets
          .slice()
          .reverse()
          .map((dataset, index) => {
            const valueCurrentWeek = dataset.data[0];
            const valueLastWeek = dataset.data[1];
            const changeLastWeek = valueCurrentWeek - valueLastWeek;

            return (
              <div key={`${dataset.label}${index}`} className="chart-subtitle d-flex flex-column">
                <div
                  className="chart-subtitle-color"
                  style={{ backgroundColor: dataset.backgroundColor }}
                />
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column gap-1">
                    <span>{dataset.label}</span>
                    <span>
                      {Array.isArray(dataset.data) && dataset.data.length > 0
                        ? dataset.data.reduce((acc, currentValue) => acc + currentValue, 0) === 0
                          ? '-'
                          : dataset.data.reduce((acc, currentValue) => acc + currentValue, 0)
                        : 'N/A'}
                    </span>
                  </div>
                  {changeLastWeek !== 0 && valueCurrentWeek > valueLastWeek ? (
                    <div className="total-growth total-growth-positive">
                      <i className="bx bxs-down-arrow" style={{ color: '#38a169' }} />-
                      {changeLastWeek}
                    </div>
                  ) : changeLastWeek !== 0 && valueCurrentWeek < valueLastWeek ? (
                    <div className="total-growth total-growth-negative d-flex align-items-center">
                      <i className="bx bxs-up-arrow" style={{ color: '#e53e3e' }} />
                      {changeLastWeek}
                    </div>
                  ) : (
                    <div style={{ height: '1rem' }} />
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div style={{ position: 'relative', height: '28.4rem', width: '100%' }}>
        <Bar data={sortedData} options={options} />
      </div>
    </PerformanceChartStyles>
  );
};

PerformanceChart.propTypes = {
  data: PropTypes.object,
};

export default PerformanceChart;
