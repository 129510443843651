import React from 'react';
import PropTypes from 'prop-types';
import { MoonLoader } from 'react-spinners';
import { Button } from 'reactstrap';

import { PageLoadingStyle } from './styles';

const PageLoading = ({ title, description, button, onClick, children, buttonStyles = {} }) => {
  return (
    <PageLoadingStyle>
      <div className="card-loading">
        {children ? children : <MoonLoader color="#556ee6" size={90} />}
        <p>{title}</p>
        <span>{description}</span>
      </div>
      <Button onClick={onClick} style={buttonStyles} color="primary">
        {button}
      </Button>
    </PageLoadingStyle>
  );
};

PageLoading.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.element,
  buttonStyles: PropTypes.object,
};

export default PageLoading;
