import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import Badge from 'components/Badge';
import Styles from './styles';

const Option = ({ children, data, ...props }) => {
  return (
    <components.Option {...props}>
      <Styles.OptionWrapper>
        <span>{children}</span>
        {data.status && <Badge.Active />}
        {!data.status && <Badge.Inactive />}
      </Styles.OptionWrapper>
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
};

const AccountSelect = ({ user, limited = true }) => {
  const firebaseHelper = getFirebaseBackend();

  const { user: userObj, changeAccount } = useAuth();
  const userAccounts = userObj?.accounts || [userObj?.account];
  const { adminStatus } = userObj;
  const managedAccounts = userObj?.accounts;

  const [accounts, setAccounts] = useState();
  const [accountName, setAccountName] = useState('');
  const [accountListLen, setAccountListLen] = useState(100);
  const [selectKey, setSelectKey] = useState(1);

  const fetchAccounts = async () => {
    const accountsObj = await firebaseHelper.getAllAccounts();
    setAccounts(accountsObj);
  };

  const fetchAccountName = async () => {
    const name = await firebaseHelper.accountName(user.account);
    setAccountName(name);
  };

  const getAccountList = async (inputValue) => {
    if (!accounts) return;

    return new Promise((resolve, reject) => {
      let newAccountList = Object.entries(accounts);
      // Sorts the array, filter accounts if limited, sets the array length,
      // filters by the search and maps to react-select standard of value label object
      newAccountList.sort(([, firstObj], [, secondObj]) => {
        const normalizedA = firstObj.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const normalizedB = secondObj.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        return normalizedA > normalizedB ? 1 : normalizedB > normalizedA ? -1 : 0;
      });

      newAccountList.sort(([, firstObj], [, secondObj]) => {
        const normalizedA = firstObj.status ? -1 : 1;
        const normalizedB = secondObj.status ? -1 : 1;
        return normalizedA - normalizedB;
      });
      if (limited) {
        if (adminStatus === 'Admin') {
          newAccountList = newAccountList.filter(([, account]) =>
            account.internalTeam.includes(user.id),
          );
        }
        if (adminStatus === 'Client Admin') {
          newAccountList = newAccountList.filter(([accountId]) =>
            managedAccounts.includes(accountId),
          );
        }
        if (adminStatus === 'Client') {
          newAccountList = newAccountList.filter(([accountId]) => userAccounts.includes(accountId));
        }
      }

      setAccountListLen(newAccountList.length);

      if (inputValue) {
        newAccountList = newAccountList.filter(([, account]) => {
          // Normalize, remove accents and diacretics and lowercase the strings for comparison
          const normalizedAccount = account.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
          const normalizedLabel = inputValue
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

          return normalizedAccount.includes(normalizedLabel);
        });
      }
      newAccountList = newAccountList.map(([accountId, account]) => {
        return { value: accountId, label: account.name, status: account.status };
      });

      resolve(newAccountList);
    });
  };

  const handleAccountChange = ({ value: accountId }) => {
    changeAccount(user.id, accountId);
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (!user) return;

    fetchAccountName();
  }, [user]);

  useEffect(() => {
    if (!accounts) return;

    setSelectKey((prevState) => prevState + 1);
  }, [accounts]);

  return (
    <>
      {accountListLen > 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            width: '18.75rem',
          }}
        >
          <AsyncSelect
            key={selectKey}
            defaultValue={user.account}
            placeholder={accountName}
            cacheOptions
            defaultOptions
            loadOptions={(inputValue) => getAccountList(inputValue)}
            onChange={(event) => handleAccountChange(event)}
            className="basic-multi-select w-100"
            maxMenuHeight="20vh"
            components={{
              Option,
            }}
          />
        </div>
      ) : null}
    </>
  );
};

AccountSelect.propTypes = {
  user: PropTypes.object,
  limited: PropTypes.bool,
};

export default AccountSelect;
