import styled from 'styled-components';

export const HomepageStyled = styled.div`
  .page-title {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 2rem;
  }

  .btn-toggler {
    width: 11.5rem;
    border: none;
    padding: 0.625rem 1rem;
    background-color: #e2e8f0;
    color: #2d3748 !important;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  .p-2rem {
    padding: 2rem;
  }

  .no-activity {
    padding: 4rem 2rem;
    height: calc(100vh - 70px - 60px - 100px);

    svg {
      width: 277px;
      height: 239px;
    }

    p {
      margin: 0;
      color: #1a202c;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2rem;
    }

    span {
      color: #718096;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }
`;
