import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

const ContainerDatePicker = ({
  date,
  id,
  anyDate,
  isDateBegin = false,
  isDateDelivery = false,
  isDateImplementation = false,
}) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user?.account;
  const firebaseHelper = getFirebaseBackend();
  const [newDate, setNewDate] = useState(date);

  const dateFormat = translate('date-enUS') === 'date-enUS' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';

  const handleDateSave = async (newDate) => {
    console.log('newDate', newDate);
    console.log(isDateBegin, isDateDelivery, isDateImplementation);
    try {
      if (isDateBegin) {
        await firebaseHelper.updateTaskDateBegin(accountId, id, newDate);
      }

      if (isDateDelivery) {
        await firebaseHelper.updateTaskDateDelivery(accountId, id, newDate);
      }

      if (isDateImplementation) {
        await firebaseHelper.updateTaskDateImplementation(accountId, id, newDate);
      }
    } catch (error) {
      console.error('Error updating task date:', error);
    }
  };

  const handleDateChange = (newDate) => {
    const timestamp = newDate ? newDate.getTime() : null;
    setNewDate(timestamp);
    handleDateSave(timestamp);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9/-]/g, '');
    e.target.value = newValue;
  };

  useEffect(() => {
    setNewDate(date);
  }, [date]);

  return (
    <div>
      <DatePicker
        id="date"
        selected={newDate ? new Date(newDate) : null}
        locale={translate('en-US')}
        onChange={handleDateChange}
        minDate={anyDate ? '' : new Date()}
        customInput={<Input onChange={handleInputChange} />}
        dateFormat={dateFormat}
        placeholderText={translate('Add')}
      />
    </div>
  );
};

ContainerDatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  id: PropTypes.string,
  isDateBegin: PropTypes.bool,
  isDateDelivery: PropTypes.bool,
  isDateImplementation: PropTypes.bool,
  anyDate: PropTypes.bool,
};

export default ContainerDatePicker;
