import styled from 'styled-components';

export const CardKeywordStyles = styled.div`
  background-color: #f8f8fb;
  padding: 1.5rem;
  border: 1px solid #edf2f7;
  border-radius: 0.5rem;
  width: 100%;

  .card-title {
    color: #718096;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .card-info {
    color: #1a202c;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .icon-growth {
    background-color: #2c5282;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 0.5rem;
  }
`;
