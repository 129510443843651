import React from 'react';
import PropTypes from 'prop-types';

import { CardKeywordStyles } from './styles';

const CardKeyword = ({ title, value }) => {
  return (
    <CardKeywordStyles className="d-flex justify-content-between">
      <div className="d-flex flex-column justify-content-between">
        <span className="card-title">{title}</span>
        <div className="d-flex align-items-center gap-2">
          <span className="card-info">{value}</span>
        </div>
      </div>
      <div className="icon-growth d-flex justify-content-center align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.99997 10.414L14 14.414L19.707 8.707L22 11V5H16L18.293 7.293L14 11.586L9.99997 7.586L2.29297 15.293L3.70697 16.707L9.99997 10.414Z"
            fill="#F7FAFC"
          />
        </svg>
      </div>
    </CardKeywordStyles>
  );
};

CardKeyword.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
};

export default CardKeyword;
