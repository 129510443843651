import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, BreadcrumbItem, Button } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LineLimit from '../Line-Limit';

const Breadcrumb = ({
  title,
  breadcrumbItem,
  separatedTitle,
  showLimit,
  isDefaultGroup,
  showDefaultGroupButton,
  setGroupAsDefault,
  limit,
  limitUsed,
}) => {
  const { t: translate } = useTranslation();

  return (
    <BreadcrumbStyled>
      <Col xs="12">
        <div className="d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h4 className="mb-0">{separatedTitle ? separatedTitle : breadcrumbItem}</h4>
            {showLimit && <LineLimit limit={limit} limitUsed={limitUsed} />}
            {showDefaultGroupButton && (
              <Button
                className={`btn-default-group ${isDefaultGroup ? 'btn-default-group-true' : 'btn-default-group-false'}`}
                onClick={setGroupAsDefault}
              >
                {isDefaultGroup ? (
                  <>
                    {translate('Default group')}
                    <i className="bx bxs-bookmark" />
                  </>
                ) : (
                  <>
                    {translate('Make default')}
                    <i className="bx bx-bookmark" />
                  </>
                )}
              </Button>
            )}
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="#">{title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </BreadcrumbStyled>
  );
};

const BreadcrumbStyled = styled(Row)`
  h4 {
    color: #2d3748;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
  }

  li {
    a {
      color: #495057;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .limit-container {
    color: #74788d;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .total-keywords {
      font-weight: 600;
      color: #343a40;
    }
  }

  .btn-default-group {
    height: 2rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .btn-default-group-true {
    background: #7367f0;
    color: #faf5ff !important;
    &:hover {
      background: #5b54cc;
      color: #faf5ff !important;
    }
  }
  .btn-default-group-false {
    background: transparent;
    color: #74788d !important;
    border-color: #74788d !important;
    &:hover {
      background: #e2e5f1;
      color: #74788d !important;
      border-color: #74788d !important;
    }
  }
`;

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  separatedTitle: PropTypes.string,
  showLimit: PropTypes.bool,
  isDefaultGroup: PropTypes.bool,
  showDefaultGroupButton: PropTypes.bool,
  setGroupAsDefault: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  limitUsed: PropTypes.number,
  setLimitUsed: PropTypes.func,
};

export default Breadcrumb;
