import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { useAuth } from 'hooks/useAuth';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { getFirebaseBackend } from 'helpers/firebaseHelper';

const ModalDeleteKeyword = ({
  isOpen,
  toggle,
  keyword,
  fetchGroup,
  fetchLimitUsedFromFirebase,
}) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const { id: groupId } = useParams();
  const accountId = user.account;
  const [isSending, setIsSending] = useState(false);

  const onSubmit = async () => {
    try {
      setIsSending(true);
      const firebaseToken = await firebaseHelper.getIdToken();

      await axios.post(
        `${process.env.REACT_APP_API_URL}/deleteKeyword`,
        {
          development: process.env.REACT_APP_BUILD_TYPE !== 'production',
          accountId: accountId,
          groupId: groupId,
          keyword: keyword.name,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );

      await fetchLimitUsedFromFirebase();
      fetchGroup();
      setIsSending(false);
      toggle();
    } catch (error) {
      setIsSending(false);
      console.error('Error deleting keyword: ', error);
    }
  };

  return (
    <ModalEditKeywordStyled
      isOpen={isOpen}
      toggle={() => {
        if (!isSending) toggle();
      }}
      centered
    >
      <ModalHeader>{translate('Delete keyword')}</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column gap-3">
          <p>{translate('Are you sure you want to delete this keyword?')}</p>
          <ul className="">
            <li>{keyword?.name?.charAt(0).toUpperCase() + keyword?.name?.slice(1)}</li>
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggle} disabled={isSending}>
          {translate('Cancel')}
        </Button>
        <Button className="btn-submit" onClick={onSubmit} disabled={isSending}>
          {isSending ? <i className="bx bx-loader-alt bx-spin" /> : translate('Delete')}
        </Button>
      </ModalFooter>
    </ModalEditKeywordStyled>
  );
};

const ModalEditKeywordStyled = styled(Modal)`
  min-width: 30rem;

  .btn-submit {
    width: 4.5rem;
    background-color: #f46a6a;
    border: none;
  }
  .btn-submit:hover {
    background-color: #f23d3d;
  }
`;

ModalDeleteKeyword.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  keyword: PropTypes.object,
  idSelected: PropTypes.string,
  fetchGroup: PropTypes.func,
  fetchLimitUsedFromFirebase: PropTypes.func,
};

export default ModalDeleteKeyword;
