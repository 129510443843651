import styled from 'styled-components';
import { Dropdown } from 'reactstrap';

export const ButtonAddTagStyles = styled(Dropdown)`
  .dropdown-toggle {
    display: flex;
    padding: 0.125rem 0.375rem;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 0.25rem;
    border: 1px dashed #74788d;
    color: #74788d !important;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      background-color: #f8f9fa;
    }
  }

  .dropdown-menu {
    width: 21.875rem;
    padding: 0.5rem;
  }

  .form-label {
    margin: 0;
  }

  .span-obs-tag {
    color: #808080;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1rem;
  }

  .btn-add-tag {
    height: 2.75rem;
    width: min-content;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    background: #556ee6;
    margin-top: 0.625rem;
    align-self: self-end;
  }
`;
