import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import styled from 'styled-components';

const CustomPagination = ({ totalPages, currentPage, onPageChange, linesByPage }) => {
  const navigate = useNavigate();

  const handleClick = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      onPageChange(page);
      navigate(`?page=${page}&limit=${linesByPage}`);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => handleClick(i)}>{i}</PaginationLink>
        </PaginationItem>,
      );
    }
    return pages;
  };

  return (
    <CustomPaginationStyled>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink first onClick={() => handleClick(1)} />
      </PaginationItem>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink previous onClick={() => handleClick(currentPage - 1)} />
      </PaginationItem>
      {renderPageNumbers()}
      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink next onClick={() => handleClick(currentPage + 1)} />
      </PaginationItem>
      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink last onClick={() => handleClick(totalPages)} />
      </PaginationItem>
    </CustomPaginationStyled>
  );
};

const CustomPaginationStyled = styled(Pagination)`
  ul {
    margin: 0 !important;
  }
`;

CustomPagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  linesByPage: PropTypes.number,
};

export default CustomPagination;
