import * as React from 'react';

const CalendarIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M7.49826 2.99219V5.99344"
        stroke="#556EE6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5019 2.99219V5.99344"
        stroke="#556EE6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49826 2.99219V5.99344"
        stroke="#556EE6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5019 2.99219V5.99344"
        stroke="#556EE6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99925 20.9991H5.99759C4.34004 20.9991 2.99634 19.6554 2.99634 17.9978V7.49344C2.99634 5.83589 4.34004 4.49219 5.99759 4.49219H18.0026C19.6601 4.49219 21.0038 5.83589 21.0038 7.49344V9.99448"
        stroke="#556EE6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="16.5019"
        cy="16.5019"
        r="4.50187"
        stroke="#556EE6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2009 14.5156V16.7996H18.0967"
        stroke="#556EE6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { CalendarIcon };
