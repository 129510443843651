import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import PropTypes from 'prop-types';

import { ButtonSelectGroupStyled } from './styles';

const ButtonSelectGroup = ({ groups, selectedIdGroup, setSelectedIdGroup, disabled }) => {
  return (
    <ButtonSelectGroupStyled>
      <DropdownToggle className="btn-dropdown-toggle-group" disabled={disabled}>
        {groups.map((group) => {
          if (group.id === selectedIdGroup) {
            return group.name;
          }
        })}
        <i className="bx bx-chevron-down" />
      </DropdownToggle>
      <DropdownMenu>
        {groups.map((group, index) => (
          <DropdownItem
            key={group.id}
            onClick={() => setSelectedIdGroup(group.id)}
            style={{ backgroundColor: selectedIdGroup === index ? '#f7fAfC' : '#ffffff' }}
          >
            {group.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonSelectGroupStyled>
  );
};

ButtonSelectGroup.propTypes = {
  groups: PropTypes.array,
  selectedIdGroup: PropTypes.string,
  setSelectedIdGroup: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ButtonSelectGroup;
