import styled from 'styled-components';

export const TableKeywordsStyled = styled.div`
  border: 1px solid #eff2f7;
  border-radius: 0.5rem;
  height: fit-content;
  width: 100%;
  overflow: hidden;

  table {
    margin: 0;

    th {
      background-color: #f8f9fa;
      height: 2.5rem;
      padding: 0.5rem 1.5rem;
      vertical-align: middle;
      &:not(:first-child):not(:nth-child(2)) {
        text-align: center;
      }
    }

    td {
      background-color: #ffffff;
      min-height: 4.5rem;
      overflow: visible;
      padding: 0.5rem 1.5rem;
      vertical-align: middle;
      &:first-child {
        width: 100%;
        i {
          margin-left: 1rem;
          font-size: 1.25rem;
          color: #7367f0;
          vertical-align: middle;
        }
      }
      &:last-child,
      :nth-last-child(4) {
        max-width: 30rem;
      }
      &:nth-last-child(4) {
        min-width: 15rem;
      }
      &:nth-last-child(3),
      &:nth-last-child(2),
      &:last-child {
        text-align: center;
      }

      .avatar-element {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    .text-no-groups {
      color: #74788d;
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    .btn-action {
      width: 2.3125rem;
      height: 1.9375rem;
      padding: 0.3rem;

      i {
        font-size: 1.25rem;
      }
    }

    .btn-action-edit {
      border: none;
      background-color: #50a5f1;
      &:hover {
        background-color: #1c8ef2;
      }
    }

    .btn-action-delete {
      border: none;
      background-color: #f46a6a;
      &:hover {
        background-color: #f23d3d;
      }
    }
  }
`;
