import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { map, size, cloneDeep } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../../../helpers/firebaseHelper';
import { formatDate, translateArray } from '../../../../utils';

import {
  priorityColorClass,
  impactTooltip,
  complexityTooltip,
  statusColorClass,
  statusNumber,
} from '../../../../constants/index';
import UserAvatar from 'components/UserAvatar';

const KanbanCard = ({ data, action }) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user.account;
  const firebaseHelper = getFirebaseBackend();
  const [task, setTask] = useState();

  const numComments = data.commentCount || 0;

  const setTaskData = useCallback(async () => {
    const taskData = cloneDeep(data);
    // Convert the UNIX value to a JavaScript Date Object
    taskData.dateBegin = taskData.dateBegin ? new Date(taskData.dateBegin) : null;
    taskData.dateDelivery = taskData.dateDelivery ? new Date(taskData.dateDelivery) : null;
    const assignedTo = Array.isArray(taskData.assignedTo) ? taskData.assignedTo : [];
    taskData.assignedToNames = await Promise.all(
      assignedTo.map(async (member) => await firebaseHelper.userName(member)),
    );
    if (taskData.hasChecklist) {
      taskData.checkStats = await firebaseHelper.getChecklistStats({
        accountId,
        projectId: '1',
        taskId: taskData.id,
      });
    }

    setTask(taskData);
  }, [data]);

  useEffect(() => {
    setTaskData();
  }, [setTaskData]);

  return (
    <React.Fragment key={task?.id}>
      <Card onClick={() => action(task)} className="card-responsive">
        <CardBody className="borad-width">
          <div className="float-end ms-2 d-flex flex-column align-items-end gap-1">
            {task?.hasFiles ? (
              <box-icon type="regular" name="paperclip" color="#b4b4b4" size="20px" />
            ) : null}
            {task?.hasChecklist ? (
              <div className="d-flex flex-row justify-content-center align-items-center gap-1">
                <p style={{ margin: 0, color: '#b4b4b4' }}>
                  {task?.checkStats ? task?.checkStats : '(0/0)'}
                </p>
                <box-icon type="regular" name="list-ul" color="#b4b4b4" size="20px" />
              </div>
            ) : null}
            {numComments && numComments > 0 ? (
              <div className="d-flex flex-row justify-content-center align-items-center gap-1">
                <p style={{ margin: 0, color: '#b4b4b4' }}>{numComments}</p>
                <box-icon name="message-square-detail" color="#b4b4b4" size="20px" />
              </div>
            ) : null}
          </div>
          <div>
            <h5 className="font-size-15">{task?.name ? task.name : ''}</h5>
          </div>
          <div className="mb-3">
            <div
              className="d-flex align-items-center"
              style={{ lineHeight: '12px', fontSize: '12px', color: '#ADB5BD' }}
            >
              <box-icon type="regular" name="calendar-alt" color="#ADB5BD" size="15px" />
              <span className="info--text">
                {task?.dateDelivery
                  ? formatDate(task?.dateDelivery, translate('date-enUS'))
                  : 'N/A'}
              </span>
            </div>
            <div
              className="info--row d-flex align-items-center"
              style={{ lineHeight: '12px', fontSize: '12px', color: '#ADB5BD' }}
            >
              <box-icon type="regular" name="purchase-tag-alt" color="#ADB5BD" size="15px" />
              <span className="info--text" id={`foundation-${task?.id}`}>
                {task?.foundations && task?.foundations.length > 3
                  ? `${task?.foundations?.map(translateArray)?.slice(0, 3)?.toLocaleString()},+${
                      task?.foundations.length - 3
                    }`
                  : task?.foundations?.map(translateArray)?.toLocaleString()}
              </span>
              <UncontrolledTooltip
                defaultOpen={false}
                flip
                placement="right"
                target={`foundation-${task?.id}`}
              >
                {task?.foundations?.map(translateArray)?.toLocaleString()}
              </UncontrolledTooltip>
            </div>
          </div>

          <div className="avatar-element">
            <div className="avatar-group">
              {map(task?.assignedTo, (member, index) => {
                if (!(index < 2)) return;

                return (
                  <div className="avatar-group-item" key={index}>
                    <div
                      alt={`${translate('Photo of')} ${task?.assignedToNames[index]}`}
                      id={`card-${member}-${task?.id}`}
                    >
                      <UserAvatar userId={member} />
                    </div>
                    <UncontrolledTooltip
                      defaultOpen={false}
                      flip
                      placement="right"
                      target={`card-${member}-${task?.id}`}
                    >
                      {task?.assignedToNames[index]}
                    </UncontrolledTooltip>
                  </div>
                );
              })}
              {size(task?.assignedTo) > 2 && (
                <div className="avatar-group-item">
                  <div className="avatar-xs">
                    <span
                      className="avatar-title rounded-circle text-white font-size-16"
                      style={{ backgroundColor: 'black' }}
                    >
                      {`${size(task?.assignedTo) - 2}+`}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="third-row">
              <div className="priority-element">
                {task?.complexity ? (
                  <>
                    <div
                      className={`priority ${priorityColorClass[task?.complexity]}`}
                      id={`complexity-${task?.id}`}
                    >
                      {translate(task?.complexity)}
                    </div>
                    <UncontrolledTooltip
                      defaultOpen={false}
                      flip
                      placement="right"
                      target={`complexity-${task?.id}`}
                    >
                      {translate(complexityTooltip[task?.complexity])}
                    </UncontrolledTooltip>
                  </>
                ) : null}

                {task?.impact ? (
                  <>
                    <div
                      className={`priority ${priorityColorClass[task?.impact]}`}
                      id={`impact-${task?.id}`}
                    >
                      {translate(task?.impact)}
                    </div>
                    <UncontrolledTooltip
                      defaultOpen={false}
                      flip
                      placement="right"
                      target={`impact-${task?.id}`}
                    >
                      {translate(impactTooltip[task?.impact])}
                    </UncontrolledTooltip>
                  </>
                ) : null}
              </div>
              <span
                className={`status ${
                  statusColorClass[task?.status]
                } badge rounded-pill font-size-12`}
              >
                {translate(statusNumber[task?.status])}
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

KanbanCard.propTypes = {
  data: PropTypes.object,
  dragging: PropTypes.any,
  action: PropTypes.func,
};

const propsAreEqual = (prevProps, nextProps) => {
  const isActionEquals = prevProps.action === nextProps.action;
  const isDataEquals = JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);

  return isActionEquals && isDataEquals;
};

export default memo(KanbanCard, propsAreEqual);
