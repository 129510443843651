import styled from 'styled-components';

export const ButtonFilterStyles = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    height: 2.5rem;
    padding: 0.625rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background-color: transparent;
    color: #343a40 !important;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }
  button:hover {
    background-color: #f7fafc;
    border: 1px solid #e2e8f0;
  }
  button:disabled {
    cursor: not-allowed;
    background-color: #f7fafc;
    border: 1px solid #e2e8f0;
  }

  i {
    width: 1rem;
    height: 1rem;
  }
`;
