import styled from 'styled-components';

export const ButtonAddGroupStyles = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    height: 2.5rem;
    padding: 0.6rem 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #556ee6;
    color: #ffffff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }
  button:hover {
    color: #ffffff;
    background-color: #3b5bfc;
  }

  i {
    width: 1rem;
    height: 1rem;
    color: #ffffff;
  }
`;
