import { getFirebaseBackend } from './firebaseHelper';

/**
 * Retrieves the URL of the user's avatar.
 *
 * @param {string} userId - The ID of the user.
 * @returns {Promise<string|null>} A promise that resolves to the URL of the user's avatar, or null if no avatar is found.
 */
async function userPhoto(userId) {
  try {
    const firebaseHelper = getFirebaseBackend();
    const path = `${userId}/profilePic`;
    const profilePicMeta = await firebaseHelper.listAllFiles(path);
    if (profilePicMeta.length) {
      const profilePicFile = await firebaseHelper.getFileObj(`${path}/${profilePicMeta[0].name}`);
      const profilePicUrl = URL.createObjectURL(profilePicFile);
      return profilePicUrl;
    }
    return null;
  } catch (error) {
    return null;
  }
}

const getFile = {
  userAvatar: userPhoto,
};

export default getFile;
