import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Button, Row, Col, Card, CardBody } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { translateArray } from 'utils';
import '../../assets/scss/datatables.scss';

const ListAccounts = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [accounts, setAccounts] = useState(null);

  const activated = (
    <p style={{ color: 'rgba(52, 195, 143, 1)', margin: '0 0' }}>{translate('Activated')}</p>
  );
  const deactivated = (
    <p style={{ color: 'rgba(244, 106, 106, 1)', margin: '0 0' }}>{translate('Deactivated')}</p>
  );

  const actionButton = ({ id: id }) => {
    return (
      <div style={{ display: 'flex' }}>
        <Link to={`/edit-account?id=${id}`}>
          <Button style={{ border: 'none', backgroundColor: '#E0E0E0' }}>
            <i className="bx bx-pencil" style={{ color: '#495057' }} />{' '}
            <span style={{ color: 'rgba(106, 113, 135, 1)' }}>{translate('Edit')}</span>
          </Button>
        </Link>
      </div>
    );
  };

  const fetchAllAccounts = async () => {
    const accounts = await firebaseHelper.getAllAccounts();
    setAccounts(accounts);
  };

  useEffect(() => {
    if (!accounts) return;

    const newUserData = Object.values(accounts).map((accountInfo) => {
      return {
        id: accountInfo.id,
        name: accountInfo.name,
        services:
          accountInfo.services.slice(0, 4).map(translateArray).toLocaleString() +
          (accountInfo.services.length > 4 ? `, +${accountInfo.services.length - 4}` : ''),
        begin: new Date(accountInfo.beginDate).toLocaleDateString(),
        end: accountInfo.endDate ? new Date(accountInfo.endDate).toLocaleDateString() : 'N/A',
        status: accountInfo.status ? activated : deactivated,
        action: actionButton(accountInfo),
      };
    });
    setUserData(newUserData);
  }, [accounts]);

  useEffect(() => {
    fetchAllAccounts();
  }, []);

  const columns = [
    {
      dataField: 'name',
      text: translate('Name'),
      sort: true,
    },
    {
      dataField: 'services',
      text: translate('Services'),
      sort: true,
    },
    {
      dataField: 'begin',
      text: translate('Begin'),
      sort: true,
    },
    {
      dataField: 'end',
      text: translate('End'),
      sort: true,
    },
    {
      dataField: 'status',
      text: translate('Status'),
      sort: true,
    },
    {
      dataField: 'action',
      text: translate('Action'),
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'asc',
    },
  ];

  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: userData.length },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: userData.length,
    custom: true,
    sizePerPageList,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content" style={{ minHeight: '100vh' }}>
        <MetaTags>
          <title>{translate('List Accounts')} | Ectools</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12" className="mb-4">
              <h4 className="mb-0 font-size-18">{translate('Accounts')}</h4>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={userData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider keyField="id" columns={columns} data={userData} search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2 d-flex justify-content-between">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={translate('Search')}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="4" className="d-flex flex-row-reverse align-items-center">
                                <Link to="/create-account">
                                  <Button
                                    style={{
                                      backgroundColor: 'rgba(85, 110, 230, 1)',
                                      borderColor: 'rgba(85, 110, 230, 1)',
                                    }}
                                  >
                                    <i className="bx bx-plus-circle" />{' '}
                                    {translate('Create new Account')}
                                  </Button>
                                </Link>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    hover
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListAccounts;
