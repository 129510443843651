import styled from 'styled-components';
import { Button } from 'reactstrap';

export const CardKeywordGroupStyles = styled(Button)`
  background-color: #f8f8fb;
  padding: 1.5rem;
  border: 1px solid #edf2f7;
  border-radius: 0.5rem;
  width: 100%;
  &:hover {
    background-color: #edf2f7;
    border: 1px solid #edf2f7;
  }
  &:disabled {
    background-color: #f8f8fb;
    border: 1px solid #edf2f7;
    color: #a0aec0;
    opacity: 1;
  }

  .card-title {
    color: #718096;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .card-info {
    color: #1a202c;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .icon-key,
  .icon-file {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 0.5rem;
  }

  .icon-key {
    background-color: #556ee6;
  }

  .icon-file {
    background-color: #2a8ae0;
  }

  .badge-soon {
    width: 100%;
    background-color: #fefcbf !important;
    color: #d69e2e;
    font-size: 0.75rem;
    font-weight: 500;

    .dot {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: #d69e2e;
      margin-right: 0.5rem;
    }
  }
`;
