import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { loadAccounts, normalizePhones, getFormValue } from '../../utils';
import { FILE_SIZE_5MB, stateOptions } from '../../constants/index';

import SuccessModal from '../../components/Success-Modal';
import Breadcrumbs from '../../components/Breadcrumb';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import TextAreaInput from '../../components/TextArea-Input';
import SelectInput from '../../components/Select-Input';
import DateInput from '../../components/Date-Input';
import SwitchInput from '../../components/Switch-Input';
import PhonesInput from '../../components/Phones-Input';
import SubmitButton from '../../components/Submit-Button';

const CreateUser = () => {
  // Form and State
  const formConfig = {
    defaultValues: {
      accountType: [],
      accounts: [],
      email: '',
      name: '',
      password: '',
      repeatPassword: '',
      tasks: true,
      content: false,
      keywordReport: false,
      occupation: '',
      description: '',
      birthdate: '',
      phones: [{ tag: '', number: '' }],
      zipCode: '',
      addressLine1: '',
      addressLine2: '',
      state: [],
      city: '',
      emailNotification: {
        newTask: false,
        assignedToTask: false,
        newComment: false,
        newMeeting: false,
      },
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };
  const firebaseHelper = getFirebaseBackend();
  const form = useForm(formConfig);
  const { formState, getValues, watch } = form;
  const { t: translate } = useTranslation();

  const [file, setFile] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [accountModules, setAccountModules] = useState([]);
  const [changedFile, setChangedFile] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const resetForm = () => {
    form.reset();
    handleFileSubmit(null);
  };

  const closeModalHandle = () => {
    resetForm();
    setSuccessModal(false);
  };

  const onSubmit = async (data) => {
    try {
      setIsSending(true);
      const user = {
        accounts: data.accounts.map(getFormValue),
        account: data.accounts[0].value,
        password: data.password,
        status: 'Pending',
        email: data.email,
        name: data.name,
        adminStatus: data.accountType.value,
        hasPhoto: changedFile ? Boolean(file) : false,
        file: file !== null ? file : null,
        modules: {
          tasks: data.tasks || false,
          content: data.content || false,
          keywordReport: data.keywordReport || false,
        },
        occupation: data.occupation ? data.occupation : null,
        description: data.description ? data.description : null,
        birthDate: data.birthdate ? Date.parse(data.birthdate) : null,
        phones: normalizePhones(data.phones),
        zipCode: data.zipCode ? data.zipCode : null,
        addressLine1: data.addressLine1 ? data.addressLine1 : null,
        addressLine2: data.addressLine2 ? data.addressLine2 : null,
        state: data.state.value ? data.state.value : null,
        city: data.city ? data.city : null,
        emailNotification: data.emailNotification,
      };

      const firebaseToken = await firebaseHelper.getIdToken();

      await axios.post(
        `${process.env.REACT_APP_API_URL}/createUser`,
        {
          email: data.email,
          password: data.password,
          userData: user,
          development: process.env.REACT_APP_BUILD_TYPE !== 'production',
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );

      setSuccessModal(true);
      handleRemoveFile();
      setIsSending(false);
    } catch (error) {
      console.error('Error creating user:', error);
      setIsSending(false);
    }
  };

  const handleFileSubmit = (photoFile) => {
    const loader = document.getElementById('loading-icon');
    const ghost = document.getElementById('ghost-icon');
    const image = document.getElementById('file-display');

    // Checks if a file was uploaded and if it matches the valid extensions and puts it as source for the img tag
    if (photoFile) {
      if (
        !(
          photoFile.type === 'image/png' ||
          photoFile.type === 'image/jpg' ||
          photoFile.type === 'image/jpeg'
        )
      ) {
        alert(
          translate('The profile photo file extesion needs to be one of the following') +
            ': png, jpg, jpeg.',
        );
        document.getElementById('file-input').value = null;
      } else if (photoFile.size > FILE_SIZE_5MB) {
        alert(translate('Max size of file is 5MB'));
        document.getElementById('file-input').value = null;
      } else {
        setChangedFile(true);
        setFile(photoFile);
        image.style.display = 'inline';
        image.src = URL.createObjectURL(photoFile);
        ghost.style.display = 'none';
        loader.style.display = 'none';
      }
    } else {
      setFile(null);
      ghost.style.display = 'inline';
      image.style.display = 'none';
      loader.style.display = 'none';
    }
  };

  const handleRemoveFile = (event) => {
    event?.preventDefault();
    setFile(null);
    handleFileSubmit(null);
    setChangedFile(true);
    document.querySelector('#file-input').value = null;
  };

  async function processAccountModules(accounts) {
    const accountsPromise = Promise.all(
      accounts.map(async ({ value }) => {
        const { modules } = await firebaseHelper.getAccount(value);
        return modules;
      }),
    );
    const reducedAccounts = (await accountsPromise).reduce((acc, cur) => {
      return {
        ...acc,
        ...cur,
      };
    }, {});
    const accountIds = reducedAccounts;

    setAccountModules(accountIds);
  }

  useEffect(() => {
    const accounts = getValues('accounts');
    if (!accounts.length) setAccountModules({});
    processAccountModules(accounts);
  }, [watch('accounts')]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{translate('Create User')} | Ectools</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={translate('Users')} breadcrumbItem={translate('Create')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <SuccessModal
                    isOpen={successModal}
                    onClose={closeModalHandle}
                    verb="Created"
                    buttonText="See Users"
                    buttonLink="/list-users"
                  />

                  <CardTitle className="mb-2">{translate('Add New User')}</CardTitle>

                  <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '49%' }}>
                          <LabelGroup
                            htmlFor="accountType"
                            label="Account Type"
                            mb={1}
                            column
                            required
                          >
                            <SelectInput
                              controlName="accountType"
                              required
                              options={[
                                { value: 'Client', label: 'Client' },
                                {
                                  value: 'Client Admin',
                                  label: 'Client Admin',
                                },
                                { value: 'Admin', label: 'Admin' },
                                { value: 'Super Admin', label: 'Super Admin' },
                              ]}
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="accounts" label="Accounts" mb={1} column required>
                            <SelectInput
                              asyncronous
                              isMulti
                              controlName="accounts"
                              required
                              cacheOptions
                              defaultOptions
                              loadOptions={(inputValue) => loadAccounts(inputValue)}
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="email" label="Email" mb={1} column required>
                            <TextInput
                              controlName="email"
                              placeholder="Email"
                              required
                              validation={{
                                pattern: {
                                  value: RegExp(/^\S+@\S+.+\S+$/),
                                  message: 'Invalid Email!',
                                },
                              }}
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="name" label="Name" mb={1} column required>
                            <TextInput controlName="name" placeholder="Name" required />
                          </LabelGroup>

                          <LabelGroup htmlFor="password" label="Password" mb={1} column required>
                            <TextInput
                              controlName="password"
                              placeholder="Password"
                              type="password"
                              required
                              validation={{
                                minLength: {
                                  value: 6,
                                  message: 'Password must have at least 6 characters!',
                                },
                              }}
                            />
                          </LabelGroup>

                          <LabelGroup
                            htmlFor="repeatPassword"
                            label="Repeat Password"
                            mb={3}
                            column
                            required
                          >
                            <TextInput
                              controlName="repeatPassword"
                              placeholder="Repeat Password"
                              type="password"
                              required
                              validation={{
                                validate: (value) => {
                                  const password = form.getValues('password');
                                  if (value && password) {
                                    return (
                                      form.getValues('password') === value ||
                                      'The Passwords need to match!'
                                    );
                                  } else {
                                    return true;
                                  }
                                },
                              }}
                            />
                          </LabelGroup>

                          <FormGroup className="mb-1 mt-3" row>
                            <Col lg="2">
                              <div
                                style={{
                                  height: '6rem',
                                  width: '6rem',
                                  border: '1px solid #CED4DA',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: '4px',
                                }}
                              >
                                <i
                                  id="ghost-icon"
                                  className="bx bx-ghost"
                                  style={{ fontSize: '4rem', color: '#D2D2D2' }}
                                />

                                <i
                                  id="loading-icon"
                                  className="bx bx-loader-alt loading-animation"
                                  style={{
                                    fontSize: '2rem',
                                    color: '#D2D2D2',
                                    display: 'none',
                                  }}
                                />

                                <img
                                  id="file-display"
                                  width="90%"
                                  style={
                                    file === null
                                      ? { display: 'none' }
                                      : {
                                          display: 'inline',
                                          maxHeight: '95%',
                                          objectFit: 'cover',
                                          objectPosition: 'center center',
                                        }
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg="9">
                              <Col
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Label
                                  style={{
                                    fontSize: '13px',
                                    height: '26px',
                                    color: '#495057',
                                    fontWeight: '400',
                                  }}
                                >
                                  {translate('Profile Photo')}
                                </Label>
                                {Boolean(file) && (
                                  <button
                                    className="remove-file-btn"
                                    onClick={(e) => handleRemoveFile(e)}
                                  >
                                    <box-icon
                                      type="regular"
                                      name="trash"
                                      color="#F46A6A"
                                      size="1.3rem"
                                    />
                                    <span>{translate('Remove Image')}</span>
                                  </button>
                                )}
                              </Col>
                              <Input
                                id="file-input"
                                className="form-control"
                                type="file"
                                onChange={(event) => handleFileSubmit(event.target.files[0])}
                                accept="image/png, image/jpg, image/jpeg"
                              />
                              <p className="upload-photo-default-caption">
                                {translate('Upload Photo Default Caption')}
                              </p>
                            </Col>
                          </FormGroup>

                          {Boolean(watch('accountType.value')) &&
                            Boolean(watch('accounts').length) && (
                              <LabelGroup htmlFor="tasks" label="Access Modules" mb={1} column>
                                {accountModules?.tasks && (
                                  <SwitchInput controlName="tasks" label="Tasks" />
                                )}
                                {(watch('accountType.value') === 'Admin' ||
                                  watch('accountType.value') === 'Super Admin') &&
                                  accountModules?.content && (
                                    <SwitchInput controlName="content" label="Content" />
                                  )}
                                {accountModules?.keywordReport && (
                                  <SwitchInput controlName="keywordReport" label="KeywordReport" />
                                )}
                              </LabelGroup>
                            )}
                        </div>

                        <div
                          style={{
                            height: 'auto',
                            width: '1px',
                            backgroundColor: '#E2E2E2',
                          }}
                        />

                        <div style={{ width: '49%' }}>
                          <LabelGroup htmlFor="occupation" label="Occupation" mb={1} column>
                            <TextInput controlName="occupation" placeholder="Occupation" />
                          </LabelGroup>

                          <LabelGroup htmlFor="description" label="Description" mb={1} column>
                            <TextAreaInput
                              controlName="description"
                              placeholder="Enter the description"
                              size={12}
                              mb={2}
                              manualResize
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="birthdate" label="Birthdate" mb={1} column>
                            <DateInput controlName="birthdate" placeholder="Birthdate" />
                          </LabelGroup>

                          <PhonesInput mb={1} />

                          <LabelGroup htmlFor="zipCode" label="Zip Code" mb={1} column>
                            <TextInput
                              controlName="zipCode"
                              placeholder="Zip Code"
                              mask="99999-999"
                              maskChar=" "
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="addressLine1" label="Address Line 1" mb={1} column>
                            <TextInput controlName="addressLine1" placeholder="Address Line 1" />
                          </LabelGroup>

                          <LabelGroup htmlFor="addressLine2" label="Address Line 2" mb={1} column>
                            <TextInput controlName="addressLine2" placeholder="Address Line 2" />
                          </LabelGroup>

                          <LabelGroup htmlFor="state" label="State and City" mb={1} column>
                            <Row>
                              <SelectInput size={3} controlName="state" options={stateOptions} />
                              <TextInput size={9} controlName="city" placeholder="City" />
                            </Row>
                          </LabelGroup>

                          <LabelGroup
                            htmlFor="emailNotification"
                            label="Email Notifications"
                            mb={1}
                            column
                          >
                            <SwitchInput
                              controlName="emailNotification.newTask"
                              label="New task created on the project"
                            />
                            <SwitchInput
                              controlName="emailNotification.assignedToTask"
                              label="Tasks assigned to me"
                            />
                            <SwitchInput
                              controlName="emailNotification.newComment"
                              label="New comments on tasks"
                            />
                            <SwitchInput
                              controlName="emailNotification.newMeeting"
                              label="New meeting created on the project"
                            />
                          </LabelGroup>
                        </div>
                      </div>

                      <SubmitButton
                        text="Register User"
                        justifyLeft
                        disabled={Boolean(Object.entries(formState.errors).length)}
                        isLoading={isSending}
                      />
                    </form>
                  </FormProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateUser;
