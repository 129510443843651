import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import SuccessModal from '../../components/Success-Modal';
import ErrorModal from '../../components/Error-Modal';
import Breadcrumbs from '../../components/Breadcrumb';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import TextAreaInput from '../../components/TextArea-Input';
import SelectInput from '../../components/Select-Input';
import DateInput from '../../components/Date-Input';
import SwitchInput from '../../components/Switch-Input';
import PhonesInput from '../../components/Phones-Input';
import SubmitButton from '../../components/Submit-Button';
import { normalizePhones, getFormValue } from '../../utils/';
import { ufToState, numberTag, stateOptions, FILE_SIZE_5MB } from '../../constants/index';
import '../../assets/scss/custom/pages/_users.scss';

const EditProfile = () => {
  // Form and State
  const formConfig = {
    defaultValues: {
      accountType: [],
      status: '',
      oldStatus: '',
      account: [],
      unchangedEmail: '',
      email: '',
      name: '',
      tasks: true,
      content: false,
      occupation: '',
      description: '',
      birthdate: '',
      phones: [{ tag: '', number: '' }],
      zipCode: '',
      addressLine1: '',
      addressLine2: '',
      state: [],
      city: '',
      emailNotification: {
        newTask: false,
        assignedToTask: false,
        newComment: false,
        newMeeting: false,
      },
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };

  const firebaseHelper = getFirebaseBackend();

  const form = useForm(formConfig);
  const { formState } = form;
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();
  const userId = user.id;
  const [file, setFile] = useState(null);
  const [changedFile, setChangedFile] = useState(false);
  const [updateSuccessfull, setUpdateSuccessfull] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const userAccounts = data.account.map(getFormValue);
    const loggedAccount = userAccounts.includes(data.fixedAccount)
      ? data.fixedAccount
      : userAccounts[0];
    const user = {
      id: userId,
      status: data.oldStatus,
      account: loggedAccount,
      accounts: userAccounts,
      email: data.email,
      name: data.name,
      adminStatus: data.accountType.value,
      hasPhoto: changedFile ? Boolean(file) : data.hasPhoto,
      modules: {
        tasks: data.tasks,
        content: data.content,
      },
      occupation: data.occupation ? data.occupation : null,
      description: data.description ? data.description : null,
      birthDate: data.birthdate ? Date.parse(data.birthdate) : null,
      phones: normalizePhones(data.phones),
      zipCode: data.zipCode ? data.zipCode : null,
      addressLine1: data.addressLine1 ? data.addressLine1 : null,
      addressLine2: data.addressLine2 ? data.addressLine2 : null,
      state: data?.state?.value ? data.state.value : null,
      city: data.city ? data.city : null,
      emailNotification: data.emailNotification ?? {
        newTask: false,
        assignedToTask: false,
        newComment: false,
      },
      createdAt: data.createdAt,
    };
    await firebaseHelper.updateUser(user);
    if (changedFile) {
      await firebaseHelper.updateUserPhoto(file, user.id);
    }
    setSuccessModal(true);
    updateUser();
    setIsLoading(false);
  };

  const closeModalHandle = () => {
    setFile(null);
    setSuccessModal(false);
    navigate(0);
  };

  const handleFileSubmit = (photoFile) => {
    const loader = document.getElementById('loading-icon');
    const ghost = document.getElementById('ghost-icon');
    const image = document.getElementById('file-display');

    // Checks if a file was uploaded and if it matches the valid extensions and puts it as source for the img tag
    if (photoFile) {
      if (
        !(
          photoFile.type === 'image/png' ||
          photoFile.type === 'image/jpg' ||
          photoFile.type === 'image/jpeg'
        )
      ) {
        alert(
          translate('The profile photo file extesion needs to be one of the following') +
            ': png, jpg, jpeg.',
        );
        document.getElementById('file-input').value = null;
      } else if (photoFile.size > FILE_SIZE_5MB) {
        alert(translate('Max size of file is 5MB'));
        document.getElementById('file-input').value = null;
      } else {
        setChangedFile(true);
        setFile(photoFile);
        image.style.display = 'inline';
        image.src = URL.createObjectURL(photoFile);
        ghost.style.display = 'none';
        loader.style.display = 'none';
      }
    } else {
      setFile(null);
      ghost.style.display = 'inline';
      image.style.display = 'none';
      loader.style.display = 'none';
    }
  };

  const handleRemoveFile = (event) => {
    event.preventDefault();
    setFile(null);
    handleFileSubmit(null);
    setChangedFile(true);
    document.querySelector('#file-input').value = null;
  };

  const activateLoader = () => {
    document.getElementById('loading-icon').style.display = 'inline';
    document.getElementById('ghost-icon').style.display = 'none';
    document.getElementById('file-display').style.display = 'none';
  };

  const handleChangePassword = () => {
    firebaseHelper.logout();
    navigate('/forgot-password');
  };

  const getUser = async () => {
    try {
      const user = await firebaseHelper.getUser(userId);
      let processedAccount;
      if (user.accounts) {
        processedAccount = await Promise.all(
          user.accounts.map(async (account) => ({
            value: account,
            label: await firebaseHelper.accountName(account),
          })),
        );
      } else {
        processedAccount = [
          {
            value: user.account,
            label: await firebaseHelper.accountName(user.account),
          },
        ];
      }
      const processedPhones = user.phones
        ? user.phones.map(({ tag, number }) => ({
            number,
            tag: {
              value: tag,
              label: translate(numberTag[tag]),
            },
          }))
        : [{ tag: '', number: '' }];
      form.reset({
        accountType: {
          value: user.adminStatus,
          label: translate(user.adminStatus),
        },
        fixedAccount: user.account,
        account: processedAccount,
        email: user.email,
        name: user.name,
        tasks: user?.modules?.tasks === true,
        content: user?.modules?.content === true,
        occupation: user?.occupation,
        description: user?.description,
        birthdate: user.birthDate ? new Date(user.birthDate) : '',
        zipCode: user?.zipCode,
        addressLine1: user?.addressLine1,
        addressLine2: user?.addressLine2,
        state: user.state ? { value: user.state, label: ufToState[user.state] } : null,
        city: user?.city,
        phones: processedPhones,
        oldStatus: user.status,
        status: { value: user.status, label: translate(user.status) },
        hasPhoto: user.hasPhoto,
        emailNotification: {
          assignedToTask: false,
          newTask: false,
          newComment: false,
          newMeeting: false,
          ...user.emailNotification,
        },
        createdAt: user.createdAt,
      });
      if (user.hasPhoto) {
        activateLoader();
        const path = `${user.id}/profilePic`;
        const profilePicMeta = await firebaseHelper.listAllFiles(path);
        if (profilePicMeta) {
          const profilePicFile = await firebaseHelper.getFileObj(
            `${path}/${profilePicMeta[0].name}`,
          );
          handleFileSubmit(profilePicFile);
          setChangedFile(false);
        }
      }
      setUpdateSuccessfull(true);
    } catch (error) {
      console.error(error);
      setErrorModal(true);
    }
  };

  useEffect(async () => {
    if (!userId) return;

    getUser();
  }, [userId]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{translate('Edit Profile')} | Ectools</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={translate('My Profile')} breadcrumbItem={translate('Edit')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <SuccessModal
                    isOpen={successModal}
                    onClose={closeModalHandle}
                    verb="Updated"
                    buttonText="Home"
                    buttonLink="/"
                  />

                  <ErrorModal isOpen={errorModal} />

                  <CardTitle className="mb-2">{translate('Edit Profile Information')}</CardTitle>

                  <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '49%' }}>
                          <LabelGroup htmlFor="email" label="Email" mb={1} column required>
                            <TextInput
                              controlName="email"
                              placeholder="Email"
                              required
                              disabled
                              validation={{
                                pattern: {
                                  value: RegExp(/^\S+@\S+.+\S+$/),
                                  message: 'Invalid Email!',
                                },
                              }}
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="name" label="Name" mb={1} column required>
                            <TextInput controlName="name" placeholder="Name" required />
                          </LabelGroup>

                          <FormGroup className="mb-1" row>
                            <Col lg="11">
                              <div
                                className="mt-3 d-flex align-items-center"
                                style={{ color: '#50A5F1', gap: '1em' }}
                              >
                                <i className="bx bx-lock" style={{ fontSize: '1.4em' }} />
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    lineHeight: '1em',
                                  }}
                                  onClick={handleChangePassword}
                                >
                                  {translate('Redefine Password')}
                                </span>
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-1 mt-3" row>
                            <Col lg="2">
                              <div
                                style={{
                                  height: '6rem',
                                  width: '6rem',
                                  border: '1px solid #CED4DA',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: '4px',
                                }}
                              >
                                <i
                                  id="ghost-icon"
                                  className="bx bx-ghost"
                                  style={{ fontSize: '4rem', color: '#D2D2D2' }}
                                />

                                <i
                                  id="loading-icon"
                                  className="bx bx-loader-alt loading-animation"
                                  style={{
                                    fontSize: '2rem',
                                    color: '#D2D2D2',
                                    display: 'none',
                                  }}
                                />

                                <img
                                  id="file-display"
                                  width="90%"
                                  style={
                                    file === null
                                      ? { display: 'none' }
                                      : {
                                          display: 'inline',
                                          maxHeight: '95%',
                                          objectFit: 'cover',
                                          objectPosition: 'center center',
                                        }
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg="9">
                              <Col
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Label
                                  style={{
                                    fontSize: '13px',
                                    height: '26px',
                                    color: '#495057',
                                    fontWeight: '400',
                                  }}
                                >
                                  {translate('Profile Photo')}
                                </Label>
                                {Boolean(file) && (
                                  <button
                                    className="remove-file-btn"
                                    onClick={(e) => handleRemoveFile(e)}
                                  >
                                    <box-icon
                                      type="regular"
                                      name="trash"
                                      color="#F46A6A"
                                      size="1.3rem"
                                    />
                                    <span>{translate('Remove Image')}</span>
                                  </button>
                                )}
                              </Col>
                              <Input
                                id="file-input"
                                className="form-control"
                                type="file"
                                onChange={(event) => handleFileSubmit(event.target.files[0])}
                                accept="image/png, image/jpg, image/jpeg"
                              />
                              <p className="upload-photo-default-caption">
                                {translate('Upload Photo Default Caption')}
                              </p>
                            </Col>
                          </FormGroup>

                          <LabelGroup
                            htmlFor="emailNotification"
                            label="Email Notifications"
                            mb={1}
                            column
                          >
                            <SwitchInput
                              controlName="emailNotification.newTask"
                              label="New task created on the project"
                            />
                            <SwitchInput
                              controlName="emailNotification.assignedToTask"
                              label="Tasks assigned to me"
                            />
                            <SwitchInput
                              controlName="emailNotification.newComment"
                              label="New comments on tasks"
                            />
                            <SwitchInput
                              controlName="emailNotification.newMeeting"
                              label="New meeting created on the project"
                            />
                          </LabelGroup>
                        </div>

                        <div
                          style={{
                            height: 'auto',
                            width: '1px',
                            backgroundColor: '#E2E2E2',
                          }}
                        />

                        <div style={{ width: '49%' }}>
                          <LabelGroup htmlFor="occupation" label="Occupation" mb={1} column>
                            <TextInput controlName="occupation" placeholder="Occupation" />
                          </LabelGroup>

                          <LabelGroup htmlFor="description" label="Description" mb={1} column>
                            <TextAreaInput
                              controlName="description"
                              placeholder="Enter the description"
                              size={12}
                              mb={2}
                              manualResize
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="birthdate" label="Birthdate" mb={1} column>
                            <DateInput controlName="birthdate" placeholder="Birthdate" />
                          </LabelGroup>

                          <PhonesInput mb={1} />

                          <LabelGroup htmlFor="zipCode" label="Zip Code" mb={1} column>
                            <TextInput
                              controlName="zipCode"
                              placeholder="Zip Code"
                              mask="99999-999"
                              maskChar=" "
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="addressLine1" label="Address Line 1" mb={1} column>
                            <TextInput controlName="addressLine1" placeholder="Address Line 1" />
                          </LabelGroup>

                          <LabelGroup htmlFor="addressLine2" label="Address Line 2" mb={1} column>
                            <TextInput controlName="addressLine2" placeholder="Address Line 2" />
                          </LabelGroup>

                          <LabelGroup htmlFor="state" label="State and City" mb={1} column>
                            <Row>
                              <SelectInput size={3} controlName="state" options={stateOptions} />
                              <TextInput size={9} controlName="city" placeholder="City" />
                            </Row>
                          </LabelGroup>
                        </div>
                      </div>

                      <SubmitButton
                        text="Update Profile"
                        justifyLeft
                        disabled={Boolean(
                          Object.values(formState.errors).length || !updateSuccessfull || isLoading,
                        )}
                        isLoading={isLoading}
                      />
                    </form>
                  </FormProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditProfile;
