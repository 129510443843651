import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'reactstrap';
import _ from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import { TableLastTasksStyled } from './styles';
import { statusNumber } from '../../../../constants';
import UserAvatar from 'components/UserAvatar';

const TableLastTasks = ({ data }) => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user?.account;

  const avatarElem = (assignedTo) => {
    return (
      <div className="avatar-group justify-content-center">
        {_.map(assignedTo, (member, index) => {
          if (index > 2) return;

          return (
            <div className="avatar-group-item" key={index}>
              <UserAvatar userId={member} />
            </div>
          );
        })}
        {_.size(assignedTo) > 2 && (
          <div className="avatar-group-item">
            <div className="avatar-xs">
              <span
                className="avatar-title rounded-circle text-white font-size-16"
                style={{ backgroundColor: 'black' }}
              >
                {`${_.size(assignedTo) - 2}+`}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <TableLastTasksStyled>
      <span className="title">{translate('Latest added tasks')}</span>
      <div className="div-bordered w-100">
        <Table>
          <thead>
            <tr>
              <th>{translate('Task')}</th>
              <th style={{ width: '12rem' }}>{translate('Guidance status')}</th>
              <th style={{ width: '10.5rem' }}>{translate('Implementation')}</th>
              <th style={{ width: '10.5rem' }}>{translate('Assigned to')}</th>
              <th style={{ width: '10.5rem' }}>{translate('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((task, index) => (
              <tr key={index}>
                <td className="task-name">{task.name}</td>
                <td>
                  <span className="badge-column">{translate(task.column)}</span>
                </td>
                <td>
                  <span className={`badge-status badge-status-${task.status}`}>
                    {translate(statusNumber[task.status])}
                  </span>
                </td>
                <td>{avatarElem(task.assignedTo)}</td>
                <td>
                  <Button
                    className="button-task"
                    onClick={() => navigate(`/tasks?id=${task.id}&acc=${accountId}`)}
                  >
                    {translate('to view')}
                    <i className="bx bx-right-arrow-alt" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </TableLastTasksStyled>
  );
};

TableLastTasks.propTypes = {
  data: PropTypes.array,
};

export default TableLastTasks;
