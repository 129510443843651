import i18n from 'i18n';

const accountServices = [
  {
    value: 'SEO Consultancy',
    label: i18n.t('SEO Consultancy'),
  },
  {
    value: 'SEO Training',
    label: i18n.t('SEO Training'),
  },
  {
    value: 'Local SEO',
    label: i18n.t('Local SEO'),
  },
  {
    value: 'SEO for Apps (ASO)',
    label: i18n.t('SEO for Apps (ASO)'),
  },
  {
    value: 'SEO for Youtube',
    label: i18n.t('SEO for Youtube'),
  },
  {
    value: 'Migration',
    label: i18n.t('Migration'),
  },
];

export { accountServices };
