import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const LineLimit = ({ limit, limitUsed }) => {
  const { t: translate } = useTranslation();

  return (
    <div className="d-flex gap-1 align-items-center limit-container">
      <i className="bx bx-key" />
      <div className="d-flex gap-1 align-items-center">
        <span>{translate('Limit')}:</span>
        <span className="total-keywords">{limitUsed}</span>
        <span>/{limit}</span>
      </div>
    </div>
  );
};

LineLimit.propTypes = {
  limit: PropTypes.number,
  limitUsed: PropTypes.number,
};

export default LineLimit;
