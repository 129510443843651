export const getInitials = (name) => {
  if (!name) return '';
  const refinedName = name.replace(/[^a-zA-Z- ]/g, '');
  if (refinedName.length === 0) return '';
  const nameArray = refinedName.split(' ').filter((word) => word.length > 0);
  if (nameArray.length === 1) {
    return nameArray[0][0].toUpperCase();
  }
  return nameArray[0][0].toUpperCase() + nameArray[1][0].toUpperCase();
};
