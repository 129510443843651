import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonTag = ({ tagName, inBulk, setTags, setData, keywordName, isStatic }) => {
  const removeTag = () => {
    if (inBulk) {
      setTags((prev) => prev.filter((tag) => tag !== tagName));
    } else {
      setData((prevData) => {
        return prevData.map((item) => {
          if (item.name === keywordName) {
            const updatedTags = item.tags.filter((tag) => tag !== tagName);

            return {
              ...item,
              tags: updatedTags,
            };
          }
          return item;
        });
      });
    }
  };

  return isStatic ? (
    <ButtonTagStyled disabled={isStatic}>{tagName}</ButtonTagStyled>
  ) : (
    <ButtonTagStyled onClick={removeTag}>
      {tagName} <i className="bx bx-x" />
    </ButtonTagStyled>
  );
};

const ButtonTagStyled = styled(Button)`
  display: flex;
  min-height: 1.65rem;
  padding: 0.125rem 0.375rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid #d9d9d9;
  background: #f8f9fa;
  color: #343a40 !important;
  font-size: 0.8125rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:hover {
    border-color: #b3b3b3;
    background: #f2f3f3;
  }
  &:active {
    background-color: #d9d9d9;
  }
  &:disabled {
    cursor: not-allowed;
    background: #f8f9fa;
    color: #74788d !important;
  }
`;

ButtonTag.propTypes = {
  tagName: PropTypes.string,
  inBulk: PropTypes.bool,
  setTags: PropTypes.func,
  setData: PropTypes.func,
  keywordName: PropTypes.string,
  isStatic: PropTypes.bool,
};

export default ButtonTag;
