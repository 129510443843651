import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useAuth } from 'hooks/useAuth';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { MoonLoader } from 'react-spinners';

import { PublishedDataStyle } from './styles';

const PublishedData = ({ setDate, date, setPublishedUrl, publishedUrl, isDisabled }) => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;

  const [saveUrlStatus, setSaveUrlStatus] = useState('');

  const normalizeUrl = (url) => {
    const hasHttpPrefix = url.startsWith('http://') || url.startsWith('https://');
    return hasHttpPrefix ? url : `http://${url}`;
  };

  const openInputLink = () => {
    const urlInput = document.getElementById('publish-link').value;
    const url = normalizeUrl(urlInput);
    window.open(url, '_blank');
  };

  const debounceSavePublishedDate = _.debounce(async (date) => {
    await firebaseHelper.savePublishedDateContent(currentAccountId, id, date.valueOf());
  }, 500);

  const handleDate = (value) => {
    setDate(value);
    debounceSavePublishedDate(value);
  };

  const savePublishedUrl = useCallback(
    _.debounce(async (url) => {
      try {
        if (!url) {
          setSaveUrlStatus('');
          return;
        }
        setSaveUrlStatus('loading');
        await firebaseHelper.savePublishedUrlContent(currentAccountId, id, url);

        setPublishedUrl(url);
        setSaveUrlStatus('saved');
      } catch (error) {
        console.log(error);
        setSaveUrlStatus('error');
      }
    }, 1000),
    [currentAccountId, id],
  );

  useEffect(() => {
    const fetchPublishedUrl = async () => {
      try {
        const fetchedUrl = await firebaseHelper.getPublishedUrlContent(currentAccountId, id);
        setPublishedUrl(fetchedUrl);
        publishedUrl && setSaveUrlStatus('saved');
      } catch (error) {
        console.error(error);
      }
    };

    fetchPublishedUrl();
  }, [currentAccountId, id]);

  return (
    <PublishedDataStyle publishedUrl={!publishedUrl ? '' : 'none'}>
      <p className="subtitle">{translate('Information')}</p>
      <FormGroup>
        <Label className="form-label" for="date">
          {translate('Publish Date')}
        </Label>
        <DatePickerStyled
          className="form-control"
          locale={translate('en-US')}
          selected={date}
          dateFormat={'dd/MM/yyyy'}
          onChange={(value) => handleDate(value)}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label className="form-label" for="link">
          {translate('Publish Link')}
        </Label>
        <div className="url-input">
          <Input
            name="link"
            id="publish-link"
            placeholder={translate('Paste here the post link')}
            onChange={(event) => {
              setPublishedUrl(event.target.value);
              savePublishedUrl(event.target.value);
            }}
            value={publishedUrl}
            disabled={isDisabled}
          />
          {publishedUrl && (
            <>
              <Button className="open-link-button" onClick={openInputLink}>
                <box-icon type="regular" name="link-external" color="#2D3748" size="1.5em" />
              </Button>

              {saveUrlStatus === 'saved' && !isDisabled && (
                <div className="input-icon">
                  <box-icon type="regular" name="check" color="#48BB78" size="1.5em" />
                </div>
              )}
              {saveUrlStatus === 'error' && (
                <div className="input-icon">
                  <box-icon type="regular" name="x" color="#F56565" size="1.5em" />
                </div>
              )}
              {saveUrlStatus === 'loading' && (
                <div className="input-icon">
                  <MoonLoader size={12} color="#74788D" />
                </div>
              )}
            </>
          )}
        </div>
        {!publishedUrl && (
          <div className="error-text mt-1">
            * {translate('You must enter the publication link to mark as published')}
          </div>
        )}
      </FormGroup>
    </PublishedDataStyle>
  );
};

const DatePickerStyled = styled(DatePicker)`
  line-height: 1.5;
  width: 100%;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
`;

PublishedData.propTypes = {
  setDate: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  setPublishedUrl: PropTypes.func,
  publishedUrl: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default PublishedData;
