import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

import { selectCustomStyles } from 'utils';
import DisplayErrors from 'components/Display-Errors';
import Styles from './styles';

const NoOptionsMessage = (props) => {
  const { t: translate } = useTranslation();
  const externalValue = props.selectProps.inputValue;
  const currentValue = props.selectProps.value;
  const setValue = props.setValue;

  const handleAddExternal = () => {
    setValue([
      ...currentValue,
      { value: `external:${externalValue}`, label: `${externalValue} (${translate('external')})` },
    ]);
  };
  return (
    <Styles.NoContentWrapper onClick={handleAddExternal}>
      <span className="name">{externalValue}</span>
      <div className="badge">
        <span>{translate('Add as external')}</span>
      </div>
    </Styles.NoContentWrapper>
  );
};

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object,
  setValue: PropTypes.func,
};

const ParticipantsSelectInput = ({
  disable = false,
  controlName,
  key,
  required = false,
  ...options
}) => {
  const { t: translate } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validate = required ? { required: 'Required Field!' } : {};

  return (
    <Col lg={12}>
      <Controller
        control={control}
        name={'participants'}
        rules={validate}
        render={({ field }) => {
          return (
            <AsyncSelect
              {...field}
              value={field.value}
              key={key}
              formatCreateLabel={(value) => translate('Other (Type): ') + `"${value}"`}
              onChange={(event) => {
                console.log(event);
                field.onChange(event);
              }}
              noOptionsMessage={() => translate('No options')}
              isDisabled={disable}
              styles={selectCustomStyles(field.name, errors)}
              isClearable
              components={{ NoOptionsMessage }}
              placeholder={translate('Select...')}
              {...options}
              isMulti={true}
              className="basic-multi-select"
              ref={field.ref}
            />
          );
        }}
      />

      <DisplayErrors error={errors?.[controlName]?.types} />
    </Col>
  );
};

ParticipantsSelectInput.propTypes = {
  disable: PropTypes.bool,
  controlName: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
};

export default ParticipantsSelectInput;
