import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonAddGroupStyles } from './styles';

const ButtonAddGroup = ({ onClickFunction }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonAddGroupStyles>
      <button onClick={() => onClickFunction()}>
        {translate('Add group')}
        <i className="bx bx-plus" />
      </button>
    </ButtonAddGroupStyles>
  );
};

ButtonAddGroup.propTypes = {
  onClickFunction: PropTypes.func,
};

export default ButtonAddGroup;
