import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { AccordionHeader, AccordionBody } from 'reactstrap';

export const AccordionTitle = styled(AccordionHeader)`
  background-color: #fff;
  border-radius: 5px;
  button {
    font-weight: 500;
    font-size: 1rem;
  }
  /* Change colors of closed accordion from blue theme to black and white */
  button:not(.collapsed) {
    background-color: #fff;
    color: #000;
  }
  /* Change arrow color from blue to black to match open accordion */
  button:not(.collapsed)::after {
    filter: grayscale(100%);
  }
`;

export const AccordionTable = styled(AccordionBody)`
  background-color: #fff;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0.5rem;
  }
`;

export const LoadingAccordions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const AccordionNameRow = styled.button`
  color: #495057;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
`;
