import styled from 'styled-components';

export const FilterKeywordMonitoringStyled = styled.div`
  padding: 1.5rem;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0 0 0.5rem 0.5rem;
  background: #f6f6f6;
  display: flex;
  width: 100%;
  flex-direction: row;

  form {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
  }

  #input-date {
    height: 38px;
  }

  .basic-multi-select {
    width: 100% !important;
    min-width: 100% !important;
  }
`;

export const ContainerButtonsStyled = styled.div`
  background: #f6f6f6;
  border-radius: 0 0 0.5rem 0.5rem;

  .btn-filter {
    width: 4.5rem;
  }

  .btn-filter-clear {
    width: 7.5;
    background: transparent;
    border: none;
    color: #556ee6 !important;
  }
  .btn-filter-clear:hover {
    background: #ffffff !important;
    color: #556ee6 !important;
  }
`;
