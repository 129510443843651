import styled from 'styled-components';

export const PerformanceTableStyles = styled.div`
  position: relative;

  ::after,
  ::before {
    content: '';
    position: absolute;
    top: 4.5rem;
    bottom: 3.9rem;
    background-color: #e2e8f0;
  }
  ::after {
    right: 0;
    width: 1px;
  }
  ::before {
    left: 0;
    width: 1px;
  }

  .table-header {
    color: #2d3748;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    padding: 1.5rem;
    background-color: #f7fafc;
    border: 1px solid #e2e8f0;
    border-radius: 0.8rem 0.8rem 0 0;
  }

  .performance-table {
    overflow-x: scroll;
    width: 100%;
  }

  table {
    thead {
      height: 2.75rem;
    }

    thead th {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.125rem;
      color: #475467;
      white-space: nowrap;
    }

    thead th:first-child {
      width: 16.875rem !important;
      min-width: 16.875rem !important;
    }

    tbody td {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: #1a202c;
      padding: 0.75rem 1rem;
      border-width: 0 1px 1px 1px;
      border-color: #e2e8f0;
      border-style: solid;
      vertical-align: middle;
      background-color: #f9f9f9;
    }

    thead th:last-child,
    tbody td:last-child {
      border-width: 0 1px 1px 0;
      border-color: #e2e8f0;
      border-style: solid;
    }

    th {
      background-color: #ffffff;
      border-width: 0 0 1px 0;
      border-color: #e2e8f0;
      border-style: solid;
      vertical-align: middle;
    }

    .header-keyword {
      background-color: #f2f2f5;
      border-width: 0 1px 1px 1px;
      border-color: #e2e8f0;
      border-style: solid;
    }

    .header-label {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.125rem;
      color: #475467;
    }

    .header-value {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      color: #718096;
    }

    .body-search-tag {
      width: max-content;
      height: 1.25rem;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;
      color: #718096;
      background-color: #f7fafc;
      padding: 0 0.5rem;
      border: 1px solid #edf2f7;
      border-radius: 0.25rem;
      margin-bottom: 0.25rem;
    }

    .body-search-value {
      min-width: 10rem;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      color: #2d3748;
      padding: 0.75rem 1rem;
      border-width: 0 0 1px 0;
      border-color: #e2e8f0;
      border-style: solid;
      vertical-align: middle;
      background-color: #ffffff;
    }

    .total-growth {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;
      gap: 0.25rem;
    }

    .total-growth-positive {
      color: #e53e3e;
    }

    .total-growth-negative {
      color: #38a169;
    }
  }

  .table-footer {
    padding: 0.75rem;
    background-color: #ffffff;
    border-width: 0 1px 1px 1px;
    border-color: #e2e8f0;
    border-style: solid;
    border-radius: 0 0 0.8rem 0.8rem;

    span {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: #718096;
    }
  }

  .btn-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    min-width: 9.375rem;
    padding: 0.625rem 0.75rem;
    border-radius: 0.25rem;
    border: none;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: #fff;
    align-self: flex-end;
    gap: 0.5rem;
  }

  .next {
    background-color: #556ee6;
    color: #fff !important;
  }
  .next:hover {
    background-color: #3b5bfc;
  }
  .next:disabled {
    background-color: #a0aec0;
  }
  .previous {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    color: #2d3748;
  }
  .previous:hover {
    background-color: #edf2f7;
    border: 1px solid #e2e8f0;
    color: #2d3748;
  }
  .previous:disabled {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    color: #a0aec0;
  }

  .copy {
    min-width: min-content !important;
    color: #2d3748;
    background-color: transparent;
    border: 1px solid #e2e8f0;
  }
  .copy:hover {
    background-color: #e2e8f0;
  }
  .copy:disabled {
    background-color: #e2e8f0;
    color: #a0aec0;
  }

  .keywords {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: #74788d;
  }

  .groups {
    min-width: min-content !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #74788d;
  }

  .config {
    width: 2rem !important;
    min-width: 2rem !important;
  }

  .dropdown-config {
    .dropdown-menu {
      min-width: 12.5rem;
      border: 1px solid #e2e8f0;
      border-radius: 0.5rem;
    }
  }

  .hover-gray:hover {
    background-color: #f7fafc;
  }

  .lines-by-page {
    width: 14rem;
    display: flex;
    align-items: center;

    label {
      width: 21rem !important;
      color: #1a202c;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }

    select {
      width: 5rem !important;
    }
  }

  .container-not-collected {
    color: #bdbdbd;
    font-size: 0.8rem;
    font-weight: 500;

    i {
      font-size: 1.25rem;
    }
  }
`;
