import React, { useEffect, useRef, useState } from 'react';
import {
  guidelineTypeOptions,
  initialGuidelineKanbanState,
  initialGuidelineClientState,
  initialGuidelineReviewerState,
  kanbanGuidelineStepNumber,
} from 'constants/index';
import { getDatabase, ref, onValue, off, update } from 'firebase/database';
import { useAuth } from 'hooks/useAuth';
import { MetaTags } from 'react-meta-tags';
import { Container, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Board, { moveCard } from '@asseinfo/react-kanban';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import KanbanCardGuideline from './components/Kanban-Card-Guideline';
import ChangelogModal from '../../components/Changelog-Modal';
import KanbanHeader from '../../components/Kanban-Header';
import HeaderContext from '../../components/Tasks-Filter/TasksFilter.Context';
import Breadcrumbs from '../../components/Breadcrumb';
import { KanbanGuidelineLayout } from './styles';
import ModalArchive from './components/Modal-Archive';

const KanbanGuideline = () => {
  const firebaseHelper = getFirebaseBackend();

  const { t: translate } = useTranslation();
  const { user } = useAuth();

  const accountId = user?.account;
  const userStatus = user?.adminStatus;
  const initialBoard =
    userStatus === 'Client' || userStatus === 'Client Admin'
      ? initialGuidelineClientState
      : userStatus === 'Reviewer'
        ? initialGuidelineReviewerState
        : initialGuidelineKanbanState;
  const containerRef = useRef();

  const [board, setBoard] = useState({ columns: initialBoard });
  const [filter, setFilter] = useState(null);
  const [filterTotal, setFilterTotal] = useState(0);
  const [draggingFromColumn, setDraggingFromColumn] = useState('');
  const [modalArchive, setModalArchive] = useState(false);
  const [guidelineDataClicked, setGuidelineDataClicked] = useState([]);

  const getGuidelineType = (type) => {
    for (const option of guidelineTypeOptions) {
      if (option.value === type) {
        return option.label;
      }
    }
  };

  const getBoardIndex = (index) => {
    return board.columns.findIndex(
      (column) => column.title === initialGuidelineKanbanState[index].title,
    );
  };

  const processGuidelines = async (receivedGuidelines) => {
    if (!receivedGuidelines) return initialBoard;

    const guidelineState = _.cloneDeep(initialBoard);

    const sortedGuideline = Object.entries(receivedGuidelines)
      .sort((a, b) => a[1]['position'] - b[1]['position'])
      .filter(([, guidelineValue]) => {
        if (!filter) return true;
        let totalFilters = 0;
        let assignedBool = 1;
        let createDateBool = 1;
        let productionYearBool = 1;
        let productionMonthBool = 1;
        let typeBool = 1;
        let searchBool = 1;

        if (!_.isEmpty(filter.assignedTo)) {
          assignedBool = 0;
          filter.assignedTo.forEach(({ value }) => {
            totalFilters++;
            if (guidelineValue.assignedTo?.includes(value)) assignedBool++;
          });
        }

        if (!_.isEmpty(filter.type)) {
          typeBool = 0;
          filter.type.forEach(({ value }) => {
            totalFilters++;
            if (getGuidelineType(guidelineValue.type) === getGuidelineType(value)) typeBool++;
          });
        }

        if (filter.endDate) {
          totalFilters++;
          createDateBool = 0;
          const cardsCreatedAt = new Date(guidelineValue.createdAt);
          const filterStartDate = new Date(filter.startDate.getTime());
          const filterEndDate = new Date(filter.endDate.getTime());

          cardsCreatedAt.setHours(0, 0, 0, 0);
          filterStartDate.setHours(0, 0, 0, 0);
          filterEndDate.setHours(0, 0, 0, 0);

          if (cardsCreatedAt >= filterStartDate && cardsCreatedAt <= filterEndDate) {
            createDateBool++;
          }
        }

        if (!_.isEmpty(filter.productionYear)) {
          productionYearBool = 0;

          if (guidelineValue.productionDate) {
            const productionYear = guidelineValue.productionDate.year;

            const filterProductionYear = filter.productionYear.filter((year) => {
              totalFilters++;
              return year.value === productionYear;
            });

            if (filterProductionYear.length > 0) productionYearBool++;
          }
        }

        if (!_.isEmpty(filter.productionMonth)) {
          productionMonthBool = 0;

          if (guidelineValue.productionDate) {
            const productionMonth = guidelineValue.productionDate.month;

            const filterProductionMonth = filter.productionMonth.filter((month) => {
              totalFilters++;
              return month.value === productionMonth;
            });

            if (filterProductionMonth.length > 0) productionMonthBool++;
          }
        }

        if (filter.search) {
          searchBool = 0;
          totalFilters++;

          const normalizedSearch = filter.search
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

          const normalizedPrimaryKeyword = guidelineValue?.primaryKeyword
            ? guidelineValue.primaryKeyword
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            : '';

          if (normalizedPrimaryKeyword.includes(normalizedSearch)) searchBool++;
        }
        setFilterTotal(totalFilters);

        return (
          assignedBool &&
          createDateBool &&
          productionYearBool &&
          productionMonthBool &&
          typeBool &&
          searchBool
        );
      })
      .reduce(
        (sortedObj, [key, value]) => ({
          ...sortedObj,
          [key]: value,
        }),
        {},
      );

    // Populate the kanban board object with its contents from the realtime database
    for (const [key, value] of Object.entries(sortedGuideline)) {
      // Places the tasks on its proper columns of the Kanban Board Object

      switch (value.column) {
        case 'BACKLOG':
          guidelineState[getBoardIndex(0)].cards.push(value);
          break;
        case 'GUIDELINE_APPROVAL':
          guidelineState[getBoardIndex(1)].cards.push(value);
          break;
        case 'CONTENT_STRUCTURE':
          guidelineState[getBoardIndex(2)].cards.push(value);
          break;
        case 'PRE_REVIEW':
          guidelineState[getBoardIndex(3)].cards.push(value);
          break;
        case 'CONTENT_REVIEW':
          guidelineState[getBoardIndex(4)].cards.push(value);
          break;
        case 'INTERNAL_APPROVAL':
          guidelineState[getBoardIndex(5)].cards.push(value);
          break;
        case 'CONTENT_APPROVAL':
          guidelineState[getBoardIndex(6)].cards.push(value);
          break;
        case 'APPROVED':
          guidelineState[getBoardIndex(7)].cards.push(value);
          break;
        case 'PUBLISHED':
          guidelineState[getBoardIndex(8)].cards.push(value);
          break;
      }
    }

    //order cards in coluns by position
    guidelineState.forEach((column) => {
      column.cards.sort((a, b) => {
        return a.position - b.position;
      });
    });

    // return the state, processed or default
    return guidelineState;
  };

  const stringPerColumn = {
    Backlog: () => {
      return `(${board.columns[getBoardIndex(0)].cards.length})`;
    },
    'Guideline approval': () => {
      return `(${board.columns[getBoardIndex(1)].cards.length})`;
    },
    'Content Structure': () => {
      return `(${board.columns[getBoardIndex(2)].cards.length})`;
    },
    'Pre review': () => {
      return `(${board.columns[getBoardIndex(3)].cards.length})`;
    },
    'Content review': () => {
      return `(${board.columns[getBoardIndex(4)].cards.length})`;
    },
    'Internal approval': () => {
      return `(${board.columns[getBoardIndex(5)].cards.length})`;
    },
    'Content approval': () => {
      return `(${board.columns[getBoardIndex(6)].cards.length})`;
    },
    Approved: () => {
      return `(${board.columns[getBoardIndex(7)].cards.length})`;
    },
    Published: () => {
      return `(${board.columns[getBoardIndex(8)].cards.length})`;
    },
  };

  const onCardDragEnd = (card, source, destination) => {
    setDraggingFromColumn('');

    if (source.fromColumnId === destination.toColumnId) {
      const updatedBoard = moveCard(board, source, destination);
      const updates = {};

      // Updates the position field on each card
      updatedBoard.columns.forEach((column) => {
        column.cards.forEach((card, indexCard) => {
          card.position = indexCard;
        });
      });
      // If the column was changed updates the column on the moved card
      if (card.column !== kanbanGuidelineStepNumber[destination.toColumnId]) {
        card.column = kanbanGuidelineStepNumber[destination.toColumnId];
      }
      // Sends an update request to change the position for every card,
      // firebase only updates the needed ones
      updatedBoard.columns.forEach((column) => {
        column.cards.forEach((card) => {
          updates[`guidelines/${accountId}/${card.id}`] = { ...card };
        });
      });

      const db = getDatabase();
      update(ref(db), updates).catch((error) => {
        console.error('Erro ao atualizar os cartões:', error);
      });

      setBoard(updatedBoard);
    }
  };

  const toggleModalArchive = () => setModalArchive(!modalArchive);

  useEffect(() => {
    const columns = document.querySelectorAll(
      `.react-kanban-column:not([data-rbd-draggable-id="column-draggable-${draggingFromColumn}"])`,
    );

    if (draggingFromColumn !== '') {
      columns.forEach((column) => {
        column.classList.add('no-drag');
      });
    } else {
      columns.forEach((column) => {
        column.classList.remove('no-drag');
      });
    }
  }, [draggingFromColumn]);

  useEffect(() => {
    const initialize = async () => {
      if (!accountId) return;

      const db = getDatabase();
      const guidelinesRef = ref(db, `guidelines/${accountId}`);

      const handleValueChange = async (snapshot) => {
        const data = snapshot.val();

        const guidelinesWithIds = Object.entries(data ?? {}).map(([key, value]) => ({
          ...value,
          id: key,
        }));

        const filteredGuidelines = guidelinesWithIds.filter((guideline) => {
          const isClientAccessible =
            guideline.column === 'GUIDELINE_APPROVAL' ||
            guideline.column === 'CONTENT_APPROVAL' ||
            guideline.column === 'APPROVED' ||
            guideline.column === 'PUBLISHED';

          if (userStatus === 'Client' || userStatus === 'Client Admin') return isClientAccessible;
          return true;
        });

        const nonArchivedGuidelines = Object.values(filteredGuidelines).filter(
          (guideline) => !guideline.archived,
        );

        const processedGuidelines = await processGuidelines(nonArchivedGuidelines);

        const highestPositionCardsByColumn = processedGuidelines.map((column) => {
          const columnLength = column.cards.length + 1;

          return {
            columnName: column.title
              .toUpperCase()
              .replace(/[^A-Z0-9 ]/g, '')
              .replace(/\s+/g, '_'),
            lastPosition: columnLength > 0 ? columnLength - 1 : 0,
          };
        });

        if (processGuidelines.length > 0) {
          highestPositionCardsByColumn.forEach((column) => {
            firebaseHelper.saveGuidelineMetadataLastPosition(
              accountId,
              column.columnName,
              column.lastPosition,
            );
          });
        }

        setBoard({ columns: processedGuidelines });
      };

      onValue(guidelinesRef, handleValueChange);

      return () => {
        off(guidelinesRef, 'value', handleValueChange);
      };
    };
    initialize();
  }, [accountId, filter]);

  return (
    <React.Fragment>
      <KanbanGuidelineLayout>
        <div className="page-content">
          <MetaTags>
            <title>{`${translate('Guideline')} | Ectools`}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title={translate('Guideline')}
              breadcrumbItem={translate('Kanban Board')}
            />

            <ChangelogModal />

            <HeaderContext.Provider value={{ setFilter }}>
              <KanbanHeader
                board={board}
                accountId={accountId}
                filterTotal={filterTotal}
                userStatus={userStatus}
                isGuideline
              />
            </HeaderContext.Provider>

            <div ref={containerRef} style={{ height: '64.9vh' }}>
              {userStatus !== 'Reviewer' && (
                <ModalArchive
                  modalFunction={toggleModalArchive}
                  modalState={modalArchive}
                  dataIndividual={guidelineDataClicked}
                />
              )}

              <Board
                disableColumnDrag
                renderColumnHeader={({ title }) => (
                  <CardTitle>{translate(title) + stringPerColumn[title]()}</CardTitle>
                )}
                renderCard={(data, { dragging }) => (
                  <KanbanCardGuideline
                    dragging={dragging}
                    data={data}
                    setDraggingFromColumn={setDraggingFromColumn}
                    draggingFromColumn={draggingFromColumn}
                    key={data.id}
                    setGuidelineDataClicked={setGuidelineDataClicked}
                    toggleModalArchive={toggleModalArchive}
                  >
                    {data}
                  </KanbanCardGuideline>
                )}
                onCardDragEnd={onCardDragEnd}
              >
                {board}
              </Board>
            </div>
          </Container>
        </div>
      </KanbanGuidelineLayout>
    </React.Fragment>
  );
};

export default KanbanGuideline;
