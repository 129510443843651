import styled from 'styled-components';

export const TableGroupsStyled = styled.div`
  border: 1px solid #eff2f7;
  border-radius: 0.5rem;
  height: fit-content;
  width: 100%;
  overflow: hidden;

  table {
    margin: 0;

    th {
      background-color: #f8f9fa;
      height: 2.5rem;
      padding: 0.5rem 1.5rem;
      vertical-align: middle;
      &:not(:first-child) {
        text-align: center;
      }
    }

    td {
      background-color: #ffffff;
      height: 4.5rem;
      overflow: visible;
      padding: 0.5rem 1.5rem;
      vertical-align: middle;
      &:first-child {
        i {
          margin-left: 1rem;
          font-size: 1.25rem;
          color: #7367f0;
          vertical-align: middle;
        }
      }
      &:nth-last-child(2),
      &:last-child {
        text-align: center;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    .text-no-groups {
      color: #74788d;
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    .btn-manage {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      background-color: transparent;
      border: none;
      color: #2d3748 !important;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      &:hover {
        background-color: #f8f8fb;
        border: none;
      }

      i {
        color: #a0aec0;
      }
    }
  }
`;
