import React, { useState, useEffect, useContext } from 'react';
import { Button, ButtonGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { useForm, Controller } from 'react-hook-form';
import 'boxicons';

import { loadTeam } from '../../../../utils';
import HeaderContext from '../../../../components/Tasks-Filter/TasksFilter.Context';
import { guidelineTypeOptions, monthNumberLabel } from '../../../../constants';
import ContainerLabelDate from '../Container-Label-Date';
import { GuidelinesFilterLayout } from './styles';

const currentYear = new Date().getFullYear();
const maxYear = currentYear + 1;

const yearOptions = [];
for (let year = 2024; year <= maxYear; year++) {
  yearOptions.push({ label: year.toString(), value: year });
}

const GuidelinesFilter = ({ toggleFilter, accountId, show }) => {
  const formConfig = {
    defaultValues: {
      assignedTo: [],
      startCreationDate: new Date(),
      endCreationDate: null,
      type: [],
      productionMonth: [],
      productionYear: [],
      search: '',
    },
  };
  const { t: translate } = useTranslation();
  const { control, reset, getValues, setValue, watch } = useForm(formConfig);

  const { setFilter } = useContext(HeaderContext);
  const [assignedKey, setAssignedKey] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [creationDate, setCreationDate] = useState('');

  const handleToggle = () => toggleFilter((prevState) => !prevState);

  const cleanFilters = () => {
    reset();
    setCreationDate('');
    setSelectedRadio(null);
    setFilter({
      assignedTo: [],
      startCreationDate: new Date(),
      endCreationDate: null,
      type: [],
      productionMonth: [],
      productionYear: [],
      search: '',
    });
  };

  const applyFilters = () => {
    const dataObj = getValues();
    setFilter(dataObj);
  };

  useEffect(() => {
    setAssignedKey((prevState) => prevState + 1);
  }, [accountId]);

  return (
    <div className="header-wrapper" style={{ display: show ? 'flex' : 'none' }}>
      <GuidelinesFilterLayout>
        <div className="filter-menu m-0">
          <form className="filter-menu m-0">
            <div className="menu-inline">
              <div className="menu-column">
                <Label className="filter-label" for="assignedTo">
                  {translate('Assigned To')}
                </Label>
                <Controller
                  control={control}
                  name="assignedTo"
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      value={field.value}
                      key={assignedKey}
                      placeholder={translate('Select')}
                      className="basic-multi-select filter-select"
                      cacheOptions
                      defaultOptions
                      loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
                      onKeyDown={(e) => e.key === 'Enter' && applyFilters()}
                      isMulti
                    />
                  )}
                />
              </div>
              <div className="menu-column">
                <Label className="filter-label" for="input-date">
                  {translate('Creation date')}
                </Label>
                <Input
                  value={creationDate || ''}
                  placeholder={creationDate ? creationDate : translate('Select')}
                  className="basic-multi-select filter-select"
                  id="input-date"
                  style={{ minWidth: '200px' }}
                  onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                  readOnly
                />
              </div>

              {isDatePickerOpen && (
                <ContainerLabelDate
                  date={creationDate}
                  setDate={setCreationDate}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  setIsDatePickerOpen={setIsDatePickerOpen}
                  onApplyFilter={applyFilters}
                />
              )}

              <div className="menu-column">
                <Label className="filter-label" for="productionYear">
                  {translate('Production year')}
                </Label>
                <Controller
                  control={control}
                  name="productionYear"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      value={field.value}
                      placeholder={translate('Select')}
                      className="basic-multi-select filter-select"
                      style={{ minWidth: '150px' }}
                      options={yearOptions}
                      onKeyDown={(e) => e.key === 'Enter' && applyFilters()}
                      isMulti
                    />
                  )}
                />
              </div>

              <div className="menu-column">
                <Label className="filter-label" for="productionMonth">
                  {translate('Production month')}
                </Label>
                <Controller
                  control={control}
                  name="productionMonth"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      value={field.value}
                      placeholder={translate('Select')}
                      className="basic-multi-select filter-select"
                      style={{ minWidth: '200px' }}
                      options={monthNumberLabel}
                      onKeyDown={(e) => e.key === 'Enter' && applyFilters()}
                      isMulti
                    />
                  )}
                />
              </div>

              <div className="menu-column">
                <Label className="filter-label" for="type">
                  {translate('Types of content')}
                </Label>
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      value={field.value}
                      placeholder={translate('Select')}
                      className="basic-multi-select filter-select"
                      style={{ minWidth: '200px' }}
                      onKeyDown={(e) => e.key === 'Enter' && applyFilters()}
                      options={guidelineTypeOptions}
                      isMulti
                    />
                  )}
                />
              </div>
            </div>

            <Controller
              control={control}
              name="search"
              render={({ field }) => (
                <div className="search">
                  <Input
                    {...field}
                    value={field.value}
                    placeholder={translate('Search')}
                    onKeyDown={(e) => e.key === 'Enter' && applyFilters()}
                    required
                  />
                </div>
              )}
            />
          </form>
        </div>

        <div className="filter-btn">
          <Button className="filter-btn-apply" onClick={applyFilters}>
            <box-icon name="check" color="#FFF" />
          </Button>
          <Button className="filter-btn-clear" onClick={cleanFilters}>
            <box-icon name="eraser" color="#FFF" />
          </Button>
          <Button className="filter-btn-back" onClick={handleToggle}>
            {translate('Go back')}
          </Button>
        </div>
      </GuidelinesFilterLayout>
    </div>
  );
};

GuidelinesFilter.propTypes = {
  toggleFilter: PropTypes.func,
  accountId: PropTypes.string,
  show: PropTypes.bool,
};

export default GuidelinesFilter;
