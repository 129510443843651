import Homepage from 'pages/homepage';
import KanbanBoard from '../pages/tasks/kanbanBoard';
import TasksList from '../pages/tasks/tasksList';
import TasksCreate from '../pages/tasks/createTask';
import EditTask from '../pages/tasks/editTask';
import EditProfile from '../pages/user/editProfile';
import Meetings from '../pages/meetings/listMeetings';
import Meeting from '../pages/meetings/meeting';
import createMeeting from '../pages/meetings/createMeeting';
import editMeeting from '../pages/meetings/editMeeting';
import Changelog from '../pages/support/changelog';
import CreateGuideline from 'pages/guideline/createGuideline';
import KanbanGuideline from 'pages/guideline/kanbanGuideline';
import ReviewGuideline from 'pages/guideline/reviewGuideline';
import GuidelineApproval from 'pages/guideline/guidelineApproval';
import manageGuideline from 'pages/guideline/manageGuideline';
import GuidelineMisc from 'pages/guideline/guidelineMisc';
import CreateContentStructure from 'pages/guideline/createContentStructure';
import PreReviewContent from 'pages/guideline/preReviewContent';
import ReviewContent from 'pages/guideline/reviewContent';
import InternalApproval from 'pages/guideline/internalApproval';
import PublishContent from 'pages/guideline/publishContent';
import ContentApproval from 'pages/guideline/contentApproval';
import ViewPublishedContent from 'pages/guideline/viewPublishedContent';
import KeywordMonitoring from 'pages/keywords/keywordMonitoring';
import KeywordGroups from 'pages/keywords/keywordGroups';
import KeywordGroup from 'pages/keywords/keywordGroup';

const authProtectedRoutes = [
  // Homepage
  { path: '/', component: Homepage },
  // Tasks
  { path: '/tasks', component: KanbanBoard },
  { path: '/tasks-list', component: TasksList },
  { path: '/tasks-create', component: TasksCreate },
  { path: '/edit-task', component: EditTask },
  { path: '/edit-profile', component: EditProfile },
  // Meetings
  { path: '/meetings', component: Meetings },
  { path: '/meeting', component: Meeting },
  // Meeting Management
  { path: '/create-meeting', component: createMeeting },
  { path: '/edit-meeting', component: editMeeting },
  // Changelog
  { path: '/changelog', component: Changelog },
  // Guideline
  {
    path: '/guidelines',
    component: KanbanGuideline,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/guideline/:id',
    component: GuidelineMisc,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/create-guideline',
    component: CreateGuideline,
    requiredModule: 'content',
    requiredRole: 'Super Admin',
  },
  {
    path: '/guideline/manage',
    component: manageGuideline,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/guideline/:id/review',
    component: ReviewGuideline,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/guideline/:id/guideline-approval',
    component: GuidelineApproval,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/content/:id/structure',
    component: CreateContentStructure,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/content/:id/pre-review',
    component: PreReviewContent,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/content/:id/review',
    component: ReviewContent,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/content/:id/internal-approval',
    component: InternalApproval,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/content/:id/content-approval',
    component: ContentApproval,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/content/:id/publish',
    component: PublishContent,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  {
    path: '/content/:id/published-content',
    component: ViewPublishedContent,
    requiredModule: 'content',
    requiredRole: ['Super Admin', 'Admin'],
  },
  // Reports
  { path: '/reports/keywords', component: KeywordMonitoring },
  { path: '/reports/keywords/groups', component: KeywordGroups },
  { path: '/reports/keywords/groups/:id', component: KeywordGroup },
];

export { authProtectedRoutes };
