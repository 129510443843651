import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Chart } from 'chart.js';
import { verticalLinePlugin } from 'plugins/chartjs/verticalLine';

import { initFirebaseBackend } from './helpers/firebaseHelper';
import './assets/scss/theme.scss';

import { authProtectedRoutes, publicRoutes, adminRoutes } from './routes';
import { AuthRoute } from './routes/AuthRoute';
import { AdminRoute } from './routes/AdminRoute';
import { ModuleRoute } from './routes/ModuleRoute';

Chart.plugins.register(verticalLinePlugin);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL:
    process.env.REACT_APP_BUILD_TYPE === 'production'
      ? process.env.REACT_APP_DATABASEURL
      : process.env.REACT_APP_DEV_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket:
    process.env.REACT_APP_BUILD_TYPE === 'production'
      ? process.env.REACT_APP_STORAGEBUCKET
      : process.env.REACT_APP_DEV_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

initFirebaseBackend(firebaseConfig);

const App = () => {
  return (
    <Routes>
      {React.Children.toArray(
        authProtectedRoutes.map((route) => (
          <>
            <Route
              path={route.path}
              element={
                <AuthRoute>
                  <ModuleRoute
                    requiredModule={route.requiredModule}
                    requiredRole={route.requiredRole}
                  >
                    <route.component />
                  </ModuleRoute>
                </AuthRoute>
              }
              key={route.path}
              exact
            />
          </>
        )),
      )}

      {React.Children.toArray(
        adminRoutes.map((route) => (
          <>
            <Route
              path={route.path}
              element={
                <AdminRoute key={`${route.path}-admin`} role="Super Admin">
                  <route.component key={`${route.path}-comp`} />
                </AdminRoute>
              }
              key={route.path}
              exact
            />
          </>
        )),
      )}

      {React.Children.toArray(
        publicRoutes.map((route) => (
          <>
            <Route path={route.path} element={<route.component />} key={route.path} exact />
          </>
        )),
      )}
    </Routes>
  );
};

export default App;
