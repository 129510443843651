import React, { useEffect } from 'react';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useAuth } from 'hooks/useAuth';

const AccountLogo = () => {
  const { user } = useAuth();
  const accountId = user.account;
  const firebaseHelper = getFirebaseBackend();

  const getLogo = async () => {
    const logoElem = document.getElementById('account-photo');
    const loadingElem = document.getElementById('loading-icon');
    if (logoElem && loadingElem) {
      logoElem.style.display = 'none';
      loadingElem.style.display = 'block';
    }

    const path = `${accountId}/logo`;
    const logoMeta = await firebaseHelper.listAllFiles(path);
    if (logoMeta.length !== 0) {
      const logoFile = await firebaseHelper.getFileObj(`${path}/${logoMeta[0].name}`);
      const logoUrl = URL.createObjectURL(logoFile);
      logoElem.style.display = 'block';
      loadingElem.style.display = 'none';
      logoElem.style.backgroundImage = `url('${logoUrl}')`;
    } else {
      if (logoElem && loadingElem) {
        logoElem.style.display = 'none';
        loadingElem.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    getLogo();
  }, [accountId]);

  return (
    <div
      className="task-summary-photo"
      style={{ height: '60px', width: '60px', position: 'relative' }}
    >
      <div id="account-photo" className="task-summary-logo" />
      <i
        id="loading-icon"
        className="bx bx-loader-alt task-summary-loading-icon mt-3"
        style={{
          position: 'absolute',
          top: '0',
          left: '20%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </div>
  );
};

export default AccountLogo;
