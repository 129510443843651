import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import 'boxicons';

import ContainerLabelDate from 'pages/guideline/components/Container-Label-Date';
import { TasksFilterLayout } from './styles';
import HeaderContext from './TasksFilter.Context';
import { formatDateInput, loadTeam } from '../../utils';

const IMPACT_LABEL = {
  L: 'Low',
  M: 'Medium',
  H: 'High',
};

const STATUS_LABEL = {
  0: 'Waiting',
  1: 'Ready to Implement',
  2: 'Technical Impediment',
  3: 'Under Development',
  4: 'Implemented',
};

const END_DATE_PROPERTY = {
  startCreationDate: 'endCreationDate',
  startImplementationDate: 'endImplementationDate',
  startDeliveryDate: 'endDeliveryDate',
};

const TasksFilter = ({ toggleFilter, accountId, isContent, show }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [team, setTeam] = useState([]);

  const formConfig = {
    defaultValues: {
      assignedTo: [],
      status: [],
      impact: [],
      complexity: [],
      foundations: [],
      search: '',
      startCreationDate: new Date(),
      endCreationDate: null,
      startImplementationDate: new Date(),
      endImplementationDate: null,
      startDeliveryDate: new Date(),
      endDeliveryDate: null,
    },
  };
  const { t: translate } = useTranslation();
  const { control, reset, getValues, setValue, watch } = useForm(formConfig);

  const { setFilter } = useContext(HeaderContext);
  const [assignedKey, setAssignedKey] = useState(0);
  const [isCreateDatePickerOpen, setIsCreateDatePickerOpen] = useState(false);
  const [creationDate, setCreationDate] = useState('');
  const [isImplementationDatePickerOpen, setIsImplementationDatePickerOpen] = useState(false);
  const [inplementationDate, setImplementationDate] = useState('');
  const [isDeliveryDatePickerOpen, setIsDeliveryDatePickerOpen] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState('');

  const inputRef = useRef(null);

  const handleToggle = () => toggleFilter((prevState) => !prevState);

  const cleanFilters = () => {
    setCreationDate('');
    setImplementationDate('');
    setDeliveryDate('');
    reset();
    applyFilters();
  };

  const applyFilters = () => {
    const dataObj = getValues();
    const filterProperties = Object.keys(dataObj);
    for (const property of filterProperties) {
      const value = dataObj[property];
      if (Array.isArray(value) && value.length) {
        searchParams.set(property, value.map((item) => item.value).join(','));
      }
      if (typeof value === 'string' && value) {
        searchParams.set(property, dataObj[property]);
      }
      if (value instanceof Date) {
        const isStartDate = property.includes('start');
        const endDateProperty = dataObj[END_DATE_PROPERTY[property]];
        if (isStartDate && endDateProperty) searchParams.set(property, String(value.valueOf()));
        if (isStartDate && !endDateProperty) searchParams.delete(property);
        if (!isStartDate) searchParams.set(property, String(value.valueOf()));
      }

      if (_.isEmpty(value) && !(value instanceof Date)) {
        searchParams.delete(property);
      }
    }
    // Delete task modal params to avoid previous tasks from being opened
    searchParams.delete('id');
    searchParams.delete('acc');
    setSearchParams(searchParams);
    setFilter(dataObj);
  };

  useEffect(() => {
    const assignedTo = searchParams.get('assignedTo');
    const status = searchParams.get('status');
    const impact = searchParams.get('impact');
    const complexity = searchParams.get('complexity');
    const foundations = searchParams.get('foundations');
    const search = searchParams.get('search');
    const startCreationDate = searchParams.get('startCreationDate');
    const endCreationDate = searchParams.get('endCreationDate');
    const startImplementationDate = searchParams.get('startImplementationDate');
    const endImplementationDate = searchParams.get('endImplementationDate');
    const startDeliveryDate = searchParams.get('startDeliveryDate');
    const endDeliveryDate = searchParams.get('endDeliveryDate');

    const newAssignedTo =
      assignedTo
        ?.split(',')
        .map((value) => ({ value, label: team.find((item) => item.value === value)?.label })) || [];
    const newStatus =
      status?.split(',').map((value) => ({ value, label: STATUS_LABEL[value] })) || [];
    const newImpact =
      impact?.split(',').map((value) => ({ value, label: IMPACT_LABEL[value] })) || [];
    const newComplexity =
      complexity?.split(',').map((value) => ({ value, label: IMPACT_LABEL[value] })) || [];
    const newFoundation =
      foundations?.split(',').map((value) => ({ value, label: translate(value) })) || [];
    const newStartCreationDate = startCreationDate
      ? new Date(Number(startCreationDate))
      : new Date();
    const newEndCreationDate = endCreationDate ? new Date(Number(endCreationDate)) : null;
    const newStartImplementationDate = startImplementationDate
      ? new Date(Number(startImplementationDate))
      : new Date();
    const newEndImplementationDate = endImplementationDate
      ? new Date(Number(endImplementationDate))
      : null;
    const newStartDeliveryDate = startDeliveryDate
      ? new Date(Number(startDeliveryDate))
      : new Date();
    const newEndDeliveryDate = endDeliveryDate ? new Date(Number(endDeliveryDate)) : null;

    setValue('search', search || '');
    setValue('assignedTo', newAssignedTo);
    setValue('status', newStatus);
    setValue('impact', newImpact);
    setValue('complexity', newComplexity);
    setValue('foundations', newFoundation);
    setValue('startCreationDate', newStartCreationDate);
    setValue('endCreationDate', newEndCreationDate);
    setValue('startImplementationDate', newStartImplementationDate);
    setValue('endImplementationDate', newEndImplementationDate);
    setValue('startDeliveryDate', newStartDeliveryDate);
    setValue('endDeliveryDate', newEndDeliveryDate);

    const formattedCreationDate = formatDateInput(
      newStartImplementationDate,
      newEndCreationDate,
      '',
    );
    const formattedImplementationDate = formatDateInput(
      newStartImplementationDate,
      newEndImplementationDate,
      '',
    );
    const formattedDeliveryDate = formatDateInput(newStartDeliveryDate, newEndDeliveryDate, '');
    setCreationDate(formattedCreationDate);
    setImplementationDate(formattedImplementationDate);
    setDeliveryDate(formattedDeliveryDate);

    applyFilters();
  }, [searchParams, accountId, team]);

  useEffect(() => {
    if (!isCreateDatePickerOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 10);
    }
  }, [isCreateDatePickerOpen]);

  useEffect(() => {
    loadTeam(accountId).then((team) => {
      setTeam(team);
    });
    setAssignedKey((prevState) => prevState + 1);
  }, [accountId]);

  return (
    <div className="header-wrapper" style={{ display: show ? 'flex' : 'none' }}>
      <TasksFilterLayout>
        <div className="filter-menu m-0">
          <form className="filter-menu">
            <div>
              <div
                className="position-relative"
                onMouseLeave={() => setIsCreateDatePickerOpen(false)}
              >
                <Controller
                  control={control}
                  name="createdAt"
                  render={({ field }) => (
                    <Input
                      {...field}
                      innerRef={(e) => {
                        field.ref(e);
                        inputRef.current = e;
                      }}
                      value={creationDate || ''}
                      placeholder={creationDate ? creationDate : translate('Creation date')}
                      id="input-date"
                      style={{ minWidth: '190px' }}
                      onClick={() => setIsCreateDatePickerOpen(!isCreateDatePickerOpen)}
                      readOnly
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          applyFilters();
                        }
                      }}
                    />
                  )}
                />
                {isCreateDatePickerOpen && (
                  <ContainerLabelDate
                    date={creationDate}
                    setDate={setCreationDate}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                    setIsDatePickerOpen={setIsCreateDatePickerOpen}
                    inputRef={inputRef}
                    left
                    top="36px"
                    startDateName="startCreationDate"
                    endDateName="endCreationDate"
                    onApplyFilter={applyFilters}
                  />
                )}
              </div>

              <div
                className="position-relative"
                onMouseLeave={() => setIsImplementationDatePickerOpen(false)}
              >
                <Controller
                  control={control}
                  name="implementationDate"
                  render={({ field }) => (
                    <Input
                      {...field}
                      innerRef={(e) => {
                        field.ref(e);
                        inputRef.current = e;
                      }}
                      value={inplementationDate || ''}
                      placeholder={
                        inplementationDate ? inplementationDate : translate('Implementation date')
                      }
                      id="input-date"
                      style={{ minWidth: '190px' }}
                      onClick={() =>
                        setIsImplementationDatePickerOpen(!isImplementationDatePickerOpen)
                      }
                      readOnly
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          applyFilters();
                        }
                      }}
                    />
                  )}
                />
                {isImplementationDatePickerOpen && (
                  <ContainerLabelDate
                    date={inplementationDate}
                    setDate={setImplementationDate}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                    setIsDatePickerOpen={setIsImplementationDatePickerOpen}
                    inputRef={inputRef}
                    left
                    top="36px"
                    startDateName="startImplementationDate"
                    endDateName="endImplementationDate"
                    onApplyFilter={applyFilters}
                  />
                )}
              </div>

              <div
                className="position-relative"
                onMouseLeave={() => setIsDeliveryDatePickerOpen(false)}
              >
                <Controller
                  control={control}
                  name="deliveryDate"
                  render={({ field }) => (
                    <Input
                      {...field}
                      innerRef={(e) => {
                        field.ref(e);
                        inputRef.current = e;
                      }}
                      value={deliveryDate || ''}
                      placeholder={deliveryDate ? deliveryDate : translate('Delivery date')}
                      id="input-date"
                      style={{ minWidth: '190px' }}
                      onClick={() => setIsDeliveryDatePickerOpen(!isDeliveryDatePickerOpen)}
                      readOnly
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          applyFilters();
                        }
                      }}
                    />
                  )}
                />
                {isDeliveryDatePickerOpen && (
                  <ContainerLabelDate
                    date={deliveryDate}
                    setDate={setDeliveryDate}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                    setIsDatePickerOpen={setIsDeliveryDatePickerOpen}
                    inputRef={inputRef}
                    left
                    top="36px"
                    startDateName="startDeliveryDate"
                    endDateName="endDeliveryDate"
                    onApplyFilter={applyFilters}
                  />
                )}
              </div>

              {!isContent && (
                <Controller
                  control={control}
                  name="impact"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      value={field.value}
                      placeholder={translate('Impact')}
                      className="basic-multi-select filter-select"
                      options={[
                        { value: 'L', label: translate('Low') },
                        { value: 'M', label: translate('Medium') },
                        { value: 'H', label: translate('High') },
                      ]}
                      isMulti
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          applyFilters();
                        }
                      }}
                    />
                  )}
                />
              )}

              <Controller
                control={control}
                name="assignedTo"
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    value={field.value}
                    key={assignedKey}
                    placeholder={translate('Assigned To')}
                    className="basic-multi-select filter-select"
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
                    isMulti
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        applyFilters();
                      }
                    }}
                    styles={{
                      minWidth: '250px',
                    }}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    value={field.value}
                    placeholder={translate('Status')}
                    className="basic-multi-select filter-select"
                    options={[
                      { value: 0, label: translate('Waiting') },
                      { value: 1, label: translate('Ready to Implement') },
                      { value: 2, label: translate('Technical Impediment') },
                      { value: 3, label: translate('Under Development') },
                      { value: 4, label: translate('Implemented') },
                    ]}
                    isMulti
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        applyFilters();
                      }
                    }}
                  />
                )}
              />
              {!isContent && (
                <>
                  <Controller
                    control={control}
                    name="complexity"
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        value={field.value}
                        placeholder={translate('Complexity')}
                        className="basic-multi-select filter-select"
                        options={[
                          { value: 'L', label: translate('Low') },
                          { value: 'M', label: translate('Medium') },
                          { value: 'H', label: translate('High') },
                        ]}
                        isMulti
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            applyFilters();
                          }
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="foundations"
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        value={field.value}
                        placeholder={translate('Foundations')}
                        className="basic-multi-select filter-select"
                        style={{ minWidth: '250px', width: '250px' }}
                        options={[
                          {
                            value: 'Technology',
                            label: translate('Technology'),
                          },
                          { value: 'Content', label: translate('Content') },
                          {
                            value: 'Link Building',
                            label: translate('Link Building'),
                          },
                          { value: 'Report', label: translate('Report') },
                          {
                            value: 'Performance',
                            label: translate('Performance'),
                          },
                          {
                            value: 'Architeture',
                            label: translate('Architeture'),
                          },
                        ]}
                        isMulti
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            applyFilters();
                          }
                        }}
                      />
                    )}
                  />
                </>
              )}

              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <div className="search" style={{ minWidth: '300px' }}>
                    <box-icon name="search" color="#606060" />
                    <Input
                      {...field}
                      value={field.value}
                      placeholder={translate('Search')}
                      required
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          applyFilters();
                        }
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </form>
        </div>

        <div className="filter-btn">
          <Button className="filter-btn-apply" onClick={applyFilters}>
            <box-icon name="check" color="#FFF" />
          </Button>
          <Button className="filter-btn-clear" onClick={cleanFilters}>
            <box-icon name="eraser" color="#FFF" />
          </Button>
          <Button className="filter-btn-back" onClick={handleToggle}>
            {translate('Go back')}
          </Button>
        </div>
      </TasksFilterLayout>
    </div>
  );
};

TasksFilter.propTypes = {
  toggleFilter: PropTypes.func,
  accountId: PropTypes.string,
  isContent: PropTypes.bool,
  show: PropTypes.bool,
};

export default TasksFilter;
