import styled from 'styled-components';

export const CardAssignedDiv = styled.div`
  border: ${(props) => (props.hasResponsibles ? 'none' : '1px solid #e53e3e')};
  .error-text {
    color: #e53e3e;
  }
`;

export const SelectAssignedToDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
  }

  button {
    background-color: transparent;
    margin: 0;
    border: none;
    font-weight: 600;

    &.remove {
      color: #f56565;
    }

    &.add {
      color: #718096;
    }
  }
`;
