import React, { useEffect, useState } from 'react';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MoonLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import 'boxicons';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAccordion,
} from 'reactstrap';

import { GuidelineLayout } from './styles';
import { SendCardLayout } from './components/Send-Card/styles';
import Shimmer from '../../components/Shimmer';
import Breadcrumbs from '../../components/Breadcrumb';
import ButtonMove from './components/Button-Move';
import ButtonDelete from './components/Button-Delete';
import ButtonRefresh from './components/Button-Refresh';
import CardAssignedTo from './components/Card-Assigned-To';
import ButtonEdit from './components/Button-Edit';
import ButtonSave from './components/Button-Save';
import ButtonCancelIcon from './components/Button-Cancel-Icon';
import TableResumeReview from './components/Table-Resume-Review';
import PageLoading from './components/Page-Loading';
import TitleGuideline from './components/Title-Guideline';
import onKeyEvents from 'utils/onKeyEvents';

const ReviewGuideline = () => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const { t: translate } = useTranslation();
  const currentAccountId = user?.account;
  const navigate = useNavigate();
  const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';

  const [guideline, setGuideline] = useState();
  const [topicsList, setTopicsList] = useState([]);
  const [seeQuestionsOnGoogle, setSeeQuestionsOnGoogle] = useState(false);
  const [googleQuestions, setGoogleQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [isLoadingNewTopic, setIsLoadingNewTopic] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [responsibleToApproval, setResponsibleToApproval] = useState([]);
  const [isLoadingResponsibleToApproval, setIsLoadingResponsibleToApproval] = useState(true);
  const [modalApprove, setModalApprove] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [newCustomTopic, setNewCustomTopic] = useState(false);
  const [textTopic, setTextTopic] = useState('');
  const [editTopicIndex, setEditTopicIndex] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleEditTopic = (index, topic) => {
    setNewCustomTopic(false);
    setTextTopic(topic);
    setEditTopicIndex(index);
  };

  const handleCancelEditTopic = () => {
    setNewCustomTopic(false);
    setTextTopic('');
    setEditTopicIndex(null);
  };

  const handleCancelNewTopic = () => {
    setNewCustomTopic(false);
    setTextTopic('');
    const updateTopicsList = [...topicsList];
    updateTopicsList.pop();
    setTopicsList(updateTopicsList);
  };

  const handleSaveTopic = async (topicIndex) => {
    try {
      await firebaseHelper.editGuidelineTopic(currentAccountId, id, topicIndex, textTopic);

      const updateTopicsList = [...topicsList];
      updateTopicsList[topicIndex] = textTopic;
      setTopicsList(updateTopicsList);

      setEditTopicIndex(null);
      setTextTopic('');
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteGuideline = async () => {
    try {
      await firebaseHelper.deleteGuideline(currentAccountId, id);
      navigate('/guidelines');
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveNewTopic = async () => {
    try {
      await firebaseHelper.newGuidelineTopic(currentAccountId, id, textTopic);

      const updateTopicsList = [...topicsList];
      updateTopicsList[topicsList.length - 1] = textTopic;
      setTopicsList(updateTopicsList);

      setTextTopic('');
      setNewCustomTopic(false);
    } catch (error) {
      console.log(error);
    }
  };

  const generateNewTopic = async () => {
    setIsLoadingNewTopic(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generateNewTopic`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      const newTopic = response.data.subHeading;
      setTopicsList([...topicsList, newTopic]);
      setIsLoadingNewTopic(false);
    } catch (error) {
      console.log(error);
      setIsLoadingNewTopic(false);
    }
  };

  const regenerateGuidelineTopic = async (topicIndex) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/regenerateGuidelineTopic`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          topicIndex,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      const updateTopicsList = [...topicsList];
      updateTopicsList[topicIndex] = response.data.subHeading;
      const filteredTopicsList = updateTopicsList.filter((item) => item !== undefined);

      setTopicsList(filteredTopicsList);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteGuidelineTopic = async (topicIndex) => {
    try {
      await firebaseHelper.deleteGuidelineTopic(currentAccountId, id, topicIndex);

      const updateTopicsList = [...topicsList];
      updateTopicsList.splice(topicIndex, 1);

      setTopicsList(updateTopicsList);
    } catch (error) {
      console.log(error);
    }
  };

  const addingCustomTopic = () => {
    setEditTopicIndex(null);
    setTextTopic('');
    setNewCustomTopic(true);
    setTopicsList([...topicsList, textTopic]);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newTopicsListOrder = Array.from(topicsList);
    const [reorderedItem] = newTopicsListOrder.splice(result.source.index, 1);
    newTopicsListOrder.splice(result.destination.index, 0, reorderedItem);

    const filteredNewTopicsListOrder = newTopicsListOrder.filter((item) => item !== undefined);

    setTopicsList(filteredNewTopicsListOrder);

    const newOrder = {};
    newTopicsListOrder.forEach((topic, index) => {
      newOrder[index] = { topic: topic };
    });

    await firebaseHelper.updateStructureTopicsOrder(currentAccountId, id, newOrder);
  };

  const getGoogleQuestions = async () => {
    setIsLoadingQuestions(true);
    setSeeQuestionsOnGoogle(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getGoogleQuestions`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );
      setGoogleQuestions(response.data.googleQuestions);
      setIsLoadingQuestions(false);
      setSeeQuestionsOnGoogle(true);
    } catch (error) {
      console.log(error);
      setIsLoadingQuestions(false);
      setSeeQuestionsOnGoogle(false);
    }
  };

  const addQuestionToTopics = async (question) => {
    try {
      await firebaseHelper.addQuestionToTopics(currentAccountId, id, question);

      setTopicsList([...topicsList, question]);
    } catch (error) {
      console.log(error);
    }
  };

  const sendToApproval = async () => {
    try {
      setIsSending(true);
      await firebaseHelper.addGuidelineId(currentAccountId, id);
      await firebaseHelper.sendGuidelineToApproval(currentAccountId, id);
      setIsSending(false);
      navigate('/guidelines');
    } catch (error) {
      setIsSending(false);
      setModalApprove(false);
      if (error === 'You must select at least one person to approve') {
        toast.error(translate(error));
        return;
      }
      console.log(error);
    }
  };

  const toggleModalApprove = () => setModalApprove(!modalApprove);

  const toggleModalDelete = () => setModalDelete(!modalDelete);

  const fetchUserName = async (id) => {
    try {
      const userName = await firebaseHelper.getUserName(id);
      return { value: id, label: userName };
    } catch (error) {
      console.error(`This user doesn't exist`, error);
      return null;
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const loadedGuideline = await firebaseHelper.getFirebaseGuideline(currentAccountId, id);

    if (loadedGuideline.column !== 'BACKLOG') {
      navigate('/guidelines');
    }

    setIsProcessing(loadedGuideline.processing);
    setIsError(loadedGuideline?.error);
    setGuideline(loadedGuideline);

    if (loadedGuideline?.structure) {
      setTopicsList(loadedGuideline.structure.map((item) => item.topic));
    }

    const getApprovalTeam = await firebaseHelper.getApprovalTeam(currentAccountId);

    const fetchApprovalTeamPromises = (getApprovalTeam || []).map((id) => fetchUserName(id));

    Promise.all(fetchApprovalTeamPromises)
      .then((userInfo) => {
        const filteredApprovalTeam = userInfo.filter((info) => info !== null);

        setTeamData(filteredApprovalTeam);
        setResponsibleToApproval(filteredApprovalTeam);
        setIsLoadingResponsibleToApproval(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async function handleRetry() {
    try {
      if (!guideline?.id || !currentAccountId)
        throw new Error('Guideline ID or Account ID is missing');
      const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
      const firebaseToken = await firebaseHelper.getIdToken();
      setIsProcessing(true);
      setIsError(false);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/regenerateGuideline`,
        {
          accountId,
          guidelineId: guideline?.id,
          development: !isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );
    } catch (error) {
      setIsProcessing(false);
      setIsError(true);
      console.log(error);
    }
  }

  useEffect(async () => {
    const onlyIds = responsibleToApproval.map((item) => item.value);
    await firebaseHelper.setResponsibleToApproval(currentAccountId, id, onlyIds);
  }, [responsibleToApproval]);

  useEffect(() => {
    if (!currentAccountId || !id) return;

    fetchData();
  }, [currentAccountId, id]);

  useEffect(() => {
    if (!isProcessing && !isError) return;

    const db = getDatabase();
    const guidelineRef = ref(db, `guidelines/${currentAccountId}/${id}`);

    const unsubscribe = onValue(guidelineRef, (snapshot) => {
      const guidelineData = snapshot.val();

      if (!guidelineData) {
        navigate('/404');
        return;
      }

      if (guidelineData?.column !== 'BACKLOG') {
        navigate(`/guideline/${id}`);
        return;
      }

      if (guidelineData?.processing === false || guidelineData?.error === false) {
        fetchData();
      }
    });

    return () => {
      off(guidelineRef, 'value', unsubscribe);
    };
  }, [isProcessing, isError]);

  return (
    <GuidelineLayout>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Guideline Review')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs
          title={translate('Guideline')}
          breadcrumbItem={translate('Guideline Review')}
        />

        {isError && (
          <PageLoading
            title={translate('An error occurred')}
            description={translate('There was an error processing this guideline.')}
            button={translate('Try Again')}
            onClick={handleRetry}
            buttonStyles={{ backgroundColor: '#F56565', color: '#fff' }}
          >
            <box-icon name="x-circle" color="#F56565" size="7rem"></box-icon>
          </PageLoading>
        )}

        {isProcessing && !isError && (
          <PageLoading
            title={translate('This guideline is being processed')}
            description={translate(
              'This may take a few minutes, you can also wait for this process in the background and return later.',
            )}
            button={translate('Go back')}
            onClick={() => navigate('/guidelines')}
          />
        )}

        {!isProcessing && !isError && (
          <div className="container-row">
            <div className="container-general-information">
              <div className="header-general-information">
                <div>
                  <button
                    aria-label="Back"
                    type="button"
                    id="back-button"
                    className="back-button"
                    onClick={() => navigate('/guidelines')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.21875 7.33117H13.3334V8.6645H5.21875L8.79475 12.2405L7.85208 13.1832L2.66675 7.99783L7.85208 2.8125L8.79475 3.75517L5.21875 7.33117Z"
                        fill="#2D3748"
                      />
                    </svg>
                  </button>
                  {guideline?.primaryKeyword && <TitleGuideline guideline={guideline} />}
                </div>
              </div>
              {isLoading ? (
                <>
                  <Shimmer height="25em" style={{ marginBottom: '3em' }} />
                  <Shimmer height="60em" />
                </>
              ) : (
                <UncontrolledAccordion defaultOpen={['1', '2', '3']} stayOpen>
                  <TableResumeReview
                    data={guideline}
                    accountId={currentAccountId}
                    guidelineId={id}
                    onlyResume
                  />
                  <AccordionItem>
                    <AccordionHeader targetId="2">{translate('Content Structure')}</AccordionHeader>
                    <AccordionBody accordionId="2">
                      {topicsList && (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="contentList">
                            {(provided) => {
                              return (
                                <ul
                                  className="list-group contentList"
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {topicsList.map((item, index) => (
                                    <Draggable key={item} draggableId={item} index={index}>
                                      {(provided) => {
                                        return (
                                          <li
                                            onKeyUp={(e) => {
                                              const isValid =
                                                (newCustomTopic &&
                                                  index === topicsList.length - 1) ||
                                                editTopicIndex === index;

                                              onKeyEvents.onEnter({
                                                event: e,
                                                enabled: isValid,
                                                callback: () =>
                                                  newCustomTopic
                                                    ? handleSaveNewTopic()
                                                    : handleSaveTopic(index),
                                              });
                                              onKeyEvents.onEscape({
                                                event: e,
                                                enabled: isValid,
                                                callback: () => {
                                                  newCustomTopic
                                                    ? handleCancelNewTopic()
                                                    : handleCancelEditTopic();
                                                },
                                              });
                                            }}
                                            className="list-group-item"
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                          >
                                            <div
                                              className="container-btns"
                                              style={{ marginRight: '24px' }}
                                            >
                                              <ButtonMove />
                                              {editTopicIndex === index ||
                                              (newCustomTopic &&
                                                index === topicsList.length - 1) ? (
                                                <Input
                                                  type="text"
                                                  className={`topic-${index}`}
                                                  name={`topic-${index}`}
                                                  value={textTopic}
                                                  bsSize="md"
                                                  style={{
                                                    width: '30rem',
                                                  }}
                                                  onChange={(e) => {
                                                    setTextTopic(e.target.value);
                                                  }}
                                                  onKeyDown={(e) => {
                                                    if (e.key === 'Escape') handleCancelEditTopic();
                                                  }}
                                                  autoFocus
                                                />
                                              ) : (
                                                <div onClick={() => handleEditTopic(index, item)}>
                                                  {item}
                                                </div>
                                              )}
                                            </div>
                                            {editTopicIndex === index ? (
                                              <div className="d-flex justify-content-end">
                                                <ButtonSave
                                                  onClickFunction={() => handleSaveTopic(index)}
                                                />
                                                <ButtonCancelIcon
                                                  onClickFunction={handleCancelEditTopic}
                                                />
                                              </div>
                                            ) : newCustomTopic &&
                                              index === topicsList.length - 1 ? (
                                              <>
                                                <ButtonSave
                                                  onClickFunction={() => handleSaveNewTopic()}
                                                />
                                                <ButtonCancelIcon
                                                  onClickFunction={handleCancelNewTopic}
                                                />
                                              </>
                                            ) : (
                                              <div className="container-btns">
                                                <ButtonEdit
                                                  onClickFunction={() =>
                                                    handleEditTopic(index, item)
                                                  }
                                                />
                                                <ButtonRefresh
                                                  onClickFunction={() =>
                                                    regenerateGuidelineTopic(index)
                                                  }
                                                  index={index}
                                                />
                                                <ButtonDelete
                                                  deleteGuidelineTopic={deleteGuidelineTopic}
                                                  index={index}
                                                />
                                              </div>
                                            )}
                                          </li>
                                        );
                                      }}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </ul>
                              );
                            }}
                          </Droppable>
                        </DragDropContext>
                      )}
                      <div className="container-btns-bottom">
                        <Button className="btn-new-topic" onClick={addingCustomTopic}>
                          {translate('Custom topic')}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M6.41675 6.41406V2.91406H7.58341V6.41406H11.0834V7.58073H7.58341V11.0807H6.41675V7.58073H2.91675V6.41406H6.41675Z"
                              fill="white"
                            />
                          </svg>
                        </Button>
                        <Button className="btn-transparent" onClick={generateNewTopic}>
                          {isLoadingNewTopic ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                              }}
                            >
                              {translate('Loading')}
                              <MoonLoader size={16} color="#556ee6" />
                            </div>
                          ) : (
                            <React.Fragment>{translate('New topic by AI')}</React.Fragment>
                          )}
                        </Button>
                        <Button className="btn-transparent" onClick={getGoogleQuestions}>
                          {isLoadingQuestions ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                              }}
                            >
                              {translate('Loading')}
                              <MoonLoader size={15} color="#556ee6" />
                            </div>
                          ) : (
                            translate('To see questions on Google')
                          )}
                        </Button>
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                  {seeQuestionsOnGoogle && (
                    <AccordionItem>
                      <AccordionHeader targetId="3">
                        {translate('Google questions')}
                      </AccordionHeader>
                      <AccordionBody accordionId="3">
                        <ul className="list-group">
                          {googleQuestions.map((question, index) => (
                            <li className="list-group-item" key={index}>
                              <div className="container-btns" style={{ marginRight: '24px' }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M17.12 5.856C15.733 4.698 13.948 4 12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12H13"
                                    stroke="#718096"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                {question.question}
                                <Badge color="light">
                                  {question.type === 'primary'
                                    ? translate('Primary')
                                    : translate('Secondary')}
                                </Badge>
                              </div>
                              {topicsList.includes(question.question) ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <path
                                    d="M6.99984 12.8307C3.77809 12.8307 1.1665 10.2191 1.1665 6.9974C1.1665 3.77565 3.77809 1.16406 6.99984 1.16406C10.2216 1.16406 12.8332 3.77565 12.8332 6.9974C12.8332 10.2191 10.2216 12.8307 6.99984 12.8307ZM6.41825 9.33073L10.5424 5.20598L9.71759 4.38115L6.41825 7.68106L4.768 6.03081L3.94317 6.85565L6.41825 9.33073Z"
                                    fill="#48BB78"
                                  />
                                </svg>
                              ) : (
                                <Button
                                  className="btn-add-question"
                                  onClick={() => addQuestionToTopics(question.question)}
                                >
                                  {translate('Include')}
                                </Button>
                              )}
                            </li>
                          ))}
                        </ul>
                      </AccordionBody>
                    </AccordionItem>
                  )}
                </UncontrolledAccordion>
              )}
            </div>
            <div className="container-side-column">
              <SendCardLayout>
                <p className="subtitle">{translate('Action')}</p>
                <Button onClick={toggleModalApprove}>{translate('Submit for approval')}</Button>
                <Button className="btn-delete" onClick={toggleModalDelete}>
                  <i className="bx bxs-error-circle" />
                  {translate('Delete guideline')}
                </Button>
                <p className="text-observation">
                  {translate(
                    'When submitting the agenda for approval, it is included within the tasks of the individuals responsible for this approval within the project.',
                  )}
                </p>
                <Modal isOpen={modalApprove} toggle={toggleModalApprove} centered>
                  <ModalHeader toggle={toggleModalApprove}>
                    {translate('Submit for approval')}
                  </ModalHeader>
                  <ModalBody>
                    {translate('Are you sure you want to submit this agenda for approval?')}
                  </ModalBody>
                  <ModalFooter style={{ border: 'none' }}>
                    <Button onClick={toggleModalApprove}>{translate('Cancel')}</Button>
                    <Button
                      style={{
                        backgroundColor: '#48bb78',
                        border: 'none',
                        display: 'flex',
                        gap: '8px',
                      }}
                      onClick={sendToApproval}
                    >
                      {isSending ? (
                        <MoonLoader color="#FFF" size={10} />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.99992 14.6693C4.31792 14.6693 1.33325 11.6846 1.33325 8.0026C1.33325 4.3206 4.31792 1.33594 7.99992 1.33594C11.6819 1.33594 14.6666 4.3206 14.6666 8.0026C14.6666 11.6846 11.6819 14.6693 7.99992 14.6693ZM7.33525 10.6693L12.0486 5.95527L11.1059 5.0126L7.33525 8.78394L5.44925 6.89794L4.50659 7.8406L7.33525 10.6693Z"
                            fill="white"
                          />
                        </svg>
                      )}

                      {translate('Approve')}
                    </Button>
                  </ModalFooter>
                </Modal>
                <Modal isOpen={modalDelete} toggle={toggleModalDelete} centered>
                  <ModalHeader toggle={toggleModalDelete}>
                    {translate('Delete guideline')}
                  </ModalHeader>
                  <ModalBody>
                    {translate('When deleting this agenda it cannot be recovered later.')}
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={toggleModalDelete}>{translate('Cancel')}</Button>
                    <Button
                      className="btn-delete"
                      style={{
                        backgroundColor: '#f46a6a',
                        border: '1px solid #f46a6a',
                      }}
                      onClick={handleDeleteGuideline}
                    >
                      {translate('Delete')}
                    </Button>
                  </ModalFooter>
                </Modal>
              </SendCardLayout>

              <CardAssignedTo
                isLoading={isLoadingResponsibleToApproval}
                assignedTo={responsibleToApproval}
                setAssignedTo={setResponsibleToApproval}
                teamData={teamData}
                title={translate('Those responsible for approval')}
                isMulti
              />
            </div>
          </div>
        )}
      </div>
    </GuidelineLayout>
  );
};

export default ReviewGuideline;
