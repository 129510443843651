import styled from 'styled-components';
import { Button } from 'reactstrap';

import { mediaQuery } from '../../constants';

export const GuidelineLayout = styled.div`
  .container-row {
    display: flex;
    gap: 32px;

    @media ${mediaQuery.less.sm} {
      flex-direction: column;
    }
  }

  .container-general-information {
    width: 100%;
    min-width: 65%;
    padding: 32px;
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 24px;

    @media ${mediaQuery.less.sm} {
      padding: 16px;
    }
  }

  .header-general-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    p {
      font-size: 16px;
      margin: 0 0 0 12px;
    }

    @media ${mediaQuery.less.sm} {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
  }

  .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 9999px;
    border: 1px solid #e2e8f0;
    background-color: #ffffff;
  }

  .back-button:hover {
    background-color: #edf2f7;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 600;
  }

  .text-observation {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #2d3748;
  }

  .container-cards {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .container-side-column {
    width: 332px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .button-add-new-text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    margin: 32px 0 0 0;

    button {
      display: flex;
      height: 48px;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 9999px;
      border: 1px solid #edf2f7;
      background-color: #f7fafc;
    }
    button:hover {
      background-color: #edf2f7;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #4a5568;
      margin: 0;
    }
  }

  .card-assigned {
    height: fit-content;
    padding: 16px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 6px;

    .avatar-group {
      display: flex;
      flex-direction: column;
      gap: 14px;

      .avatar-group-item {
        display: flex;
        align-items: center;
        gap: 8px;

        .avatar-user {
          display: flex;
          flex-direction: column;
        }

        .avatar-name {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: #2d3748;
          margin: 0;
        }
      }
    }
  }

  .dropdown-include {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    margin: 32px 0 0 0;

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #2d3748;
      margin: 0;
    }

    i {
      color: #2d3748;
    }
  }

  .dropdown-include:hover {
    background-color: #f7fafc !important;
  }

  .accordion {
    .accordion-item {
      border: none;
    }
    .accordion-button {
      display: flex;
      background-color: transparent;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      color: #1a202c;
    }

    .table {
      th {
        background-color: #f7fafc;
        width: 283px;
      }

      td {
        background-color: transparent;
      }
    }

    .list-group {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .list-group-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 1px solid #edf2f7;
        border-radius: 4px;
        margin: 0;
        padding: 8px;
      }

      .list-group-item:hover {
        background-color: #f7fafc;
      }

      .error {
        color: #f56565;
        font-size: 0.8rem;
      }

      .border-red {
        border: 1.5px solid #f56565;
      }

      .btn-move,
      .btn-delete,
      .btn-refresh,
      .btn-edit {
        background-color: transparent;
        border: none;
        padding: 0;
      }

      .btn-delete,
      .btn-refresh,
      .btn-edit {
        width: 32px;
        height: 32px;
        padding: 8px;
        display: flex;
        justify-content: center;
      }
      .btn-delete:hover,
      .btn-refresh:hover,
      .btn-edit:hover {
        background-color: #edf2f7;
      }

      .container-btns {
        display: flex;
        gap: 8px;
        justify-self: flex-end;
        align-items: center;
      }

      .btn-add-question {
        display: flex;
        align-items: center;
        height: 24px;
        padding: 10px 8px;
        background-color: #556ee6;
        border: none;
        font-size: 12px;
        line-height: 16px;
      }

      .btn-add-question:hover {
        background-color: #3b5bfc;
        border: none;
      }
    }

    .btn-new-topic {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background-color: #556ee6;
      border: none;
    }

    .btn-new-topic:hover {
      background-color: #3b5bfc;
    }

    .container-btns-bottom {
      display: flex;
      gap: 16px;
      margin-top: 16px;
    }
  }

  .btn-transparent {
    min-width: 131px;
    background-color: transparent;
    border: none;
    color: #2d3748 !important;
  }

  .btn-transparent:hover {
    background-color: #f7fafc;
  }

  .table-guideline {
    tr {
      border-top: 1px solid #eff2f7;
      border-left: 1px solid #eff2f7;
      border-right: 1px solid #eff2f7;
    }
    td {
      background-color: #fff;
      border: none !important;
      vertical-align: middle;
    }
    .table-guideline-details {
      border-top: none !important;
      border-left: 1px solid #eff2f7;
      border-right: 1px solid #eff2f7;
      border-bottom: 1px solid #eff2f7;
      td {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    ul {
      padding: 0 !important;

      li {
        list-style-type: none;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: #718096;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .table-th-comment {
    p {
      margin: 0 !important;
    }
    span {
      margin: 0 !important;
      font-size: 12px;
      line-height: 16px;
      color: #718096;
    }
  }

  .form-check {
    padding: 0;
    margin: 0;
  }

  .form-check-input {
    margin-right: 8px;
  }

  .badge-guideline-observation {
    font-size: 14px;
    font-weight: 300;
    padding: 8px;
    color: #718096;
    margin-bottom: 24px;
  }

  .nav-tabs {
    height: 40px;
    display: flex;
    gap: 24px;
  }

  .nav-item {
    .active {
      width: 180px;
      padding: 16px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
    .actived {
      color: #556ee6;
      font-weight: 500;
      border-bottom: 2px solid #556ee6;
    }
  }

  .tab-content {
    margin-top: 40px;
  }
`;

export const KanbanGuidelineLayout = styled.div`
  .react-kanban-column {
    background-color: #f9fafb;
    border-radius: 0 0 16px 16px;
    padding: 0 0 10px 10px;

    .card-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #475467;
      border-bottom: 1px solid #eaecf0;
      padding: 12px 0 12px 12px;
    }
  }

  .no-drag {
    filter: blur(5px);
    transform: none !important;

    div[data-rbd-drag-handle-draggable-id] {
      transform: none !important;
    }

    div[data-rbd-placeholder-context-id] {
      height: 0px !important;
    }
  }

  .react-kanban-board {
    padding: 5px 0 !important;
  }

  .react-kanban-board > div > div:first-child {
    margin-left: 0;
  }
`;

export const GoBackButton = styled(Button)`
  background-color: #edf2f7;
  color: #4a5568 !important;
`;

export const TargetSize = styled.span`
  height: min-content;
  width: max-content;
  padding: 0 0.5rem;
  background-color: #f7fafc;
  border-radius: 0.25rem;
  border: 1px solid #edf2f7;
  color: #74788d;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
