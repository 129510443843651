import translationPtBR from './ptBR/translation.json';
import common from './ptBR/common.json';
import changelogPtBR from './ptBR/changelog.json';
import countriesAndLangsPtBR from './ptBR/countries-and-langs.json';

export const resourcesPtBR = {
  ...translationPtBR,
  ...changelogPtBR,
  ...countriesAndLangsPtBR,
  ...common,
};
