import i18n from 'i18n';
import { getDateByWeekNumber } from 'utils';

const DataPerformanceChart = ({ performanceData }) => {
  const formatedLabels = performanceData?.weekList?.map((week) => {
    const date = getDateByWeekNumber(week);
    const weekNumber = i18n.t('Week') + ' ' + week.split('-')[1];
    return `${weekNumber}\n${date}`;
  });

  const datasetsData = [
    {
      label: 'Sem ranking',
      backgroundColor: '#EAEAEA',
      borderWidth: 0,
      data: performanceData?.byDate?.map((week) => week.ranking['no-ranking'] || 0),
    },
    {
      label: '20+',
      backgroundColor: '#A0AEC0',
      borderWidth: 0,
      data: performanceData?.byDate?.map((week) => week.ranking['20+'] || 0),
    },
    {
      label: '11 a 20',
      backgroundColor: '#BEE3F8',
      borderWidth: 0,
      data: performanceData?.byDate?.map((week) => week.ranking['11-20'] || 0),
    },
    {
      label: '7 a 10',
      backgroundColor: '#63B3ED',
      borderWidth: 0,
      data: performanceData?.byDate?.map((week) => week.ranking['7-10'] || 0),
    },
    {
      label: '4 a 6',
      backgroundColor: '#3182CE',
      borderWidth: 0,
      data: performanceData?.byDate?.map((week) => week.ranking['4-6'] || 0),
    },
    {
      label: '1 a 3',
      backgroundColor: '#F6B950',
      borderWidth: 0,
      data: performanceData?.byDate?.map((week) => week.ranking['1-3'] || 0),
    },
  ];

  const data = {
    labels: formatedLabels,
    datasets: datasetsData,
    weeks: performanceData?.weekList,
  };

  return data;
};

export default DataPerformanceChart;
