const formatDateTimestampNumber = (timestamp, lang) => {
  if (!timestamp) {
    return '';
  }

  const date = new Date(timestamp);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  if (lang === 'date-enUS') {
    return `${month}/${day}/${year}`;
  } else {
    return `${day}/${month}/${year}`;
  }
};

export { formatDateTimestampNumber };
