import * as React from 'react';

export const GDriveIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M0.716882 10.1667C0.714233 10.4017 0.773657 10.6373 0.895158 10.8479L2.52077 13.6662C2.75887 14.079 3.19919 14.3334 3.67574 14.3334H12.3257C12.8022 14.3334 13.2425 14.079 13.4806 13.6662L15.1063 10.8479C15.2278 10.6373 15.2872 10.4017 15.2845 10.1667H0.716882Z"
        fill="#4285F4"
      />
      <path
        d="M12.997 14.1514L5.70508 1.52149C5.90819 1.40036 6.14333 1.33331 6.38801 1.33331H9.61155C10.0879 1.33331 10.5281 1.58744 10.7662 1.99998L15.1051 9.51502C15.3431 9.92738 15.3432 10.4354 15.1053 10.8479L13.4797 13.6662C13.3617 13.8707 13.1941 14.0364 12.997 14.1514Z"
        fill="#FBBC04"
      />
      <path
        d="M10.2954 1.52149C10.0923 1.40036 9.85716 1.33331 9.61248 1.33331H6.38894C5.91259 1.33331 5.47242 1.58744 5.23424 1.99998L0.89543 9.51502C0.65735 9.92739 0.657246 10.4354 0.895158 10.8479L2.52077 13.6662C2.63876 13.8707 2.8064 14.0364 3.0035 14.1514L10.2954 1.52149Z"
        fill="#34A853"
      />
      <path
        d="M15.2845 10.1667C15.2872 10.4017 15.2277 10.6373 15.1062 10.8479L13.4806 13.6662C13.3626 13.8708 13.195 14.0364 12.9979 14.1515L10.6973 10.1667H15.2845Z"
        fill="#EA4335"
      />
      <path
        d="M10.2945 1.52149C10.0914 1.40036 9.85623 1.33331 9.61155 1.33331H6.38801C6.14333 1.33331 5.90819 1.40036 5.70508 1.52149L7.99978 5.49603L10.2945 1.52149Z"
        fill="#188038"
      />
      <path
        d="M0.716882 10.1667C0.714233 10.4017 0.773657 10.6373 0.895158 10.8479L2.52077 13.6662C2.63876 13.8708 2.8064 14.0364 3.0035 14.1515L5.30413 10.1667H0.716882Z"
        fill="#1967D2"
      />
    </svg>
  );
};
