import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import { ButtonAddKeywordStyles } from './styles';

const ButtonAddKeyword = ({ onClickFunction, disabled }) => {
  const { t: translate } = useTranslation();

  return (
    <ButtonAddKeywordStyles>
      <Button onClick={() => onClickFunction()} disabled={disabled}>
        {translate('Add keyword')}
        <i className="bx bx-plus" />
      </Button>
    </ButtonAddKeywordStyles>
  );
};

ButtonAddKeyword.propTypes = {
  onClickFunction: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ButtonAddKeyword;
