import styled from 'styled-components';
import { UncontrolledDropdown } from 'reactstrap';

export const ButtonSelectGroupStyled = styled(UncontrolledDropdown)`
  button {
    padding: 0.625rem 0.75rem;
  }

  .btn-dropdown-toggle-group {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e2e8f0;
    background: #fff;
    color: rgba(0, 0, 0, 0.8) !important;
    font-style: normal;
    font-weight: 400;

    i {
      font-size: 1.5;
      color: #718096;
    }
  }

  .dropdown-item {
    width: 21rem;
  }
  .dropdown-item:hover {
    background-color: #f7fafc !important;
  }

  .btn-add-new-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    color: #4a5568;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.625rem 0.75rem;
    cursor: pointer;

    .little-ball {
      display: flex;
      width: 1rem;
      height: 1rem;
      padding: 0.625rem 0.75rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: 6.1875rem;
      border: 1px solid #edf2f7;
      background: #f7fafc;

      i {
        font-size: 14px;
        color: #4a5568;
      }
    }
  }

  .btn-create-group {
    background-color: #48bb78;
    border: none;
  }
  .btn-create-group:hover {
    background-color: #38a169;
  }
`;
