import styled from 'styled-components';

export const ButtonDownloadStyles = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    height: 2.5rem;
    padding: 0.625rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background-color: transparent;
    color: #343a40 !important;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }
  button:hover {
    background-color: #f7fafc;
    border: 1px solid #e2e8f0;
  }
  button:disabled {
    cursor: not-allowed;
    background-color: #f7fafc;
    border: 1px solid #e2e8f0;
  }

  .btn-open {
    background: #e2e8f0;
  }

  i {
    width: 1rem;
    height: 1rem;
    color: #2d3748;
  }

  .dropdown-menu {
    width: 16.875rem;
    border-radius: 0.5rem;
    border: 1px solid #e2e8f0;

    span {
      color: #343a40;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .form-check {
      label {
        color: #343a40;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .dropdown-footer {
      border-top: 1px solid #e2e8f0;
      padding: 0.75rem;
      background-color: #f2f2f5;

      button {
        height: 2rem;
        width: min-content;
        color: #343a40;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .btn-download {
        border: none;
        background-color: #556ee6;
        color: #ffffff !important;
      }
      .btn-download:hover {
        background-color: #4458b8;
      }
    }
  }
`;
