import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import { ContainerButtonsStyled, FilterKeywordMonitoringStyled } from './styles';
import ContainerLabelDate from 'pages/guideline/components/Container-Label-Date';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

const FilterKeywordMonitoring = ({ groups, fetchPerformanceData, setFilters }) => {
  const formConfig = {
    defaultValues: {
      startDate: null,
      endDate: null,
      groups: null,
      tags: null,
    },
  };
  const { control, getValues, setValue, watch, reset } = useForm(formConfig);
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const accountId = user.account;
  const { t: translate } = useTranslation();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    const params = new URLSearchParams(location.search);
    params.set('page', 1);
    navigate(`?${params.toString()}`, { replace: true });

    reset();
    setStartDate('');
    fetchDataFiltered();
  };

  const fetchDataFiltered = async () => {
    setIsLoading(true);

    const filters = getValues();
    setFilters(filters);

    await fetchPerformanceData(filters);

    setIsLoading(false);
  };

  const fetchTags = async () => {
    const firebaseToken = await firebaseHelper.getIdToken();

    const groupsSelected = getValues('groups') || [];
    const filterGroups = groupsSelected.map((group) => group?.value);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getKeywordsTags`,
        {
          accountId: accountId,
          development: process.env.REACT_APP_BUILD_TYPE !== 'production',
          groups: filterGroups,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );
      setTags(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, [watch('groups')]);

  return (
    <>
      <FilterKeywordMonitoringStyled>
        <form style={{ position: 'relative' }}>
          <div className="menu-column" style={{ width: '100%' }}>
            <Label className="filter-label" for="input-date">
              {translate('Date')}
            </Label>
            <Input
              value={startDate || ''}
              placeholder={startDate ? startDate : translate('Select')}
              className="basic-multi-select filter-select"
              id="input-date"
              style={{ minWidth: '100%', cursor: isLoading ? 'not-allowed' : 'pointer' }}
              onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
              readOnly
              disabled={isLoading}
            />
          </div>
          {isDatePickerOpen && (
            <ContainerLabelDate
              date={startDate}
              setDate={setStartDate}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              setIsDatePickerOpen={setIsDatePickerOpen}
              left
            />
          )}
          <div className="menu-column" style={{ width: '100%' }}>
            <Label className="filter-label" for="groups">
              {translate('Groups')}
            </Label>
            <Controller
              control={control}
              name="groups"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  value={field.value}
                  placeholder={translate('Select')}
                  className="basic-multi-select filter-select"
                  style={{ minWidth: '100%' }}
                  options={groups}
                  isMulti
                  isDisabled={isLoading}
                />
              )}
            />
          </div>
          <div className="menu-column" style={{ width: '100%' }}>
            <Label className="filter-label" for="tags">
              {translate('Tags')}
            </Label>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  value={field.value}
                  placeholder={translate('Select')}
                  className="basic-multi-select filter-select"
                  style={{ minWidth: '100%' }}
                  options={tags}
                  isMulti
                  isDisabled={isLoading}
                />
              )}
            />
          </div>
        </form>
      </FilterKeywordMonitoringStyled>
      <ContainerButtonsStyled className="d-flex justify-content-start pb-4 ps-4 pe-4 gap-3">
        <Button
          color="primary"
          className="btn-filter"
          onClick={fetchDataFiltered}
          disabled={isLoading}
        >
          {isLoading ? <i className="bx bx-loader-alt bx-spin" /> : translate('Filter')}
        </Button>
        <Button className="btn-filter-clear" onClick={clearFilters} disabled={isLoading}>
          {translate('Clear filters')}
        </Button>
      </ContainerButtonsStyled>
    </>
  );
};

FilterKeywordMonitoring.propTypes = {
  groups: PropTypes.array,
  fetchPerformanceData: PropTypes.func,
  setFilters: PropTypes.func,
};

export default FilterKeywordMonitoring;
