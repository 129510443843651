import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { useAuth } from 'hooks/useAuth';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import ButtonTag from '../Button-Tag';
import ButtonAddTag from '../Button-Add-Tag';
import ButtonSelectGroup from './components/Button-Select-Group';

const ModalEditKeyword = ({ isOpen, toggle, keyword, idSelected, fetchGroup, groups }) => {
  const { t: translate } = useTranslation();
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const { id: groupId } = useParams();
  const accountId = user.account;
  const [data, setData] = useState([]);
  const [selectedIdGroup, setSelectedIdGroup] = useState(groupId);
  const [isSending, setIsSending] = useState(false);

  const onSubmit = async () => {
    try {
      setIsSending(true);

      await firebaseHelper.updateKeywordTags(accountId, groupId, idSelected, data[0].tags);

      if (selectedIdGroup !== groupId) {
        await firebaseHelper.moveKeywordToNewGroup(accountId, groupId, selectedIdGroup, idSelected);
      }

      fetchGroup();
      setIsSending(false);
      toggle();
    } catch (error) {
      setIsSending(false);
      console.error('Error updating keyword: ', error);
    }
  };

  useEffect(() => {
    if (keyword?.id === idSelected) {
      const values = [
        {
          name: keyword?.name,
          tags: keyword?.tags,
        },
      ];
      setData(values);
    }
  }, [isOpen, keyword, idSelected]);

  return (
    <ModalEditKeywordStyled
      isOpen={isOpen}
      toggle={() => {
        if (!isSending) toggle();
      }}
      centered
    >
      <ModalHeader>{keyword?.name.charAt(0).toUpperCase() + keyword?.name.slice(1)}</ModalHeader>
      <ModalBody className="d-flex flex-column gap-4">
        <div className="d-flex flex-column gap-3">
          <p className="m-0">{'Tags:'}</p>
          <div className="d-flex gap-2 flex-wrap">
            {data[0]?.tags?.map((tag, index) => (
              <ButtonTag
                tagName={tag}
                key={tag + index}
                setData={setData}
                keywordName={keyword?.name}
              />
            ))}
            <ButtonAddTag
              direction="up"
              data={data}
              setData={setData}
              keywordName={keyword?.name}
              disabled={isSending}
            />
          </div>
        </div>
        <div className="d-flex flex-column gap-3">
          <Label className="m-0">{translate('Group')}:</Label>
          <ButtonSelectGroup
            groups={groups}
            selectedIdGroup={selectedIdGroup}
            setSelectedIdGroup={setSelectedIdGroup}
            disabled={isSending}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggle} disabled={isSending}>
          {translate('Cancel')}
        </Button>
        <Button className="btn-submit" onClick={onSubmit} disabled={isSending}>
          {isSending ? <i className="bx bx-loader-alt bx-spin" /> : translate('Save')}
        </Button>
      </ModalFooter>
    </ModalEditKeywordStyled>
  );
};

const ModalEditKeywordStyled = styled(Modal)`
  min-width: 30rem;

  .btn-submit {
    width: 4rem;
    background-color: #48bb78;
    border: none;
  }
  .btn-submit:hover {
    background-color: #2f8c58;
  }
`;

ModalEditKeyword.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  keyword: PropTypes.object,
  idSelected: PropTypes.string,
  fetchGroup: PropTypes.func,
  groups: PropTypes.array,
};

export default ModalEditKeyword;
