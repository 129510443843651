import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container, Button } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { useAuth } from 'hooks/useAuth';
import logo from '../../assets/images/small-logo.svg';
import LanguageDropdown from '../../components/Language-Dropdown';
import TextInput from 'components/Text-Input';
import LabelGroup from 'components/Label-Group';

const Login = () => {
  const formConfig = {
    defaultValues: {
      email: '',
      password: '',
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };
  const form = useForm(formConfig);
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = form;
  const { login } = useAuth();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const goto = url.searchParams.get('goto') ?? '/';
  const [isSending, setIsSending] = useState(false);

  const handleValidSubmit = async (data) => {
    setIsSending(true);
    try {
      await login(data.email, data.password, goto);
      navigate(goto);

      setIsSending(false);
    } catch (error) {
      setIsSending(false);
      setError('email', { type: 'manual', message: translate('Invalid email or password') });
      setError('password', { type: 'manual', message: translate('Invalid email or password') });
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{translate('Login')} | Ectools</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(242, 242, 242, 0.22)' }}>
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 style={{ color: '#344792' }}>Ectools</h5>
                        <p style={{ color: '#344792' }}>{translate('Your SEO Dashboard.')}</p>
                      </div>
                    </Col>
                    <Col className="col-5" style={{ paddingLeft: '25%' }}>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img src={logo} alt={translate("Ectools' Logo")} height="36" />
                      </div>
                    </Link>
                  </div>
                  <div className="px-2 pb-2 w-100">
                    <FormProvider {...form}>
                      <form
                        className="form-horizontal w-100"
                        onSubmit={handleSubmit(handleValidSubmit)}
                      >
                        {Object.keys(errors).length > 0 && (
                          <Alert color="danger">{translate('Invalid email or password')}</Alert>
                        )}
                        <LabelGroup column mb={2} htmlFor="email" label="Email" size={12} required>
                          <TextInput
                            controlName="email"
                            className={`form-control ${errors.email ? 'error--input' : ''}`}
                            placeholder={translate('Enter email')}
                            type="email"
                            required
                            error={errors.email?.message}
                          />
                        </LabelGroup>

                        <LabelGroup
                          column
                          mb={2}
                          htmlFor="password"
                          label="Password"
                          size={12}
                          required
                        >
                          <TextInput
                            controlName="password"
                            type="password"
                            className={`form-control ${errors.email ? 'error--input' : ''}`}
                            placeholder={translate('Enter password')}
                            required
                            error={errors.password?.message}
                          />
                        </LabelGroup>

                        <div className="mt-3 d-grid">
                          <Button
                            className="btn btn-block"
                            style={{ backgroundColor: '#344792', color: '#FFF' }}
                            type="submit"
                            disabled={isSending}
                          >
                            {isSending ? (
                              <i className="bx bx-loader bx-spin" />
                            ) : (
                              translate('Log In')
                            )}
                          </Button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            {translate('Forgot your password?')}
                          </Link>
                        </div>
                      </form>
                    </FormProvider>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {`${translate("Don't have an account?")} `}
                  <Link to="/register" className="fw-medium text-primary">
                    {` ${translate('Signup now')} `}
                  </Link>{' '}
                </p>
                <p>
                  © {new Date().getFullYear()} {translate('Ecto Digital')}.
                  {` ${translate('Created with')} `}
                  <i className="mdi mdi-heart text-danger" />
                  {` ${translate('in Curitiba')}`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
