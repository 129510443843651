import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from 'reactstrap';
import { parse } from 'json2csv';
import axios from 'axios';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useAuth } from 'hooks/useAuth';
import { ButtonDownloadStyles } from './styles';

const ButtonDownloadCSV = ({ chartData, disabled }) => {
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const accountId = user.account;
  const { t: translate } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [allDataChecked, setAllDataChecked] = useState(false);
  const [chartsChecked, setChartsChecked] = useState(false);
  const [keywordsChecked, setKeywordsChecked] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleAllDataChange = () => {
    setAllDataChecked(!allDataChecked);

    if (!allDataChecked) {
      setChartsChecked(false);
      setKeywordsChecked(false);
    }
  };

  const exportPerformanceDataToCSV = async () => {
    try {
      const firebaseToken = await firebaseHelper.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getKeywords`,
        {
          accountId: accountId,
          development: process.env.REACT_APP_BUILD_TYPE !== 'production',
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );

      const dataToExport = response.data.byKeyword.map((item) => ({
        keyword: item.name,
        averagePosition: item.averagePosition,
        groups: item.groupNames,
        ...item.dates.reduce((acc, date) => {
          acc[date.week] = date.bestPlace;
          return acc;
        }, {}),
      }));

      const csv = parse(dataToExport);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'performance_data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const exportChartDataToCSV = () => {
    try {
      const { weeks, datasets } = chartData;

      const header = ['Weeks', ...weeks];

      const dataRows = datasets.map((dataset) => {
        const row = [dataset.label];
        dataset.data.forEach((dataPoint) => {
          row.push(dataPoint || '');
        });
        return row;
      });

      const csv = [header, ...dataRows].map((row) => row.join(',')).join('\n');

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'chart_data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadCSV = async (e) => {
    e.stopPropagation();

    try {
      setIsSending(true);
      setDropdownOpen(true);

      if (allDataChecked) {
        await exportPerformanceDataToCSV();
        exportChartDataToCSV();
      } else if (chartsChecked) {
        exportChartDataToCSV();
      } else if (keywordsChecked) {
        await exportPerformanceDataToCSV();
      }

      setIsSending(false);
      setDropdownOpen(false);
    } catch (error) {
      setIsSending(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (chartsChecked && keywordsChecked) {
      setAllDataChecked(true);
      setChartsChecked(false);
      setKeywordsChecked(false);
    }
  }, [chartsChecked, keywordsChecked]);

  useEffect(() => {
    if (!dropdownOpen && !isSending) {
      setAllDataChecked(false);
      setChartsChecked(false);
      setKeywordsChecked(false);
    }
  }, [dropdownOpen, isSending]);

  return (
    <ButtonDownloadStyles>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className={dropdownOpen ? 'btn-open' : ''} disabled={disabled}>
          <i className="bx bx-download" />
          {translate('Export CSV')}
        </DropdownToggle>
        <DropdownMenu right className="mt-1 p-0 pt-3">
          <span className="px-3">{translate('Export CSV')}</span>
          <FormGroup check>
            <div className="d-flex gap-2 px-3 pt-2">
              <Input
                id="all-data"
                type="checkbox"
                onChange={handleAllDataChange}
                checked={allDataChecked}
              />
              <Label className="m-0" check htmlFor="all-data">
                {translate('All data')}
              </Label>
            </div>
            <div className="d-flex gap-2 px-3">
              <Input
                id="charts"
                type="checkbox"
                onChange={() => setChartsChecked(!chartsChecked)}
                checked={chartsChecked}
                disabled={allDataChecked}
              />
              <Label className="m-0" check htmlFor="charts">
                {translate('Charts')}
              </Label>
            </div>
            <div className="d-flex gap-2 px-3">
              <Input
                id="keywords"
                type="checkbox"
                onChange={() => setKeywordsChecked(!keywordsChecked)}
                checked={keywordsChecked}
                disabled={allDataChecked}
              />
              <Label className="m-0" check htmlFor="keywords">
                {translate('Keywords')}
              </Label>
            </div>
          </FormGroup>
          <div className="dropdown-footer mt-3">
            <div className="d-flex gap-2 justify-content-end">
              <button onClick={() => setDropdownOpen(false)}>{translate('Cancel')}</button>
              <button
                className="btn-download"
                onClick={handleDownloadCSV}
                disabled={isSending}
                style={{ width: '6rem' }}
              >
                {isSending ? (
                  <i
                    className="bx bx-loader-alt bx-spin"
                    style={{ color: '#ffffff', fontSize: '1rem' }}
                  />
                ) : (
                  translate('Download')
                )}
              </button>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </ButtonDownloadStyles>
  );
};

ButtonDownloadCSV.propTypes = {
  chartData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ButtonDownloadCSV;
