import React, { useEffect, useRef, useState } from 'react';
import { getDatabase, ref, update, onValue, off } from 'firebase/database';
import { MetaTags } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { Button, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import { MoonLoader } from 'react-spinners';
import 'boxicons';
import axios from 'axios';

import Breadcrumbs from '../../components/Breadcrumb';
import Shimmer from '../../components/Shimmer';
import CardAssignedTo from './components/Card-Assigned-To';
import ApprovalChat from './components/Approval-Chat';
import PageLoading from './components/Page-Loading';
import TableResumeReview from './components/Table-Resume-Review';
import ContainerEditContent from './components/Container-Edit-Content';
import { SendCardLayout } from './components/Send-Card/styles';
import { GuidelineLayout } from './styles';
import PerformanceData from './components/Performance-Data';
import TitleGuideline from './components/Title-Guideline';

const PreReviewContent = () => {
  const firebaseHelper = getFirebaseBackend();

  const { id } = useParams();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const currentAccountId = user?.account;

  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [activeNav, setActiveNav] = useState('1');
  const [teamData, setTeamData] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const loadedGuideline = await firebaseHelper.getFirebaseGuideline(currentAccountId, id);

    if (loadedGuideline.column !== 'PRE_REVIEW') {
      navigate(`/guideline/${id}`);
    }

    if (loadedGuideline?.assignedTo) {
      const formattedAssignedTo = await Promise.all(
        loadedGuideline.assignedTo.map((userId) => {
          return firebaseHelper
            .getUserName(userId)
            .then((userName) => ({ value: userId, label: userName }));
        }),
      );
      setAssignedTo(formattedAssignedTo);
    }

    setIsProcessing(loadedGuideline.processing);
    setIsError(loadedGuideline?.error);
    setContent(loadedGuideline);
    setIsLoading(false);
  };

  const onSubmit = async () => {
    try {
      setIsSending(true);
      await firebaseHelper.createContentByStructure(currentAccountId, id);
      await firebaseHelper.sendGuidelineToContentReview(currentAccountId, id);

      navigate('/guidelines');
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountTeam = () => {
    return new Promise(async (resolve, reject) => {
      const team = await firebaseHelper.getTeam(currentAccountId);
      if (team) {
        const select = Object.entries(team)
          .filter(([memberId]) => memberId !== 'initialTeamState')
          .map(([, memberInfo]) => ({
            value: memberInfo.id,
            label: memberInfo.name,
          }));
        resolve(select);
      }
    });
  };

  const getInternalTeam = () => {
    return new Promise(async (resolve, reject) => {
      const ectoTeam = await firebaseHelper.getTeam(process.env.REACT_APP_ECTO_ID);
      const select = Object.entries(ectoTeam).map(([accountId, memberInfo]) => ({
        value: memberInfo.id,
        label: memberInfo.name,
      }));
      resolve(select);
    });
  };

  const combinedTeamData = () => {
    return new Promise(async (resolve, reject) => {
      const internalTeam = await getInternalTeam();
      const accountTeam = await getAccountTeam();
      const combinedTeamData = [...internalTeam, ...accountTeam];

      const uniqueTeamData = [
        ...new Map(combinedTeamData.map((item) => [item.value, item])).values(),
      ];
      resolve(
        uniqueTeamData.map((item) => ({
          value: item.value,
          label: item.label,
        })),
      );
    });
  };

  const updateAssignedTo = async () => {
    try {
      await firebaseHelper.setAssignedTo(
        currentAccountId,
        id,
        assignedTo.map((item) => item.value),
      );
    } catch (error) {
      console.log(error);
    }
  };

  async function handleRetry() {
    try {
      if (!id || !currentAccountId) throw new Error('Guideline ID or Account ID is missing');
      const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
      const firebaseToken = await firebaseHelper.getIdToken();
      setIsProcessing(true);
      setIsError(false);

      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${currentAccountId}/${id}`);
      await update(guidelineRef, {
        column: 'CONTENT_STRUCTURE',
      });
      await axios.post(
        `${process.env.REACT_APP_API_URL}/createContent`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: !isProduction,
          tracking: {
            day: 1,
            hour: 8,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );
    } catch (error) {
      setIsProcessing(false);
      setIsError(true);
      console.log(error);
    }
  }

  const isFirstUpdate = useRef(true);

  useEffect(() => {
    if (!isProcessing && !isError) return;

    const db = getDatabase();
    const guidelineRef = ref(db, `guidelines/${currentAccountId}/${id}`);

    const handleValueChange = (snapshot) => {
      const guidelineData = snapshot.val();

      if (!guidelineData) {
        navigate('/404');
        return;
      }

      if (guidelineData?.column !== 'PRE_REVIEW') {
        navigate(`/guideline/${id}`);
        return;
      }

      if (guidelineData?.processing === false || guidelineData?.error === false) {
        fetchData();
      }
    };

    onValue(guidelineRef, handleValueChange);

    return () => {
      off(guidelineRef, 'value', handleValueChange);
    };
  }, [isProcessing, isError]);

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    } else {
      updateAssignedTo();
    }
  }, [assignedTo]);

  useEffect(async () => {
    await combinedTeamData().then((data) => {
      setTeamData(data);
    });
  }, [currentAccountId]);

  useEffect(() => {
    fetchData();
  }, [user]);

  useEffect(() => {
    if (!isPageLoading) return;

    const db = getDatabase();
    const guidelineRef = ref(db, `guidelines/${currentAccountId}/${id}`);

    const handleValueChange = (snapshot) => {
      const data = snapshot.val();

      if (!data?.processing) {
        navigate(`/guideline/${id}`);
      }
    };

    onValue(guidelineRef, handleValueChange);

    return () => {
      off(guidelineRef, 'value', handleValueChange);
    };
  }, [isPageLoading]);

  return (
    <GuidelineLayout>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Pre-review content')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs
          title={translate('Content')}
          breadcrumbItem={translate('Pre-review content')}
        />

        {isError && (
          <PageLoading
            title={translate('An error occurred')}
            description={translate('There was an error processing this guideline.')}
            button={translate('Try Again')}
            onClick={handleRetry}
            buttonStyles={{ backgroundColor: '#F56565', color: '#fff' }}
          >
            <box-icon name="x-circle" color="#F56565" size="7rem" />
          </PageLoading>
        )}

        {isProcessing && !isError && (
          <PageLoading
            title={translate('This guideline is being processed')}
            description={translate(
              'This may take a few minutes, you can also wait for this process in the background and return later.',
            )}
            button={translate('Go back')}
            onClick={() => navigate('/guidelines')}
          />
        )}

        {!isProcessing && !isError && (
          <>
            {isPageLoading ? (
              <PageLoading
                title={translate('Recreating content')}
                description={translate(
                  'This may take a few minutes, you can also wait for this process in the background and return later.',
                )}
                button={translate('Go back')}
                onClick={() => navigate('/guidelines')}
              />
            ) : (
              <div className="container-row">
                <div className="container-general-information">
                  <div className="header-general-information">
                    <div>
                      <button
                        aria-label="Back"
                        type="button"
                        id="back-button"
                        className="back-button"
                        onClick={() => navigate('/guidelines')}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M5.21875 7.33117H13.3334V8.6645H5.21875L8.79475 12.2405L7.85208 13.1832L2.66675 7.99783L7.85208 2.8125L8.79475 3.75517L5.21875 7.33117Z"
                            fill="#2D3748"
                          />
                        </svg>
                      </button>
                      {content?.primaryKeyword && <TitleGuideline guideline={content} />}
                    </div>
                  </div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={activeNav === '1' ? 'actived' : ''}
                        onClick={() => setActiveNav('1')}
                      >
                        {translate('Summary')}
                      </NavLink>
                    </NavItem>
                    {!content?.googleDocId && (
                      <NavItem>
                        <NavLink
                          className={activeNav === '2' ? 'actived' : ''}
                          onClick={() => setActiveNav('2')}
                        >
                          {translate('Content')}
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        className={activeNav === '3' ? 'actived' : ''}
                        onClick={() => setActiveNav('3')}
                      >
                        {translate('Performance')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeNav}>
                    {isLoading ? (
                      <>
                        <Shimmer height="10em" style={{ marginBottom: '3em' }}></Shimmer>
                        <Shimmer height="60em"></Shimmer>
                      </>
                    ) : (
                      <TabPane tabId="1">
                        <TableResumeReview
                          data={content}
                          accountId={currentAccountId}
                          guidelineId={id}
                        />
                      </TabPane>
                    )}
                  </TabContent>
                  <TabContent activeTab={activeNav}>
                    <TabPane tabId="2">
                      <ContainerEditContent content={content} setIsPageLoading={setIsPageLoading} />
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={activeNav}>
                    <TabPane tabId="3">
                      <PerformanceData
                        primaryKeyword={content?.primaryKeyword}
                        secondaryKeywords={content?.secondaryKeywords}
                      />
                    </TabPane>
                  </TabContent>
                </div>
                <div className="container-side-column">
                  <SendCardLayout>
                    <p className="subtitle">{translate('Action')}</p>
                    <Button onClick={onSubmit}>
                      {isSending ? (
                        <MoonLoader color="#fff" size={20} />
                      ) : (
                        translate('Submit for review')
                      )}
                    </Button>
                  </SendCardLayout>
                  <CardAssignedTo
                    assignedTo={assignedTo}
                    setAssignedTo={setAssignedTo}
                    teamData={teamData}
                    title={translate('Reviewers')}
                    isMulti
                  />
                  <ApprovalChat />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </GuidelineLayout>
  );
};

export default PreReviewContent;
