import React, { useState } from 'react';
import { ButtonGroup, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ContainerLabelDateStyle } from './styles';
import { formatDate, formatDateInput } from '../../../../utils';
import { CalendarIcon } from 'assets/icons/Calendar';

const ContainerLabelDate = ({
  date,
  setDate,
  watch,
  setValue,
  getValues,
  setIsDatePickerOpen,
  inputRef,
  top,
  left = false,
  startDateName = 'startDate',
  endDateName = 'endDate',
  onApplyFilter,
}) => {
  const { t: translate } = useTranslation();
  const [selectedRadio, setSelectedRadio] = useState(null);

  const radioDates = [
    {
      label: translate('Last 7 days'),
      value: '7',
    },
    {
      label: translate('Last 30 days'),
      value: '30',
    },
    {
      label: translate('Last 90 days'),
      value: '90',
    },
    {
      label: translate('This week'),
      value: 'thisWeek',
    },
    {
      label: translate('This month'),
      value: 'thisMonth',
    },
    {
      label: translate('This year'),
      value: 'thisYear',
    },
  ];

  const onChangeRadioDates = (value) => {
    const currentDate = new Date();
    setValue(endDateName, currentDate);

    switch (value) {
      case '7':
        setValue(startDateName, new Date(currentDate - 7 * 24 * 60 * 60 * 1000));
        break;
      case '30':
        setValue(startDateName, new Date(currentDate - 30 * 24 * 60 * 60 * 1000));
        break;
      case '90':
        setValue(startDateName, new Date(currentDate - 90 * 24 * 60 * 60 * 1000));
        break;
      case 'thisWeek':
        setValue(
          startDateName,
          new Date(currentDate - (currentDate.getDay() - 1) * 24 * 60 * 60 * 1000),
        );
        break;
      case 'thisMonth':
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        setValue(startDateName, firstDayOfMonth);
        break;
      case 'thisYear':
        const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
        setValue(startDateName, firstDayOfYear);
        break;
      default:
        break;
    }

    const formattedDateRange = formatDateInput(
      getValues(startDateName),
      getValues(endDateName),
      '',
    );
    setDate(formattedDateRange);
    setSelectedRadio(value);
  };

  const onChangeCalendarDate = (dates) => {
    const [start, end] = dates;
    const formattedDateRange = formatDateInput(start, end, '');

    setValue(startDateName, start);
    setValue(endDateName, end);
    setDate(formattedDateRange);
    setSelectedRadio(null);
  };

  const handleCloseDrowdown = () => {
    setIsDatePickerOpen(false);
    if (inputRef && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 10);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onApplyFilter();
    }
  };

  return (
    <ContainerLabelDateStyle
      $left={left}
      $top={top}
      onMouseLeave={handleCloseDrowdown}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div className="container-menu-date">
        <div className="menu-date">
          <ButtonGroup className="radio-group">
            {radioDates.map((dateOption, index) => (
              <div
                key={index}
                className="radio-item"
                onClick={() => onChangeRadioDates(dateOption.value)}
              >
                <Input
                  id={dateOption.label}
                  type="radio"
                  name="radioDates"
                  value={dateOption.value}
                  checked={selectedRadio === dateOption.value}
                  onChange={() => onChangeRadioDates(dateOption.value)}
                />
                <label htmlFor={date.label} className="ms-2">
                  {translate(`${dateOption.label}`)}
                </label>
              </div>
            ))}
          </ButtonGroup>

          <DatePicker
            id="date"
            selected={watch(startDateName)}
            endDate={watch(endDateName)}
            startDate={watch(startDateName)}
            selectsRange
            inline
            locale={translate('en-US')}
            onChange={onChangeCalendarDate}
          />
        </div>
        <div className="date-selected">
          <CalendarIcon />
          {watch(startDateName) && watch(endDateName) && (
            <p>{formatDate(new Date(watch(startDateName)), translate('date-enUS'))}</p>
          )}
          <p>-</p>
          {watch(endDateName) && (
            <p>{formatDate(new Date(watch(endDateName)), translate('date-enUS'))}</p>
          )}
        </div>
      </div>
    </ContainerLabelDateStyle>
  );
};

ContainerLabelDate.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  setIsDatePickerOpen: PropTypes.func,
  inputRef: PropTypes.object,
  isChangeDate: PropTypes.func,
  top: PropTypes.string,
  left: PropTypes.bool,
  startDateName: PropTypes.string,
  endDateName: PropTypes.string,
  onApplyFilter: PropTypes.func,
};

export default ContainerLabelDate;
