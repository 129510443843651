import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledTooltip,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import MetaTags from 'react-meta-tags';
import { Link, useSearchParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import _ from 'lodash';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import ErrorModal from '../../components/Error-Modal';
import EmbeddedVideo from '../../components/Embedded-Video';
import Shimmer from '../../components/Shimmer';
import { formatDate } from '../../utils/';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import '../../assets/scss/custom/pages/_meetings.scss';

import { monthNumber } from '../../constants/monthNumber';
import styled from 'styled-components';

const ExternalSpan = styled.span`
  &.is-external {
    text-decoration: dotted underline;
    padding-top: 0.5em;
  }
`;

const Meeting = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user, changeAccount } = useAuth();
  const userId = user.id;
  const accountId = user.account;
  const { adminStatus } = user;
  const [searchParams, setSearchParams] = useSearchParams();
  const meetingParam = searchParams.get('id');
  const accountParam = searchParams.get('acc');
  const [meetingData, setMeetingData] = useState();
  const [meetingsList, setMeetingsList] = useState();
  const [loading, setLoading] = useState(true);
  const [errorModal, setErrorModal] = useState(false);

  useEffect(() => {
    if (!accountId) return;

    const db = getDatabase();
    setLoading(true);
    setMeetingData(null);

    let allowChangeAccount = false;
    if (accountParam && accountId !== accountParam && adminStatus === 'Super Admin') {
      changeAccount(userId, accountParam);
      allowChangeAccount = true;
    }

    const meetingPath = `meetings/${allowChangeAccount ? accountParam : accountId}/${meetingParam}`;
    const meetingsPath = `meetings/${allowChangeAccount ? accountParam : accountId}/`;

    const meetingRef = ref(db, meetingPath);
    const meetingsRef = ref(db, meetingsPath);

    const handleMeetingValueChange = async (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        setErrorModal(true);
        return;
      }
      const processedData = await processMeeting(data);
      setMeetingData(processedData);
      setLoading(false);
    };

    const handleMeetingsValueChange = async (snapshot) => {
      const data = snapshot.val();
      if (!data) return;
      const processedList = proccessList(data);
      setMeetingsList(processedList);
    };

    const unsubscribeMeeting = onValue(meetingRef, handleMeetingValueChange);
    const unsubscribeMeetings = onValue(meetingsRef, handleMeetingsValueChange);

    return () => {
      unsubscribeMeeting();
      unsubscribeMeetings();
    };
  }, [accountId, accountParam, meetingParam]);

  const proccessList = (data) => {
    let years = {};
    Object.entries(data)
      .sort((a, b) => {
        if (a[1].date > b[1].date) {
          return 1;
        }
        if (a[1].date < b[1].date) {
          return -1;
        }
        return 0;
      })
      .forEach(([meetingId, meetingData]) => {
        if (meetingData?.date) {
          const dateObj = new Date(meetingData.date);
          const year = dateObj.getFullYear();
          if (!years.hasOwnProperty(year)) {
            years[year] = {};
          }
          const month = dateObj.getMonth();
          if (!years[year].hasOwnProperty(month)) {
            years[year][month] = [];
          }
          years[year][month].push({
            id: meetingId,
            agenda: meetingData.agenda,
            date: meetingData.date,
          });
        } else {
          if (!years.hasOwnProperty('N/A')) {
            years['N/A'] = {
              13: [],
            };
          }
          years['N/A'][13].push({
            id: meetingId,
            agenda: meetingData.agenda,
          });
        }
      });
    return years;
  };

  const processMeeting = async (data) => {
    const partPromises = _.map(data.participants, async (userId) => {
      if (userId.indexOf('external:') !== -1) {
        return userId;
      }
      const participantName = await firebaseHelper.userName(userId);
      return participantName;
    });
    const participants = await Promise.all(partPromises);

    let relatedTasks;
    let steps;

    if (data?.relatedTasks) {
      const taskPromises = _.map(data.relatedTasks, async (taskId) => {
        const taskName = await firebaseHelper.taskName(accountId, taskId);
        return { name: taskName, id: taskId, acc: accountId };
      });
      relatedTasks = await Promise.all(taskPromises);
    } else {
      relatedTasks = null;
    }

    if (data?.steps) {
      const stepPromises = _.map(data.steps, async (step) => {
        const assignedTo = await firebaseHelper.userName(step.assignedTo);
        return { ...step, assignedTo, id: step.assignedTo };
      });
      steps = await Promise.all(stepPromises);
    } else {
      steps = null;
    }

    return {
      ...data,
      participants,
      relatedTasks,
      steps,
      participantIds: data.participants,
    };
  };

  const handleTab = (event, index) => {
    const allContents = document.querySelectorAll('.nav-content');
    const thisContent = document.querySelector(`.nav-content[tabIndex='${index}']`);

    const allTabs = document.querySelectorAll('.nav-link');
    const thisTab = event.target;

    allTabs.forEach((tab) => {
      tab.classList.remove('active-tab');
    });
    thisTab.classList.add('active-tab');
    allContents.forEach((content) => {
      content.classList.remove('active');
    });
    thisContent.classList.add('active');
  };

  function handleClick(event, accountId, meetingId) {
    event.preventDefault();
    setSearchParams({
      acc: accountId,
      id: meetingId,
    });
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ minHeight: '100vh' }}>
        <MetaTags>
          <title>{translate('Meeting')} | Ectools</title>
        </MetaTags>

        <Container fluid>
          <Row>
            <Col xs="12" className="mb-4">
              <h4 className="mb-0 font-size-18">{translate('Meeting')}</h4>
            </Col>
          </Row>

          <Row>
            <Col lg="9">
              <Card>
                <ErrorModal isOpen={errorModal} />

                <CardBody style={{ padding: '2em' }}>
                  <section className="meeting-header">
                    {adminStatus === 'Super Admin' || adminStatus === 'Admin' ? (
                      <Link
                        to={`/edit-meeting?id=${meetingData?.id}`}
                        className="d-flex justify-content-center align-items-center mt-2"
                        style={{
                          position: 'absolute',
                          right: '5%',
                          border: '0',
                          backgroundColor: 'transparent',
                          padding: '0',
                          gap: '.3em',
                        }}
                      >
                        <box-icon
                          type="regular"
                          name="edit-alt"
                          size="1.5rem"
                          color="#7367F0"
                        ></box-icon>
                        <span
                          style={{
                            fontSize: '1rem',
                            color: '#7367F0',
                            fontWeight: '500',
                          }}
                        >
                          {translate('Edit')}
                        </span>
                      </Link>
                    ) : null}
                    {loading ? (
                      <Shimmer width="30%" style={{ marginBottom: '.5em' }}></Shimmer>
                    ) : (
                      <h4 className="mtheader-title">{meetingData?.agenda}</h4>
                    )}
                    <div className="mtheader-info">
                      {loading ? (
                        <Shimmer width="10%" style={{ marginBottom: '.5em' }}></Shimmer>
                      ) : (
                        <div>
                          <box-icon
                            type="regular"
                            name="calendar-alt"
                            size="1.2rem"
                            color="#484848"
                          ></box-icon>
                          <span>
                            {meetingData?.date
                              ? formatDate(new Date(meetingData?.date), translate('date-enUS'))
                              : 'N/A'}
                          </span>
                        </div>
                      )}
                      {loading ? (
                        <Shimmer width="15%" style={{ marginBottom: '.5em' }}></Shimmer>
                      ) : (
                        <div>
                          <box-icon
                            type="regular"
                            name="time"
                            size="1.2rem"
                            color="#484848"
                          ></box-icon>
                          <span>{`${meetingData?.minutes} ${translate('minutes')}`}</span>
                        </div>
                      )}
                    </div>
                    <div className="mtheader-participants">
                      {loading ? (
                        <Shimmer width="25%" style={{ marginBottom: '.5em' }}></Shimmer>
                      ) : (
                        <>
                          <box-icon
                            type="regular"
                            name="user-voice"
                            size="1.2rem"
                            color="#484848"
                          ></box-icon>
                          <span>
                            {_.map(meetingData?.participants, (participant, index) => {
                              const isExternal = participant.indexOf('external:') !== -1;
                              let item = isExternal
                                ? participant.replace('external:', '')
                                : participant;
                              const lastItem = meetingData?.participants?.length - 1;

                              if (index !== lastItem) item += ', ';

                              return (
                                <>
                                  <ExternalSpan
                                    id={`name${index + 1}`}
                                    key={participant}
                                    className={isExternal && 'is-external'}
                                  >
                                    {item}
                                    {isExternal && (
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={`#name${index + 1}`}
                                      >
                                        {translate('External user')}
                                      </UncontrolledTooltip>
                                    )}
                                  </ExternalSpan>
                                </>
                              );
                            })}
                          </span>
                        </>
                      )}
                    </div>
                  </section>
                  <hr />
                  {loading ? (
                    <Shimmer width="100%" height="10em" style={{ marginBottom: '.5em' }}></Shimmer>
                  ) : (
                    <section className="meeting-description">
                      {meetingData?.videoLink ? (
                        <EmbeddedVideo link={meetingData.videoLink} />
                      ) : null}

                      <MDEditor.Markdown
                        source={meetingData?.description}
                        rehypePlugins={[[rehypeSanitize]]}
                        linkTarget="_blank"
                      />
                    </section>
                  )}
                  {meetingData?.steps ? (
                    <section className="meeting-next-steps">
                      <h5 className="mtsteps-title">{translate('Next Steps')}</h5>
                      <table className="mtsteps-table">
                        <thead>
                          <tr key="head">
                            <th data-count>#</th>
                            <th data-member>{translate('Assigned To One')}</th>
                            <th data-text>{translate('Action')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(meetingData?.steps, ({ assignedTo, step, id }, index) => (
                            <tr key={`${index}-${assignedTo}`}>
                              <td data-count>{index + 1}</td>
                              <td data-member>{assignedTo}</td>
                              <td data-text>{step}</td>
                              <td data-link>
                                <Link to={`/tasks-create?name=${step}&assign=${id}`}>
                                  {translate('Convert to task')}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </section>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <section className="meeting-related-tasks">
                    <h5 className="mttask-title">{translate('Related Tasks')}</h5>
                    {meetingData?.relatedTasks ? (
                      <ul className="mttask-list">
                        {_.map(meetingData?.relatedTasks, ({ name, id, acc }) => (
                          <li key={name}>
                            <box-icon
                              type="regular"
                              name="chevron-right"
                              size="1.2rem"
                              color="#74788D"
                            ></box-icon>
                            <Link to={`/tasks?id=${id}&acc=${acc}`}>{name}</Link>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span className="mttask-message">{translate('None')}</span>
                    )}
                  </section>
                  <hr />
                  <section className="meeting-related-tasks">
                    <h5 className="mttask-title">{translate('Logged Meetings')}</h5>
                    {meetingsList ? (
                      <>
                        <Nav tabs>
                          {Object.keys(meetingsList).map((year, index) => (
                            <NavItem key={`${year}-${index}`}>
                              <NavLink
                                className={`nav-link ${index === 0 ? 'active-tab' : ''}`}
                                onClick={(event) => handleTab(event, index + 1)}
                              >
                                {year}
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                        <TabContent activeTab="1">
                          {Object.keys(meetingsList).map((tab, tabIndex) => (
                            <TabPane
                              className={`nav-content ${tabIndex === 0 ? 'active' : ''}`}
                              key={`pane-${tabIndex + 1}`}
                              tabId={tabIndex + 1}
                              tabIndex={tabIndex + 1}
                            >
                              {Object.entries(meetingsList[tab]).map(([month, meetings]) => {
                                if (month == 13) {
                                  return (
                                    <>
                                      {meetings.map((meeting) => (
                                        <div key={meeting.id} className="detail-meeting">
                                          <button
                                            className="detail-link"
                                            onClick={(e) => handleClick(e, accountId, meeting.id)}
                                          >
                                            {meeting.agenda}
                                          </button>
                                          <div
                                            className="d-flex align-items-center mt-1"
                                            style={{
                                              lineHeight: '1rem',
                                              gap: '.5em',
                                            }}
                                          >
                                            <box-icon
                                              type="regular"
                                              name="calendar-alt"
                                              color="#7B919E"
                                              size="1rem"
                                            ></box-icon>
                                            <span style={{ fontSize: '.8rem' }}>N/A</span>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  );
                                } else {
                                  return (
                                    <details className="month-detail" key={month}>
                                      <summary className="month-summary">
                                        {translate(monthNumber[month])}
                                      </summary>
                                      {meetings.map((meeting) => (
                                        <div key={meeting.id} className="detail-meeting">
                                          <button
                                            className="detail-link"
                                            onClick={(e) => handleClick(e, accountId, meeting.id)}
                                          >
                                            {meeting.agenda}
                                          </button>
                                          <div
                                            className="d-flex align-items-center mt-1"
                                            style={{
                                              lineHeight: '1rem',
                                              gap: '.5em',
                                            }}
                                          >
                                            <box-icon
                                              type="regular"
                                              name="calendar-alt"
                                              color="#7B919E"
                                              size="1rem"
                                            ></box-icon>
                                            <span style={{ fontSize: '.8rem' }}>
                                              {formatDate(
                                                new Date(meeting.date),
                                                translate('date-enUS'),
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                    </details>
                                  );
                                }
                              })}
                            </TabPane>
                          ))}
                        </TabContent>
                      </>
                    ) : null}
                  </section>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Meeting;
