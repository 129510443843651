import React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import PropTypes from 'prop-types';

const Badge = ({ children, variant }) => {
  if (variant === 'active') {
    return <ActiveBadge />;
  }

  if (variant === 'inactive') {
    return <InactiveBadge />;
  }

  return <Styles.BaseBadge>{children}</Styles.BaseBadge>;
};

Badge.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['active', 'inactive']),
};

const ActiveBadge = () => {
  const { t: translate } = useTranslation();
  return (
    <Styles.ActiveBadge>
      {translate('Active', {
        context: 'female',
      })}
    </Styles.ActiveBadge>
  );
};

const InactiveBadge = () => {
  const { t: translate } = useTranslation();
  return (
    <Styles.InactiveBadge>
      {translate('Inactive', {
        context: 'female',
      })}
    </Styles.InactiveBadge>
  );
};

Badge.Active = ActiveBadge;
Badge.Inactive = InactiveBadge;

export default Badge;
