import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import _ from 'lodash';
import 'boxicons';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import PageStructure from 'components/Page-Structure';
import ShowComponent from 'components/Show-Component';
import Shimmer from 'components/Shimmer';
import ErrorModal from 'components/Error-Modal';
import ModulesTable from './components/ModulesTable';

import { BreadCrumbBtn, CourseLayout } from './styles';
import UserAvatar from 'components/UserAvatar';

const CourseHome = () => {
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const { t: translate } = useTranslation();
  const [searchParams] = useSearchParams();
  const accountParam = searchParams.get('acc');
  const courseParam = searchParams.get('id');
  const [course, setCourse] = useState(null);
  const [library, setLibrary] = useState([]);
  const [hasBanner, setHasBanner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const bannerImageRef = useRef(null);

  const fetchCourse = async () => {
    try {
      const courseObj = await firebaseHelper.getFirebaseCourse(accountParam, courseParam);
      if (!courseObj) throw new Error("This course wasn't found");
      courseObj.teacherData = await Promise.all(
        courseObj.teachers.map(async (teacherId) => {
          const userObj = await firebaseHelper.getUser(teacherId);
          return { name: userObj?.name, description: userObj?.description };
        }),
      );

      setCourse(courseObj);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setErrorModal(true);
    }
  };

  const fetchLibrary = async () => {
    const libraryObj = await firebaseHelper.getFirebaseLibraryCourse(user.id, courseParam);

    setLibrary(libraryObj);
  };

  const fetchCourseBanner = async () => {
    const path = `${accountParam}/courses/${courseParam}`;
    const bannerMeta = await firebaseHelper.listAllFiles(path);
    if (bannerMeta.length) {
      setHasBanner(true);
      const bannerFile = await firebaseHelper.getFileObj(`${path}/${bannerMeta[0].name}`);
      const bannerURL = URL.createObjectURL(bannerFile);
      if (bannerImageRef.current)
        bannerImageRef.current.style.backgroundImage = `url('${bannerURL}')`;
    } else {
      setHasBanner(false);
    }
  };

  const handleLibrary = async (event) => {
    event.preventDefault();

    await firebaseHelper.setFirebaseLibraryCourse(user.id, courseParam, {
      account: accountParam,
      status: 'owned',
      lastLesson: null,
      progress: null,
      tests: null,
    });
    fetchLibrary();
  };

  const handleTrial = async (event) => {
    event.preventDefault();

    await firebaseHelper.setFirebaseLibraryCourse(user.id, courseParam, {
      account: accountParam,
      status: 'trial',
      lastLesson: null,
      progress: null,
      tests: null,
    });
    fetchLibrary();
  };

  useEffect(() => {
    if (!accountParam || !courseParam) return;

    fetchCourse();
    fetchLibrary();
    fetchCourseBanner();
  }, [accountParam, courseParam]);

  return (
    <PageStructure metaTitle="Courses">
      <div className="d-flex justify-content-between">
        <h4 className="mb-0 font-size-18">{translate('Courses')}</h4>

        <BreadCrumbBtn to="/library">
          <box-icon name="bookmark-heart" size="1.2rem" color="#50A5F1" />
          {translate('Go to My Library')}
        </BreadCrumbBtn>
      </div>

      <ErrorModal isOpen={errorModal} />

      <CourseLayout>
        <ShowComponent condition={loading}>
          <Shimmer height="30rem" />
          <Shimmer height="10rem" />
        </ShowComponent>

        <ShowComponent condition={!loading}>
          <Card className="course-home-main">
            <h1>{course?.name}</h1>

            <h2>{translate('Teachers')}</h2>
            {_.isArray(course?.teacherData) &&
              course.teacherData.map((teacher, index) => {
                return (
                  <div className="teacher-element" key={course.teachers[index]}>
                    <div>
                      <UserAvatar userId={course.teachers[index]} />
                    </div>

                    <div>
                      <h3>{teacher.name}</h3>
                      <div>{teacher.description}</div>
                    </div>
                  </div>
                );
              })}

            <h2>{translate('About the course')}</h2>
            <MDEditor.Markdown
              source={course?.description}
              rehypePlugins={[[rehypeSanitize]]}
              linkTarget="_blank"
            />

            <h2>{translate('Course Content')}</h2>
            <ModulesTable data={course?.modules} library={library} />
          </Card>

          <div>
            <Card className="course-home-interaction">
              <div>
                {hasBanner === false && <box-icon name="book-open" color="#E7E7E7" size="6rem" />}
                <div className={hasBanner ? 'has-banner' : ''} ref={bannerImageRef}></div>
              </div>

              <div>
                {library?.status === 'owned' && (
                  <div className="course-status">
                    <box-icon name="check-double" color="#50A5F1" size="1.5rem" />
                    {translate('Course in Library')}
                  </div>
                )}
                {library?.status === 'trial' && (
                  <div className="course-status">
                    <box-icon name="timer" color="#50A5F1" size="1.5rem" />
                    {translate('Free Trial Activated')}
                  </div>
                )}
                {!library?.status && (
                  <>
                    <Button
                      onClick={handleLibrary}
                      style={{
                        backgroundColor: '#34C38F',
                        borderColor: '#34C38F',
                      }}
                    >
                      {translate('Add to library')}
                    </Button>
                    {course?.freeTrial && (
                      <Button
                        onClick={handleTrial}
                        style={{
                          backgroundColor: '#7367F0',
                          borderColor: '#7367F0',
                        }}
                      >
                        {translate('Course Free Trial')}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </Card>
          </div>
        </ShowComponent>
      </CourseLayout>
    </PageStructure>
  );
};

CourseHome.displayName = 'Course Page';

export default CourseHome;
