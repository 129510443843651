import styled from 'styled-components';

const BaseBadge = styled.div`
  display: flex;
  height: 24px;
  padding: 2px 8px 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: #dcdcdc;
  color: #aaa;
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #aaaaaa;
  }

  span {
    text-align: center;
    /* text-xs/lineHeight-4/font-medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`;

const InactiveBadge = styled(BaseBadge)``;

const ActiveBadge = styled(BaseBadge)`
  border: 1px solid var(--Sucess, #34c38f);
  background: #34c38f;
  color: #fff;
  &:before {
    background: #fff;
  }
`;

const Styles = {
  BaseBadge,
  InactiveBadge,
  ActiveBadge,
};

export default Styles;
