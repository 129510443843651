import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, Alert, Card, CardBody, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { useAuth } from 'hooks/useAuth';
import logo from '../../assets/images/small-logo.svg';
import LanguageDropdown from '../../components/Language-Dropdown';
import LabelGroup from 'components/Label-Group';
import TextInput from 'components/Text-Input';

const ForgetPasswordPage = () => {
  const formConfig = {
    defaultValues: {
      email: '',
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };
  const form = useForm(formConfig);
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = form;
  const { forgetPassword } = useAuth();
  const { t: translate } = useTranslation();
  const [showForm, setShowForm] = useState(true);
  const [success, setSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleValidSubmit = async (data) => {
    setIsSending(true);
    clearErrors();

    try {
      await forgetPassword(data.email);

      setShowForm(false);
      setSuccess(true);
    } catch (error) {
      if (error.code) {
        switch (error.code) {
          case 'auth/too-many-requests':
            setError('email', {
              type: 'manual',
              message: translate('Too many requests, try again later'),
            });
            break;
          case 'auth/invalid-email':
            setError('email', {
              type: 'manual',
              message: translate('Invalid email'),
            });
            break;
          default:
            setError('submit', {
              type: 'manual',
              message: translate('An unexpected error occurred'),
            });
        }
      } else {
        setError('submit', {
          type: 'manual',
          message: error.message || translate('Unknown error'),
        });
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{translate('Reset Password')} | Ectools</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(242, 242, 242, 0.22)' }}>
                  <Row>
                    <Col xs={9}>
                      <div className="text-primary p-4">
                        <h5 style={{ color: '#344792' }}>{translate('Reset Password')}</h5>
                        <p style={{ color: '#344792' }}>
                          {translate('Recover your access to the Dashboard.')}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-3" style={{ paddingLeft: '8%' }}>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img src={logo} alt={translate("Ectools' Logo")} height="36" />
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {errors.email && (
                      <Alert
                        color="danger"
                        style={{
                          marginTop: '13px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {translate(errors.email.message)}
                      </Alert>
                    )}

                    {errors.submit && (
                      <Alert
                        color="danger"
                        style={{
                          marginTop: '13px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {translate(errors.submit.message)}
                      </Alert>
                    )}

                    {showForm && Object.keys(errors).length === 0 && (
                      <div
                        className="alert alert-success"
                        style={{ display: 'flex', justifyContent: 'center' }}
                        id="instruction"
                      >
                        {translate('Enter your email and instructions will be sent.')}
                      </div>
                    )}

                    {success && (
                      <Alert
                        color="success"
                        style={{
                          marginTop: '13px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {translate('Check the instructions in your email.')}
                      </Alert>
                    )}

                    <FormProvider {...form}>
                      <form
                        className="form-horizontal w-100"
                        onSubmit={handleSubmit(handleValidSubmit)}
                      >
                        {showForm && (
                          <LabelGroup
                            column
                            mb={2}
                            htmlFor="email"
                            label="Email"
                            size={12}
                            required
                            id="email-field"
                          >
                            <TextInput
                              controlName="email"
                              className="form-control"
                              placeholder={translate('Enter email')}
                              type="email"
                              required
                            />
                          </LabelGroup>
                        )}

                        <Row className="mb-3">
                          <Col id="button-box">
                            {!showForm && (
                              <Link to="/login" id="back-button">
                                <Button
                                  className="btn w-md"
                                  style={{
                                    backgroundColor: '#344792',
                                    color: '#FFF',
                                    width: '100%',
                                  }}
                                  type="submit"
                                  id="back-button"
                                >
                                  {translate('Go back to Login')}
                                </Button>
                              </Link>
                            )}

                            {showForm && (
                              <Button
                                className="btn w-100 mt-2"
                                style={{
                                  backgroundColor: '#344792',
                                  color: '#FFF',
                                }}
                                type="submit"
                                id="send-button"
                              >
                                {isSending ? (
                                  <i className="bx bx-loader bx-spin" />
                                ) : (
                                  translate('Send')
                                )}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </form>
                    </FormProvider>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {translate('Ecto Digital')}.
                  {` ${translate('Created with')} `}
                  <i className="mdi mdi-heart text-danger" />
                  {` ${translate('in Curitiba')}`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordPage;
