import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Button, Row, Col, Card, CardBody } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import { normalizeString } from '../../utils/';
import '../../assets/scss/datatables.scss';
import '../../assets/scss/custom/pages/_users.scss';
import UserAvatar from 'components/UserAvatar';

const ListUsers = () => {
  const { t: translate } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [users, setUsers] = useState([]);
  const firebaseHelper = getFirebaseBackend();

  const statusCustomization = {
    Pending: (
      <p style={{ color: 'rgba(241, 180, 76, 1)', margin: '0 0' }}>{translate('Pending')}</p>
    ),
    Activated: (
      <p style={{ color: 'rgba(52, 195, 143, 1)', margin: '0 0' }}>{translate('Activated')}</p>
    ),
    Deactivated: (
      <p style={{ color: 'rgba(244, 106, 106, 1)', margin: '0 0' }}>{translate('Deactivated')}</p>
    ),
  };

  const actionButton = ({ id }) => (
    <div style={{ display: 'flex' }}>
      <Link to={`/edit-user?id=${id}`}>
        <Button style={{ border: 'none', backgroundColor: '#E0E0E0' }}>
          <i className="bx bx-pencil" style={{ color: '#495057' }}></i>{' '}
          <span style={{ color: 'rgba(106, 113, 135, 1)' }}>{translate('Edit')}</span>
        </Button>
      </Link>
    </div>
  );

  const photoElem = ({ name, id }) => {
    return <UserAvatar userId={id} name={name} />;
  };

  const fetchUsers = async () => {
    const usersObj = await firebaseHelper.getAllUsers();
    setUsers(usersObj);
  };

  const processUsers = async () => {
    const activeUsers = Object.values(users).filter((user) => user?.status === 'Activated');
    const processedUsers = await Promise.all(
      activeUsers.map(async (userInfo) => {
        const account = await firebaseHelper.accountName(userInfo.account);

        return {
          photo: photoElem(userInfo),
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          account,
          type: translate(userInfo.adminStatus),
          status: statusCustomization[normalizeString(userInfo.status)],
          action: actionButton(userInfo),
        };
      }),
    );
    setUserData(processedUsers);
  };

  useEffect(() => {
    if (users === null) return setUserData([]);

    processUsers();
  }, [users]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const pendingTotal = (acc, cur) => (cur.status === 'Pending' ? ++acc : acc);

  const columns = [
    {
      dataField: 'photo',
      text: '',
      sort: false,
    },
    {
      dataField: 'name',
      text: translate('Name'),
      sort: true,
    },
    {
      dataField: 'email',
      text: translate('Email'),
      sort: true,
    },
    {
      dataField: 'account',
      text: translate('Account'),
      sort: true,
    },
    {
      dataField: 'type',
      text: translate('Type'),
      sort: true,
    },
    {
      dataField: 'status',
      text: translate('Status'),
      sort: true,
    },
    {
      dataField: 'action',
      text: translate('Action'),
      sort: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'asc',
    },
  ];

  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: userData.length },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: userData.length,
    custom: true,
    sizePerPageList,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content" style={{ minHeight: '100vh' }}>
        <MetaTags>
          <title>{translate('List Users')} | Ectools</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12" className="mb-4">
              <h4 className="mb-0 font-size-18">
                {translate('Users')} ({users !== null ? Object.values(users).length : 0})
              </h4>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={userData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider keyField="id" columns={columns} data={userData} search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2 d-flex justify-content-between">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={translate('Search')}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="8" className="d-flex flex-row-reverse align-items-center">
                                <Link to="/create-user">
                                  <Button
                                    style={{
                                      backgroundColor: 'rgba(85, 110, 230, 1)',
                                      borderColor: 'rgba(85, 110, 230, 1)',
                                    }}
                                  >
                                    <i className="bx bx-plus-circle"></i>{' '}
                                    {translate('Create new User')}
                                  </Button>
                                </Link>
                                <Link to="/list-pending">
                                  <p className="mx-4 mb-0" style={{ color: '#ADB5BD' }}>
                                    {translate('Pending Users')} (
                                    {users !== null
                                      ? Object.values(users).reduce(pendingTotal, 0)
                                      : 0}
                                    )
                                  </p>
                                </Link>
                                <Link to="/list-deactivated">
                                  <p className="mx-4 mb-0" style={{ color: '#ADB5BD' }}>
                                    {translate('Deactivated Users')}
                                  </p>
                                </Link>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    hover
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListUsers;
