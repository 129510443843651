import styled from 'styled-components';

const OptionWrapper = styled.div`
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  span {
    flex: 1;
  }
`;

const Styles = {
  OptionWrapper,
};

export default Styles;
