import styled from 'styled-components';

export const GuidelinesFilterLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  form {
    margin: 0 !important;
  }

  .filter-menu > div {
    display: flex;
    justify-content: flex-start;
  }

  .menu-inline {
    display: flex;
    gap: 1.5rem;
  }

  .menu-column {
    display: flex;
    flex-direction: column;
  }

  .filter-label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #4a5568;
  }

  #input-date {
    cursor: pointer;
    caret-color: transparent;
  }

  .filter-btn {
    display: flex;
    gap: 8px;
    padding: 0;

    button {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      border: none;
      height: 39.5px;
    }

    .filter-btn-apply {
      background-color: #556ee6 !important;
    }

    .filter-btn-apply:hover {
      background-color: #3b5bfc !important;
    }

    .filter-btn-clear {
      background-color: #d69e2e !important;
    }

    .filter-btn-clear:hover {
      background-color: #b7791f !important;
    }

    .filter-btn-back {
      background-color: #f56565 !important;
    }
    .filter-btn-back:hover {
      background-color: #c53030 !important;
    }
  }
`;
