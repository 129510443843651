import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

const ModalCompleteReview = ({ isOpen, toggle, submitApproval }) => {
  const { t: translate } = useTranslation();

  return (
    <ModalStyled isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{translate('Send to approval')}</ModalHeader>
      <ModalBody>
        <p>
          {translate(
            'When sending for approval, you will be sending the document for customer access, are you sure you want to send it?',
          )}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} className="btn-cancel">
          {translate('Cancel')}
        </Button>
        <Button onClick={submitApproval} className="btn-send">
          {`${translate('Yes')}, ${translate('Send').toLowerCase()}`}
        </Button>
      </ModalFooter>
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)`
  width: 23.1rem;

  .btn-cancel {
    border-radius: 0.25rem;
    border: 1px solid #e2e8f0;
    background-color: transparent;
    color: #343a40 !important;

    &:hover {
      background-color: #e2e8f0;
    }
  }

  .btn-send {
    border: none;
    background-color: #34c38f;

    &:hover {
      background-color: #25855a;
    }
  }
`;

ModalCompleteReview.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  submitApproval: PropTypes.func,
};

export default ModalCompleteReview;
