import i18n from 'i18n';

const countrySelectOptions = [
  {
    value: 'br',
    label: i18n.t('Brazil'),
  },
  {
    value: 'ar',
    label: i18n.t('Argentina'),
  },
  {
    value: 'us',
    label: i18n.t('United States'),
  },
  {
    value: 'fr',
    label: i18n.t('France'),
  },
  {
    value: 'it',
    label: i18n.t('Italy'),
  },
  {
    value: 'pt',
    label: i18n.t('Portugal'),
  },
  {
    value: 'cl',
    label: i18n.t('Chile'),
  },
  {
    value: 'co',
    label: i18n.t('Colombia'),
  },
  {
    value: 'mx',
    label: i18n.t('Mexico'),
  },
  {
    value: 'es',
    label: i18n.t('Spain'),
  },
  {
    value: 'gb',
    label: i18n.t('United Kingdom'),
  },
  {
    value: 'nl',
    label: i18n.t('Netherlands'),
  },
];

const languageSelectOptions = [
  {
    value: 'pt-br',
    label: i18n.t('Portuguese (Brazil)'),
  },
  {
    value: 'es-419',
    label: i18n.t('Spanish (Latin America)'),
  },
  {
    value: 'us-en',
    label: i18n.t('English'),
  },
  {
    value: 'fr',
    label: i18n.t('French (France)'),
  },
  {
    value: 'it',
    label: i18n.t('Italian (Italy)'),
  },
  {
    value: 'pt-pt',
    label: i18n.t('Portuguese (Portugal)'),
  },
  {
    value: 'nl',
    label: i18n.t('Dutch (Netherlands)'),
  },
];

export { countrySelectOptions, languageSelectOptions };
