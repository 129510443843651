import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';

import ImgNoData from 'assets/images/no-data.svg';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { KeywordMonitoringStyles } from './keywordMonitoringStyles';
import Shimmer from 'components/Shimmer';
import Breadcrumbs from './components/Breadcrumb';
import CardKeyword from './components/Card-Keyword';
import ButtonAddKeyword from './components/Button-Add-Keyword';
import PerformanceChart from './components/Performance-Chart';
import PerformanceTable from './components/Performance-Table';
import HeaderKeywordMonitoring from './components/Header-Keyword-Monitoring';
import DataPerformanceChart from './components/Data-Performance-Chart';

const ModalAddKeyword = lazy(() => import('./components/Modal-Add-Keyword'));

const KeywordMonitoring = () => {
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const accountId = user.account;
  const isAdminStatus = ['Super Admin', 'Admin'].includes(user.adminStatus);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPerformanceData = async (overrides = {}) => {
    setIsLoading(true);

    const activeFilters = { ...filters, ...overrides };

    const firebaseToken = await firebaseHelper.getIdToken();

    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page')) || 1;
    const limit = parseInt(query.get('limit')) || 10;

    const body = {
      accountId: accountId,
      development: process.env.REACT_APP_BUILD_TYPE !== 'production',
      pageSize: limit,
      page,
    };

    if (Object.keys(activeFilters).length > 0) {
      const filterValues = Object.fromEntries(
        Object.entries(activeFilters)
          .filter(([_, value]) => value !== null)
          .map(([key, value]) => [
            key,
            key === 'startDate' || key === 'endDate'
              ? new Date(value).getTime()
              : Array.isArray(value)
                ? value.map((item) => item.value)
                : value.value,
          ]),
      );
      body.filters = filterValues;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getKeywords`, body, {
        headers: {
          Authorization: `Bearer ${firebaseToken}`,
        },
      });

      setPerformanceData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const averagePosition =
    Math.round(
      performanceData?.allPositions?.reduce((acc, value) => acc + value, 0) /
        performanceData?.allPositions?.length,
    ) || 0;

  const data = DataPerformanceChart({ performanceData });

  const noDataByFilter = performanceData && performanceData?.byKeyword?.length === 0;

  const noData = performanceData && performanceData?.length === 0;

  useEffect(async () => {
    const account = await firebaseHelper.getAccount(accountId);

    if (!account.modules?.keywordReport) {
      navigate('/');
    }

    setLimit(account?.limits.keywordReport || 0);

    await fetchPerformanceData();
    setIsLoading(false);
  }, [user, navigate]);

  return (
    <KeywordMonitoringStyles>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Keyword placement')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs
          title={translate('Reports')}
          breadcrumbItem={translate('Keywords')}
          separatedTitle={translate('Keyword placement')}
        />
        <div className="d-flex flex-column gap-4 mt-4">
          <HeaderKeywordMonitoring
            groups={performanceData?.allGroups}
            tags={performanceData?.allTags}
            chartData={data}
            toggleModal={toggleModal}
            fetchPerformanceData={fetchPerformanceData}
            setFilters={setFilters}
            isLoading={isLoading}
          />
          {isLoading ? (
            <>
              <Shimmer height="12em" />
              <Shimmer height="20em" />
            </>
          ) : noDataByFilter ? (
            <div
              className="card-keyword-container d-flex flex-column align-items-center justify-content-center gap-4"
              style={{ minHeight: '30.5rem' }}
            >
              <img src={ImgNoData} alt="No keywords found with this filter" />
              <span className="text-span mt-2">
                {translate('No keywords found with this filter')}
              </span>
            </div>
          ) : noData ? (
            <div
              className="card-keyword-container d-flex flex-column align-items-center justify-content-center gap-4"
              style={{ minHeight: '30.5rem' }}
            >
              <img src={ImgNoData} alt="No keywords found" />
              <span className="text-span mt-2">{translate('No keywords found')}</span>
              {isAdminStatus && <ButtonAddKeyword onClickFunction={toggleModal} />}
            </div>
          ) : (
            <>
              <div className="card-keyword-container d-flex gap-4">
                <CardKeyword
                  title={translate('Monitored keywords')}
                  value={performanceData?.totalGroupedKeywords ?? 0}
                />
                <CardKeyword title={translate('Average position')} value={averagePosition ?? 0} />
              </div>
              <PerformanceChart data={data} />
              <PerformanceTable
                performanceData={performanceData}
                data={data}
                fetchPerformanceData={fetchPerformanceData}
              />
            </>
          )}
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {isOpenModal && <ModalAddKeyword isOpen={isOpenModal} toggle={toggleModal} limit={limit} />}
      </Suspense>
    </KeywordMonitoringStyles>
  );
};

export default KeywordMonitoring;
