import React, { useEffect, useState, useRef, useCallback } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, CardTitle, UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Board, { moveCard } from '@asseinfo/react-kanban';
import _ from 'lodash';
import { getDatabase, ref, onValue, update } from 'firebase/database';
import 'react-toastify/dist/ReactToastify.css';

import { useAuth } from 'hooks/useAuth';
import KanbanCard from './components/Kanban-Card';
import ChangelogModal from '../../components/Changelog-Modal';
import KanbanHeader from '../../components/Kanban-Header';
import HeaderContext from '../../components/Tasks-Filter/TasksFilter.Context';
import TaskModal from './components/Task-Modal';
import TaskModalContext from './components/Task-Modal/TaskModal.Context';
import KanbanBoardShimmer from '../../components/Kanban-Board-Shimmer';
import Breadcrumbs from '../../components/Breadcrumb';
import '../../assets/scss/custom/components/_shimmer.scss';
import '@asseinfo/react-kanban/dist/styles.css';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { kanbanStepNumber, initialKanbanState } from '../../constants/index';

const KanbanBoard = () => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const location = window.location;
  const accountId = user?.account;
  const { adminStatus } = user;
  const containerRef = useRef();
  const shimmerRef = useRef();
  const firebaseHelper = getFirebaseBackend();
  const [board, setBoard] = useState({ columns: initialKanbanState });
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(null);
  const [filterTotal, setFilterTotal] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalId, setModalId] = useState(false);
  const [modalAcc, setModalAcc] = useState(false);
  const [accountName, setAccountName] = useState('');

  const taskParam = new URLSearchParams(location.search).get('id');
  const accountParam = new URLSearchParams(location.search).get('acc');

  useEffect(async () => {
    const fetchData = async () => {
      if (!accountId) return;

      setLoading(true);

      // Opens modal on task especific link
      if (taskParam) {
        if (accountParam && accountId !== accountParam && adminStatus === 'Super Admin') {
          openCardModal({ id: taskParam, acc: accountParam });
        } else {
          openCardModal({ id: taskParam });
        }
      }

      const db = getDatabase();
      const tasksPath = `tasks/${accountId}`;
      const tasksRef = ref(db, tasksPath);

      // Subscribes to the account Kanban Board
      const handleTasksValueChange = async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const processedTasks = await processTasks(data);
          setBoard(() => ({ columns: processedTasks }));
        }
      };

      const unsubscribeTasks = onValue(tasksRef, handleTasksValueChange);

      try {
        const accountName = await firebaseHelper.accountName(accountId);
        setAccountName(accountName);
      } catch (error) {
        console.error('Error fetching account name:', error);
      } finally {
        setLoading(false);
      }

      return () => {
        unsubscribeTasks();
      };
    };

    fetchData();
  }, [accountId, filter]);

  useEffect(() => {
    if (loading) {
      containerRef.current.style.display = 'none';
      shimmerRef.current.style.display = 'flex';
    } else {
      containerRef.current.style.display = 'block';
      shimmerRef.current.style.display = 'none';
    }
  }, [loading]);

  useEffect(() => {
    if (modal) return;

    const url = new URL(location);
    url.searchParams.delete('expGraph');
    url.searchParams.delete('expConfig');
  }, [modal]);

  const stringPerColumn = {
    Backlog: () => {
      return ` (${board.columns[0].cards.length})`;
    },
    'In Progress': () => {
      return ` (${board.columns[1].cards.length})`;
    },
    Pendings: () => {
      return ` (${board.columns[2].cards.length})`;
    },
    Delivered: () => {
      return ` (${board.columns[3].cards.length})`;
    },
    Finished: () => {
      return ` (${board.columns[4].cards.length})`;
    },
    Archived: () => {
      return ` (${board.columns[5].cards.length})`;
    },
  };

  const tooltipText = {
    Backlog: translate(
      'Tasks that have been mapped out and are yet to be developed by the Ecto team.',
    ),
    'In Progress': translate('Tasks that are in the current sprint of the Ecto team.'),
    Pendings: translate('Tasks that require alignment for implementation or finalization.'),
    Delivered: translate(
      'Tasks that need to be implemented or validated with the {{accountName}} team.',
      {
        accountName,
      },
    ),
    Finished: translate('Tasks that have been implemented or finalized.'),
    Archived: translate(
      'Tasks paused due to strategic reasons, prioritization, team-related issues, or external factors.',
    ),
  };

  const normalizeDate = (date) => {
    try {
      const d = new Date(date);
      return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    } catch {
      return null;
    }
  };

  const processTasks = async (receivedTasks) => {
    if (!receivedTasks) {
      setLoading(false);
      return initialKanbanState;
    }

    const taskState = _.cloneDeep(initialKanbanState);

    const sortedTasks = Object.entries(receivedTasks)
      .sort((a, b) => a[1]['position'] - b[1]['position'])
      .filter(([, taskValue]) => {
        if (!filter) return true;
        let totalFilters = 0;
        let assignedBool = 1;
        let statusBool = 1;
        let impactBool = 1;
        let complexityBool = 1;
        let foundationsBool = 1;
        let searchBool = 1;
        let createDateBool = 1;
        let implementationDateBool = 1;
        let deliveryDateBool = 1;

        if (!_.isEmpty(filter.assignedTo)) {
          assignedBool = 0;
          totalFilters++;
          filter.assignedTo.forEach(({ value }) => {
            if (taskValue.assignedTo.includes(value)) assignedBool++;
          });
        }

        if (!_.isEmpty(filter.status)) {
          statusBool = 0;
          filter.status.forEach(({ value }) => {
            totalFilters++;
            if (taskValue.status === value) statusBool++;
          });
        }

        if (!_.isEmpty(filter.impact)) {
          impactBool = 0;
          filter.impact.forEach(({ value }) => {
            totalFilters++;
            if (taskValue.impact === value) impactBool++;
          });
        }

        if (!_.isEmpty(filter.complexity)) {
          complexityBool = 0;
          filter.complexity.forEach(({ value }) => {
            totalFilters++;
            if (taskValue.complexity === value) complexityBool++;
          });
        }

        if (!_.isEmpty(filter.foundations)) {
          foundationsBool = 0;
          filter.foundations.forEach(({ value }) => {
            totalFilters++;
            if (taskValue.foundations.includes(value)) foundationsBool++;
          });
        }

        if (filter.search) {
          searchBool = 0;
          totalFilters++;
          const normalizedSearch = filter.search
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
          const normalizedName = taskValue.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
          const normalizedDescription = (taskValue?.description || '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
          if (normalizedName.includes(normalizedSearch)) searchBool++;
          if (normalizedDescription.includes(normalizedSearch)) searchBool++;
        }

        if (filter.startCreationDate && filter.endCreationDate) {
          totalFilters++;
          createDateBool = 0;

          const taskDate = normalizeDate(taskValue.createdAt);
          const startDate = normalizeDate(filter.startCreationDate);
          const endDate = normalizeDate(filter.endCreationDate);

          if (
            taskDate !== null &&
            startDate !== null &&
            endDate !== null &&
            taskDate >= startDate &&
            taskDate <= endDate
          ) {
            createDateBool++;
          }
        }

        if (filter.startImplementationDate && filter.endImplementationDate) {
          totalFilters++;
          implementationDateBool = 0;

          const taskDate = normalizeDate(taskValue.dateImplementation);
          const startDate = normalizeDate(filter.startImplementationDate);
          const endDate = normalizeDate(filter.endImplementationDate);

          if (
            taskDate !== null &&
            startDate !== null &&
            endDate !== null &&
            taskDate >= startDate &&
            taskDate <= endDate
          ) {
            implementationDateBool++;
          }
        }

        if (filter.startDeliveryDate && filter.endDeliveryDate) {
          totalFilters++;
          deliveryDateBool = 0;

          const taskDate = normalizeDate(taskValue.dateDelivery);
          const startDate = normalizeDate(filter.startDeliveryDate);
          const endDate = normalizeDate(filter.endDeliveryDate);

          if (
            taskDate !== null &&
            startDate !== null &&
            endDate !== null &&
            taskDate >= startDate &&
            taskDate <= endDate
          ) {
            deliveryDateBool++;
          }
        }

        setFilterTotal(totalFilters);

        return (
          assignedBool &&
          statusBool &&
          impactBool &&
          complexityBool &&
          foundationsBool &&
          searchBool &&
          createDateBool &&
          implementationDateBool &&
          deliveryDateBool
        );
      })
      .reduce(
        (sortedObj, [key, value]) => ({
          ...sortedObj,
          [key]: value,
        }),
        {},
      );

    // Populate the kanban board object with its contents from the realtime database
    for (const [key, value] of Object.entries(sortedTasks)) {
      // Places the tasks on its proper columns of the Kanban Board Object
      switch (value.column) {
        case 'Backlog':
          taskState[0].cards.push(value);
          break;
        case 'In Progress':
          taskState[1].cards.push(value);
          break;
        case 'Pending':
          taskState[2].cards.push(value);
          break;
        case 'Pendings':
          taskState[2].cards.push(value);
          break;
        case 'Delivered':
          taskState[3].cards.push(value);
          break;
        case 'Finished':
          taskState[4].cards.push(value);
          break;
        case 'Archived':
          taskState[5].cards.push(value);
          break;
      }
    }

    setLoading(false);

    // return the state, processed or default
    return taskState;
  };

  const openCardModal = useCallback(
    async (data) => {
      setModalId(data.id);
      setModalAcc(data.acc ?? accountId);
      setModal(true);
    },
    [accountId],
  );

  const onCardDragEnd = (card, source, destination) => {
    const updatedBoard = moveCard(board, source, destination);
    const updates = {};

    // Updates the position field on each card
    updatedBoard.columns.forEach((column) => {
      column.cards.forEach((card, indexCard) => {
        card.position = indexCard;
      });
    });

    if (!card.dateDelivery && destination.toColumnId === 4) {
      return;
    }

    // If the column was changed updates the column on the moved card
    if (card.column !== kanbanStepNumber[destination.toColumnId]) {
      card.column = kanbanStepNumber[destination.toColumnId];
    }
    // Sends an update request to change the position for every card,
    // firebase only updates the needed ones
    updatedBoard.columns.forEach((column) => {
      column.cards.forEach((card) => {
        updates[`tasks/${accountId}/${card.id}`] = { ...card };
      });
    });
    // Updates all the cards simultaneously to avoid multiple firebase listener events
    // that cause visual glitching
    const db = getDatabase();
    update(ref(db), updates).catch((error) => {
      console.error('Erro ao atualizar os cartões:', error);
    });

    // Sets the local state to avoid delay before the cards are processed
    setBoard(updatedBoard);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Kanban')} | Ectools`}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={translate('Tasks')} breadcrumbItem={translate('Kanban Board')} />

          <ChangelogModal />

          <TaskModalContext.Provider
            value={{ adminStatus, modal, setModal, id: modalId, acc: modalAcc }}
          >
            <TaskModal accountId={accountId} />
          </TaskModalContext.Provider>

          <HeaderContext.Provider value={{ setFilter }}>
            <KanbanHeader board={board} accountId={accountId} filterTotal={filterTotal} />
          </HeaderContext.Provider>

          <div ref={containerRef} style={{ height: '64.9vh' }}>
            <Board
              disableColumnDrag
              renderColumnHeader={({ title }) => (
                <div className="d-flex justify-content-between">
                  <CardTitle className="mb-4">
                    {translate(title) + stringPerColumn[title]()}
                  </CardTitle>
                  <i
                    className="bx bx-info-circle"
                    id={title.replace(' ', '-')}
                    style={{ cursor: 'pointer', fontSize: '1.5rem' }}
                  />
                  <UncontrolledTooltip placement="top" target={title.replace(' ', '-')}>
                    {tooltipText[title]}
                  </UncontrolledTooltip>
                </div>
              )}
              renderCard={(data) => (
                <KanbanCard data={data} action={openCardModal}>
                  {data}
                </KanbanCard>
              )}
              onCardDragEnd={onCardDragEnd}
            >
              {board}
            </Board>
          </div>
          <KanbanBoardShimmer ref={shimmerRef} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default KanbanBoard;
