import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';

const SuccessModal = ({ isOpen, verb, onClose = () => {}, buttonText, buttonLink }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
    >
      <ModalHeader toggle={onClose}>
        <div className="modal--header">
          <i className="bx bx-check-double modal--icon" />
          <span className="modal--title">{translate(`${verb} Successfully!`)}</span>
        </div>
      </ModalHeader>

      <ModalFooter>
        <Link to={buttonLink}>
          <button className="modal--button-two">{translate(buttonText)}</button>
        </Link>
        <button className="modal--button-one" onClick={handleBack}>
          {translate('Go back')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

SuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  verb: PropTypes.string,
  onClose: PropTypes.func,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default SuccessModal;
