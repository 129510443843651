import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useAuth } from 'hooks/useAuth';

const STATUS_TO_PAGE = {
  BACKLOG: '/guideline/:id/review',
  GUIDELINE_APPROVAL: '/guideline/:id/guideline-approval',
  CONTENT_STRUCTURE: '/content/:id/structure',
  PRE_REVIEW: '/content/:id/pre-review',
  CONTENT_REVIEW: '/content/:id/review',
  INTERNAL_APPROVAL: '/content/:id/internal-approval',
  CONTENT_APPROVAL: '/content/:id/content-approval',
  APPROVED: '/content/:id/publish',
  PUBLISHED: '/guidelines',
};

const GuidelineMisc = () => {
  const firebaseHelper = getFirebaseBackend();

  const { id: guidelineId } = useParams();
  const { user } = useAuth();

  const currentAccountId = user?.account;
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      if (!guidelineId) return;

      await firebaseHelper.getFirebaseGuideline(currentAccountId, guidelineId).then((guideline) => {
        if (!guideline) return navigate('/404');
        if (guideline?.archived) return navigate('/guideline/manage');
        if (guideline?.denied) return navigate('/guideline/manage');

        const page = STATUS_TO_PAGE[guideline.column];
        const finalURL = page.replace(':id', guidelineId);
        navigate(finalURL);
      });
    };
    initialize();
  }, [guidelineId]);

  return null;
};

GuidelineMisc.displayName = 'Guideline Misc';

export default GuidelineMisc;
