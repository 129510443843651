import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { Input, InputGroupText } from 'reactstrap';
import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

import { InputGroupStyled, KeywordGroupsStyled } from './keywordGroupsStyles';
import Breadcrumbs from './components/Breadcrumb';
import ButtonAddGroup from './components/Button-Add-Group';
import CardKeywordGroup from './components/Card-Keyword-Group';
import TableGroups from './components/Table-Groups';
import Shimmer from 'components/Shimmer';

const ModalAddGroup = lazy(() => import('./components/Modal-Add-Group'));

const KeywordGroups = () => {
  const { t: translate } = useTranslation();
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const accountId = user.account;
  const isAdminStatus = ['Super Admin', 'Admin'].includes(user.adminStatus);
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notAgruped, setNotAgruped] = useState([]);
  // const [contents, setContents] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [limit, setLimit] = useState(0);
  const [limitUsed, setLimitUsed] = useState(0);

  const fetchGroups = async () => {
    try {
      setIsLoading(true);

      const responseGroups = (await firebaseHelper.getKeywordGroups(accountId)) || [];
      const responseNotAgruped =
        (await firebaseHelper.getKeywordsByGroupId(accountId, responseGroups[0].id)) || [];
      // const responseContents = await firebaseHelper.getKeywordGroupContent(accountId);

      if (filterValue) {
        const filteredGroups = responseGroups.filter((group) =>
          group.name.toLowerCase().includes(filterValue.toLowerCase()),
        );

        setGroups(filteredGroups);
        setNotAgruped(responseNotAgruped);
        // setContents(responseContents);
        setIsLoading(false);
        return;
      }

      setGroups(responseGroups);
      setNotAgruped(responseNotAgruped);
      // setContents(responseContents);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching groups: ', error);
    }
  };

  const handleInputChange = (e) => {
    setFilterValue(e.target.value);
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const fetchLimitUsedFromFirebase = async () => {
    try {
      const limit = await firebaseHelper.getUsedLimitKeyword(accountId);

      setLimitUsed(limit);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    if (!isAdminStatus) {
      navigate('/');
    }

    const account = await firebaseHelper.getAccount(accountId);

    if (!account.modules?.keywordReport) {
      navigate('/');
    }

    account?.limits.keywordReport ? setLimit(account?.limits.keywordReport) : setLimit(0);

    fetchLimitUsedFromFirebase();

    fetchGroups();
  }, [user, navigate]);

  useEffect(() => {
    fetchGroups();
  }, [filterValue]);

  return (
    <KeywordGroupsStyled>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Keyword groups')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs
          title={translate('Keywords')}
          breadcrumbItem={translate('Groups')}
          showLimit
          limit={limit}
          limitUsed={limitUsed}
        />
        <div className="d-flex flex-column gap-4 mt-4">
          <div className="card-keyword-container">
            <div className="d-flex align-items-center justify-content-between w-100">
              <InputGroupStyled>
                <Input
                  type="text"
                  placeholder={translate('Search')}
                  onChange={(e) => handleInputChange(e)}
                />
                <InputGroupText>
                  <i className="bx bx-search" />
                </InputGroupText>
              </InputGroupStyled>
              <ButtonAddGroup onClickFunction={toggleModal} />
            </div>
            <div className="d-flex w-100 gap-4">
              {isLoading ? (
                <>
                  <Shimmer height="9em" />
                  <Shimmer height="9em" />
                </>
              ) : (
                <>
                  <CardKeywordGroup
                    keyIcon
                    title={translate('Not grouped')}
                    value={notAgruped.length}
                    onClickFunction={() => navigate(`/reports/keywords/groups/${groups[0]?.id}`)}
                  />
                  <CardKeywordGroup
                    fileIcon
                    title={translate('Contents')}
                    soon
                    // value={contents.total}
                    // onClickFunction={() => navigate(`/reports/keywords/groups/contents`)}
                  />
                </>
              )}
            </div>
            <TableGroups groups={groups} isLoading={isLoading} />
          </div>
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {isOpenModal && (
          <ModalAddGroup isOpen={isOpenModal} toggle={toggleModal} fetchGroups={fetchGroups} />
        )}
      </Suspense>
    </KeywordGroupsStyled>
  );
};

export default KeywordGroups;
