import React from 'react';
import LabelGroup from 'components/Label-Group';
import ParticipantsSelectInput from './ParticipantsSelectInput';
import { loadTeam } from 'utils';
import PropTypes from 'prop-types';

const ParticipantsSelect = ({ accountId }) => {
  const [participantsKey, setParticipantsKey] = React.useState(1000);

  React.useEffect(() => {
    setParticipantsKey((prevState) => prevState + 1);
  }, [accountId]);
  return (
    <LabelGroup htmlFor="participants" label="Participants" required>
      <ParticipantsSelectInput
        asyncronous
        isMulti
        controlName="participants"
        key={participantsKey}
        required
        cacheOptions
        defaultOptions
        loadOptions={(inputValue) => {
          return loadTeam(accountId, inputValue);
        }}
      />
    </LabelGroup>
  );
};

ParticipantsSelect.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default ParticipantsSelect;
