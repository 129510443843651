import React from 'react';
import styled from 'styled-components';
import { DropdownMenu, DropdownToggle } from 'reactstrap';

export const DropdownToggleStyled = styled(({ isOpen, ...rest }) => <DropdownToggle {...rest} />)`
  background-color: ${({ isOpen }) => (isOpen ? '#efefef' : 'transparent')};
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 0.25rem;

  :hover {
    background-color: #efefef;
  }
  :active {
    background-color: #efefef;
  }
`;

export const DropdownMenuStyled = styled(DropdownMenu)`
  margin-top: 18px !important;
  border: 1px solid #e2e8f0;
  box-shadow: 12px 12px 8px 0px rgba(0, 0, 0, 0.02) !important;
  padding: 0 !important;
  width: fit-content !important;

  .btn-quick-menu {
    background-color: #ffffff;
    width: 8.9rem;
    min-height: 3rem;
    height: 100%;
    border: 1px solid #eff2f7;
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #343a40 !important;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .btn-quick-menu:hover {
    background-color: #f5f6fa;
  }
  .btn-quick-menu:active {
    border: 1px solid #f5f6fa;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .border-right {
    border-right: 1px solid #eff2f7;
  }

  .flex-col-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    gap: 1.5rem;

    span {
      width: 8.5rem;
      font-size: 0.8125rem;
      line-height: normal;
      font-weight: 500;
      color: #343a40;
    }

    a {
      height: min-content;
      padding: 0 !important;
      border: none !important;
    }
  }
`;
