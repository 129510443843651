import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

import { ButtonAddTagStyles } from './styles';

const ButtonAddTag = ({
  inBulk,
  direction,
  data,
  setData,
  tags,
  setTags,
  keywordName,
  disabled,
}) => {
  const { t: translate } = useTranslation();
  const tagsRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prevState) => !prevState);
    setTimeout(() => {
      if (!isOpen && tagsRef.current) {
        tagsRef.current.focus();
      }
    }, 0);
  };

  const onSubmit = () => {
    const tagsArray = tagsRef.current.value
      .split(',')
      .map((tag) => tag.trim())
      .filter((tag) => tag !== '');

    if (tagsArray.length === 0) return;

    if (inBulk) {
      if (tagsArray.length > 0) {
        setTags((prevTags) => [...prevTags, ...tagsArray]);

        setData((prevData) => {
          return prevData.map((item) => ({
            ...item,
            tags: [...item.tags, ...tagsArray],
          }));
        });

        tagsRef.current.value = '';
      }
    } else {
      if (tagsArray.length > 0) {
        const index = data.findIndex((item) => item.name === keywordName);

        if (index !== -1) {
          setData((prevData) => {
            const existingTags = prevData[index].tags || [];

            const newTags = tagsArray.filter((tag) => !existingTags.includes(tag));

            const updatedTags = [...existingTags, ...newTags];

            const updatedItem = {
              ...prevData[index],
              tags: updatedTags,
            };

            const updatedData = [...prevData];
            updatedData[index] = updatedItem;

            return updatedData;
          });

          tagsRef.current.value = '';
        }
      }
    }
    setIsOpen(false);
  };

  return (
    <div>
      <ButtonAddTagStyles isOpen={isOpen} toggle={toggle} direction={direction}>
        <DropdownToggle caret disabled={disabled}>
          {inBulk ? translate('Add common tag') : translate('Add tag')}
        </DropdownToggle>
        <DropdownMenu
          style={{ zIndex: isOpen ? 9999 : -1, display: isOpen ? 'flex' : 'none' }}
          className="flex-column gap-1"
          end
        >
          <Label>{translate('Tag name')} *</Label>
          <Input
            type="text"
            required
            placeholder={translate('Name')}
            innerRef={tagsRef}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onSubmit();
              }
            }}
            data-1p-ignore
          />
          <span className="span-obs-tag">{translate('Separate each tag with a (,)')}</span>
          <Button className="btn-add-tag" onClick={onSubmit}>
            {translate('Add')}
          </Button>
        </DropdownMenu>
      </ButtonAddTagStyles>
    </div>
  );
};

ButtonAddTag.propTypes = {
  inBulk: PropTypes.bool,
  direction: PropTypes.string,
  data: PropTypes.array,
  setData: PropTypes.func,
  tags: PropTypes.array,
  setTags: PropTypes.func,
  keywordName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ButtonAddTag;
