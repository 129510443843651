import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

import { useAuth } from 'hooks/useAuth';
import { useAccount } from 'hooks/useAccount';

export const ModuleRoute = ({ children, requiredModule, requiredRole }) => {
  const { user } = useAuth();
  const { account, isLoading } = useAccount();

  if (isLoading) {
    return <MoonLoader color="#556ee6" size={90} />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  const userModules = user?.modules;

  if (requiredModule) {
    const accountModules = account?.modules || {};
    const hasRequiredModule = userModules[requiredModule];

    const userHasAccess =
      hasRequiredModule &&
      (requiredRole
        ? Array.isArray(requiredRole)
          ? requiredRole.includes(user.adminStatus)
          : user.adminStatus === requiredRole
        : true) &&
      accountModules[requiredModule];

    if (!hasRequiredModule || !userHasAccess) {
      return <Navigate to="/" />;
    }
  }

  return children;
};

ModuleRoute.propTypes = {
  children: PropTypes.node,
  requiredModule: PropTypes.string,
  requiredRole: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
