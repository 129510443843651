import React, { useState, useEffect, useRef } from 'react';
import Dropzone from 'react-dropzone';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useAuth } from 'hooks/useAuth';
import Breadcrumbs from '../../components/Breadcrumb';
import SuccessModal from '../../components/Success-Modal';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import MarkdownInput from '../../components/Markdown-Input';
import DateInput from '../../components/Date-Input';
import SelectInput from '../../components/Select-Input';
import { getFormValue, getEpochDate, loadTeam, loadProjects } from '../../utils';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import '../../assets/scss/custom/components/_dragndrop.scss';

const TasksCreate = () => {
  const { t: translate } = useTranslation();
  const formConfig = {
    defaultValues: {
      name: '',
      description: '',
      startDate: new Date(),
      endDate: '',
      implementationDate: '',
      assignedTo: [],
      foundations: [],
      status: [],
      project: [],
      impact: [],
      complexity: [],
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };

  const firebaseHelper = getFirebaseBackend();

  const location = useLocation();
  const form = useForm(formConfig);
  const { user } = useAuth();
  const accountId = user.account;
  const [taskId, setTaskId] = useState();
  const [files, setFiles] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [projectKey, setProjectKey] = useState(0);
  const [assignedKey, setAssignedKey] = useState(1000);
  const taskIdRef = useRef();

  const formState = form.formState;
  taskIdRef.current = taskId;

  useEffect(async () => {
    const currentTaskId = await firebaseHelper.newTaskKey(accountId, 1);
    setTaskId(currentTaskId);

    if (location.search.length) {
      const url = new URLSearchParams(location.search);
      const nameParam = url.get('name');
      const assignParam = url.get('assign');

      form.reset({
        name: nameParam ?? '',
        assignedTo: assignParam
          ? [
              {
                value: assignParam,
                label: await firebaseHelper.userName(assignParam),
              },
            ]
          : [],
      });
    }

    return () => {
      firebaseHelper.removeUnusedMDFiles({
        accountId,
        projectId: '1',
        id: taskIdRef.current,
      });
    };
  }, []);

  useEffect(() => {
    setProjectKey((prevState) => prevState + 1);
    setAssignedKey((prevState) => prevState + 1);
  }, [accountId]);

  const fileHandle = (acceptedFiles) => {
    setFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  const fileRemoveHandle = (event) => {
    event.preventDefault();

    const newFiles = _.cloneDeep(files);
    newFiles.splice(event.target.id, 1);
    setFiles(newFiles);
  };

  const closeModalHandle = () => {
    form.reset();
    setFiles([]);
    setSuccessModal(false);
  };

  const onSubmit = async (data) => {
    // Create task object and send it as a parameter to the registerTask function
    const position = await firebaseHelper.getLastPosition(accountId, 1, 'Backlog');
    const hasFiles = files.length > 0;
    const task = {
      taskId,
      clientId: accountId,
      projectId: data.project.value,
      name: data.name,
      description: data.description !== '' ? data.description : null,
      dateBegin: getEpochDate(data.startDate),
      dateDelivery: getEpochDate(data.endDate),
      dateImplementation: getEpochDate(data.implementationDate),
      assignedTo: data.assignedTo.map(getFormValue),
      foundations: data.foundations.map(getFormValue),
      status: data.status.value,
      impact: data?.impact?.value ? data.impact.value : null,
      complexity: data?.complexity?.value ? data.complexity.value : null,
      hasFiles: hasFiles,
      position: position,
      column: 'Backlog',
    };
    firebaseHelper
      .registerTask(task)
      // If the task is registered successfully then the handler store the files
      .then(() => {
        if (task.hasFiles) {
          firebaseHelper.storeFiles(files, `${accountId}/1/${taskId}`);
        }
        setSuccessModal(true);
      });
    // Get new Task Id for the next possible task to be created
    const newTaskId = firebaseHelper.newTaskKey(accountId, 1);
    setTaskId((prevState) => newTaskId);
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Create Task')} | Ectools`}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={translate('Tasks')} breadcrumbItem={translate('Create Task')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <SuccessModal
                    isOpen={successModal}
                    verb="Created"
                    onClose={closeModalHandle}
                    buttonText="See Tasks"
                    buttonLink="/tasks"
                  />

                  <CardTitle className="mb-4">{translate('Create New Task')}</CardTitle>

                  <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                      <LabelGroup htmlFor="name" label="Task Name" required>
                        <TextInput controlName="name" placeholder="Task Name" required />
                      </LabelGroup>

                      <LabelGroup htmlFor="description" label="Task Description">
                        <MarkdownInput attachId={taskId} controlName="description" />
                      </LabelGroup>

                      <LabelGroup label="Task Date">
                        <DateInput controlName="startDate" placeholder="Start Date" size={4} />
                        <DateInput controlName="endDate" placeholder="Deadline" size={4} />
                        <DateInput
                          controlName="implementationDate"
                          placeholder="Implementation"
                          size={4}
                        />
                      </LabelGroup>

                      <LabelGroup label="Details" required>
                        <SelectInput
                          asyncronous
                          isMulti
                          label="Assigned To"
                          controlName="assignedTo"
                          key={assignedKey}
                          required
                          cacheOptions
                          defaultOptions
                          loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
                          size={6}
                        />
                        <SelectInput
                          isMulti
                          label="Foundations"
                          controlName="foundations"
                          required
                          options={[
                            {
                              value: 'Technology',
                              label: translate('Technology'),
                            },
                            { value: 'Content', label: translate('Content') },
                            {
                              value: 'Link Building',
                              label: translate('Link Building'),
                            },
                            { value: 'Report', label: translate('Report') },
                            {
                              value: 'Performance',
                              label: translate('Performance'),
                            },
                            {
                              value: 'Architeture',
                              label: translate('Architeture'),
                            },
                          ]}
                          size={6}
                        />
                        <SelectInput
                          label="Status"
                          controlName="status"
                          required
                          options={[
                            { value: 0, label: translate('Waiting') },
                            {
                              value: 1,
                              label: translate('Ready to Implement'),
                            },
                            {
                              value: 2,
                              label: translate('Technical Impediment'),
                            },
                            { value: 3, label: translate('Under Development') },
                            { value: 4, label: translate('Implemented') },
                          ]}
                          size={6}
                        />
                        <SelectInput
                          asyncronous
                          label="Project"
                          controlName="project"
                          key={projectKey}
                          required
                          cacheOptions
                          defaultOptions
                          loadOptions={(inputValue) => loadProjects(accountId, inputValue)}
                          size={6}
                        />
                      </LabelGroup>

                      <LabelGroup label="Priority">
                        <SelectInput
                          label="Impact"
                          controlName="impact"
                          options={[
                            { value: 'L', label: translate('Low') },
                            { value: 'M', label: translate('Medium') },
                            { value: 'H', label: translate('High') },
                          ]}
                          size={6}
                        />
                        <SelectInput
                          label="Complexity"
                          controlName="complexity"
                          options={[
                            { value: 'L', label: translate('Low') },
                            { value: 'M', label: translate('Medium') },
                            { value: 'H', label: translate('High') },
                          ]}
                          size={6}
                        />
                      </LabelGroup>

                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">{translate('Files')}</Label>
                        <Col lg="10">
                          <Row>
                            <Dropzone onDrop={fileHandle}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()} className="drag-n-drop">
                                    <input {...getInputProps()} />
                                    <i
                                      className="fas fa-cloud-upload-alt fa-3x"
                                      style={{ marginBottom: '1%' }}
                                    ></i>
                                    <p>
                                      {translate(
                                        "Drag 'n' drop some files here, or click to select files",
                                      )}
                                    </p>
                                  </div>
                                </section>
                              )}
                            </Dropzone>

                            <div className="file-list">
                              {files.map((file, index) => {
                                return (
                                  <div key={`${file.name}-${index}`} className="file">
                                    <p>{file.name}</p>
                                    <button
                                      id={index}
                                      className="btn btn-sm btn-outline-danger"
                                      onClick={fileRemoveHandle}
                                    >
                                      X
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </Row>
                        </Col>
                      </FormGroup>

                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button
                            type="submit"
                            style={{ backgroundColor: '#344792' }}
                            disabled={Object.values(formState.errors).length}
                          >
                            {translate('Create Task')}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </FormProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TasksCreate;
