import React from 'react';
import propTypes from 'prop-types';

import { guidelineDeliveryTypeOptions, monthNumberLabel } from '../../../../constants';

const TitleContents = ({ guideline }) => {
  return (
    <p>{`${guideline?.count} | ${
      monthNumberLabel.find((month) => month.value === parseInt(guideline?.productionDate?.month))
        ?.label
    } | ${
      guidelineDeliveryTypeOptions.find((type) => type.value === guideline?.deliveryType)?.label
    } | ${guideline?.totalSize} | ${guideline?.deliveryType === 'REVIEW' || guideline?.deliveryType === 'REVIEW_AND_DIRECTIONS' ? guideline?.reviewLink : guideline?.name.charAt(0).toUpperCase() + guideline?.name.slice(1)}`}</p>
  );
};

TitleContents.propTypes = {
  guideline: propTypes.object,
};

export default TitleContents;
