import styled from 'styled-components';

export const ContainerShortcutsStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.8rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  gap: 2rem;

  span {
    color: #2d3748;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
  }

  .btn-quick-menu {
    background-color: #ffffff;
    min-width: 21rem;
    width: 100%;
    height: 5rem;
    padding: 1.5rem 2rem;
    border: 1px solid #eff2f7;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: #343a40 !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .btn-quick-menu:hover {
    background-color: #f5f6fa;
  }
  .btn-quick-menu:active {
    border: 1px solid #f5f6fa;
  }
`;
