import React, { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import PageStructure from '../../components/Page-Structure';
import ShowComponent from '../../components/Show-Component';
import AccordionList from '../../components/Accordion-List';
import Shimmer from '../../components/Shimmer';
import TaskModalContext from './components/Task-Modal/TaskModal.Context';
import TaskModal from './components/Task-Modal';
import { LoadingAccordions } from '../../components/Accordion-List/styles';
import { complexityTooltip, contentStatusNumber } from '../../constants';
import { formatDateTimestampNumber } from 'utils';

const TasksList = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { parse } = require('json2csv');
  const { user } = useAuth();
  const { adminStatus } = user;
  const accountId = user.account;
  const [tasks, setTasks] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [loading, setLoading] = useState();
  const [filterValue, setFilterValue] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalId, setModalId] = useState(false);
  const [modalAcc, setModalAcc] = useState(false);

  const taskParam = new URLSearchParams(location.search).get('id');
  const accountParam = new URLSearchParams(location.search).get('acc');

  const getAllTasks = async () => {
    const response = await firebaseHelper.getAllTasks(accountId);
    setAllTasks(Object.values(response));
  };

  const handleDownloadCSV = async () => {
    const tasksPromises = allTasks.map(async (task) => {
      let assignedToNames = '';

      if (task.assignedTo && task.assignedTo.length > 0) {
        const userPromises = task.assignedTo.map(
          async (userId) => await firebaseHelper.getUserName(userId),
        );
        const resolvedUserNames = await Promise.all(userPromises);
        assignedToNames = resolvedUserNames.join(',');
      }

      return {
        Column: task.column,
        Name: task.name,
        AssignedTo: assignedToNames,
        Complexity: task.complexity ? complexityTooltip[task.complexity] : '',
        CreatedAt: task.createdAt ? new Date(task.createdAt).toISOString().substring(0, 10) : '',
        Foundations: task.foundations ? task.foundations.join(',') : '',
        HasChecklist: task.hasChecklist,
        Impact: task.impact ? complexityTooltip[task.impact] : '',
        Status: task.status ? contentStatusNumber[task.status] : '',
        DeliveryDate: formatDateTimestampNumber(task.dateDelivery, 'date-enUS'),
        ImplementationDate: formatDateTimestampNumber(task.dateImplementation, 'date-enUS'),
        Description: task.description,
      };
    });

    const resolvedTasks = await Promise.all(tasksPromises);

    const csvOptions = {
      fields: [
        'Column',
        'Name',
        'AssignedTo',
        'Complexity',
        'CreatedAt',
        'Foundations',
        'HasChecklist',
        'Impact',
        'Status',
        'DeliveryDate',
        'ImplementationDate',
        'Description',
      ],
      header: true,
    };

    const csvContent = parse(resolvedTasks, csvOptions);

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);
    const accountName = await firebaseHelper.accountName(accountId);
    const dateNumber = new Date().toISOString().substring(0, 10);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${accountName}_${dateNumber}_tasks_data.csv`;

    link.click();

    URL.revokeObjectURL(url);
  };

  const isAllow = (user) => {
    return user.adminStatus === 'Super Admin' || user.adminStatus === 'Admin';
  };

  const handleInputChange = (e) => {
    setFilterValue(e.target.value);
  };

  const processTasks = useCallback(
    (receivedTasks) => {
      if (!receivedTasks || Object.keys(receivedTasks).length === 0) {
        setTasks([]);
        setLoading(false);
        return;
      }

      if (taskParam) {
        if (accountParam && accountId !== accountParam && adminStatus === 'Super Admin') {
          openCardModal({ id: taskParam, acc: accountParam });
        } else {
          openCardModal({ id: taskParam });
        }
      }

      const newTaskState = [[], [], [], [], [], []];

      const sortedTasks = Object.entries(receivedTasks)
        .sort((a, b) => a[1]['position'] - b[1]['position'])
        .reduce(
          (sortedObj, [key, value]) => ({
            ...sortedObj,
            [key]: value,
          }),
          {},
        );

      for (const [key, value] of Object.entries(sortedTasks)) {
        if (filterValue && !value.name.toLowerCase().includes(filterValue.toLowerCase())) {
          continue;
        }
        switch (value.column) {
          case 'Backlog':
            newTaskState[0].push(value);
            break;
          case 'In Progress':
            newTaskState[1].push(value);
            break;
          case 'Pending':
          case 'Pendings':
            newTaskState[2].push(value);
            break;
          case 'Delivered':
            newTaskState[3].push(value);
            break;
          case 'Finished':
            newTaskState[4].push(value);
            break;
          case 'Archived':
            newTaskState[5].push(value);
            break;
          default:
            break;
        }
      }

      setTasks(newTaskState);
    },
    [filterValue, accountId],
  );

  const openCardModal = useCallback(
    async (data) => {
      setModalId(data.id);
      setModalAcc(data.acc ?? accountId);
      setModal(true);
    },
    [accountId],
  );

  const fetchTasks = useCallback(
    async (accountId) => {
      setLoading(true);

      try {
        await getAllTasks();

        const db = getDatabase();
        const tasksPath = `tasks/${accountId}`;
        const tasksRef = ref(db, tasksPath);

        const handleTasksValueChange = (snapshot) => {
          const data = snapshot.val();

          if (data) {
            processTasks(data);
          }
          setLoading(false);
        };

        const unsubscribeTasks = onValue(tasksRef, handleTasksValueChange);

        return () => {
          unsubscribeTasks();
          off(tasksRef);
        };
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setLoading(false);
      }
    },
    [accountId],
  );

  useEffect(() => {
    if (!accountId) return;

    fetchTasks(accountId);
  }, [accountId]);

  useEffect(() => {
    processTasks(allTasks);
  }, [filterValue, allTasks, processTasks]);

  return (
    <PageStructure metaTitle="Task List" breadcrumbItem="List" breadcrumbTitle="Tasks">
      <TaskModalContext.Provider
        value={{ adminStatus, modal, setModal, id: modalId, acc: modalAcc }}
      >
        <TaskModal accountId={accountId} />
      </TaskModalContext.Provider>
      <ShowComponent condition={loading}>
        <LoadingAccordions>
          <Shimmer height="3rem" />
          <Shimmer height="10rem" />
          <Shimmer height="10rem" />
          <Shimmer height="10rem" />
          <Shimmer height="10rem" />
          <Shimmer height="10rem" />
        </LoadingAccordions>
      </ShowComponent>
      <ShowComponent condition={!loading}>
        <div className="d-flex w-100 align-items-center justify-content-between mb-4 gap-3">
          <InputGroupStyled>
            <Input
              type="text"
              placeholder={translate('Search')}
              onChange={(e) => handleInputChange(e)}
            />
            <InputGroupText>
              <IconSearchStyled className="bx bx-search" />
            </InputGroupText>
          </InputGroupStyled>
          {isAllow(user) && (
            <div className="d-flex gap-3">
              <ButtonDownloadCsvStyled onClick={handleDownloadCSV}>
                <i className="bx bx-download" />
                {translate('Export CSV')}
              </ButtonDownloadCsvStyled>
              <Button
                color="primary"
                className="d-flex gap-2 align-items-center"
                href="/tasks-create"
              >
                <i className="bx bx-plus" />
                {translate('Create task')}
              </Button>
            </div>
          )}
        </div>
        <AccordionList column="Backlog" data={tasks?.[0]} />
        <AccordionList column="In Progress" data={tasks?.[1]} />
        <AccordionList column="Pendings" data={tasks?.[2]} />
        <AccordionList column="Delivered" data={tasks?.[3]} />
        <AccordionList column="Finished" data={tasks?.[4]} />
        <AccordionList column="Archived" data={tasks?.[5]} />
      </ShowComponent>
    </PageStructure>
  );
};

const ButtonDownloadCsvStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e8f0;
  background-color: transparent;
  color: #343a40 !important;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:hover {
    background-color: #e2e8f0;
    border: 1px solid #e2e8f0;
  }
`;

const InputGroupStyled = styled(InputGroup)`
  width: 32rem;

  input {
    border-right: none;
  }
  input:focus-within {
    border-color: #ced4da;
  }

  span {
    background-color: #ffffff;
    border-left: none;
  }
`;

const IconSearchStyled = styled.i`
  display: flex;
  font-size: 1.25rem;
  color: #718096;
`;

TasksList.displayName = 'Tasks List Page';

TasksList.propTypes = {};

export default TasksList;
