import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { AuthProvider } from './hooks/useAuth';
import { AccountProvider } from 'hooks/useAccount';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const app = (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <AccountProvider>
        <CookiesProvider>
          <BrowserRouter>
            <ToastContainer />
            <App />
          </BrowserRouter>
        </CookiesProvider>
      </AccountProvider>
    </AuthProvider>
  </QueryClientProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
