import React from 'react';
import { useAuth } from 'hooks/useAuth';
import UserAvatar from '.';

const CurrentUserAvatar = () => {
  const { user } = useAuth();

  return <UserAvatar userId={user.id} name={user.name} />;
};

export default CurrentUserAvatar;
