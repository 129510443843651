import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

import { useAuth } from 'hooks/useAuth';
import Breadcrumbs from '../../components/Breadcrumb';
import SuccessModal from '../../components/Success-Modal';
import ErrorModal from '../../components/Error-Modal';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import DateInput from '../../components/Date-Input';
import MarkdownInput from '../../components/Markdown-Input';
import SelectInput from '../../components/Select-Input';
import SpinnerInput from '.../../components/Spinner-Input';
import NextSteps from '../../components/Next-Steps';
import SubmitButton from '../../components/Submit-Button';
import ParticipantsSelect from './components/ParticipantsSelect';
import { getFormValue, getEpochDate, loadTeam, loadTasks, normalizeSteps } from '../../utils/';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import '../../assets/scss/custom/components/_dragndrop.scss';

const EditMeeting = () => {
  const url = new URL(window.location.href);
  const meetingParam = url.searchParams.get('id');
  const formConfig = {
    defaultValues: {
      agenda: '',
      date: '',
      description: '',
      participants: [],
      relatedTasks: [],
      videoLink: '',
      steps: [{ assignedTo: '', step: '' }],
      minutes: 60,
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };
  const form = useForm(formConfig);
  const { formState } = form;
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user.account;
  const firebaseHelper = getFirebaseBackend();

  const [updateSuccessfull, setUpdateSuccessfull] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [assignedKey, setAssignedKey] = useState(0);
  const [tasksKey, setTasksKey] = useState(2000);
  const [isSending, setIsSending] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsSending(true);

      const meeting = {
        id: meetingParam,
        accountId,
        agenda: data.agenda,
        description: data.description !== '' ? data.description : null,
        date: getEpochDate(data.date),
        minutes: data.minutes,
        steps: normalizeSteps(data.steps),
        videoLink: data.videoLink,
        participants: data.participants.map(getFormValue),
        relatedTasks: data.relatedTasks ? data.relatedTasks.map(getFormValue) : null,
      };
      await firebaseHelper.updateMeeting(meeting);

      setSuccessModal(true);
      setIsSending(false);
    } catch (error) {
      console.error(error);
      setErrorModal(true);
      setIsSending(false);
    }
  };

  const getMeeting = async () => {
    try {
      const meeting = await firebaseHelper.getMeeting({
        accountId,
        id: meetingParam,
      });

      const handleParticipants = async (personId) => {
        if (personId.indexOf('external:') === -1) {
          return {
            value: personId,
            label: await firebaseHelper.userName(personId),
          };
        }

        return {
          value: personId,
          label: `${personId.replace('external:', '')} (${translate('external')})`,
        };
      };

      const processedParticipants = await Promise.all(meeting.participants.map(handleParticipants));

      const processedTasks = meeting.relatedTasks
        ? await Promise.all(
            meeting.relatedTasks.map(async (taskId) => ({
              value: taskId,
              label: await firebaseHelper.taskName(accountId, taskId),
            })),
          )
        : '';

      const processedSteps = meeting.steps
        ? await Promise.all(
            meeting.steps.map(async ({ assignedTo, step }) => ({
              step,
              assignedTo: {
                value: assignedTo,
                label: await firebaseHelper.userName(assignedTo),
              },
            })),
          )
        : '';

      form.reset({
        agenda: meeting.agenda,
        date: meeting.date ? new Date(meeting.date) : '',
        description: meeting.description,
        participants: processedParticipants,
        relatedTasks: processedTasks,
        minutes: meeting.minutes,
        videoLink: meeting.videoLink,
        steps: processedSteps,
      });
      setUpdateSuccessfull(true);
    } catch (error) {
      console.error(error);
      setErrorModal(true);
    }
  };

  const closeModalHandle = () => {
    setUpdateSuccessfull(false);
    form.reset();
    setSuccessModal(false);
    getMeeting();
  };

  useEffect(() => {
    const initialize = async () => {
      if (user.adminStatus !== 'Admin' && user.adminStatus !== 'Super Admin') {
        navigate('/');
      }

      getMeeting();

      return () => {
        firebaseHelper.removeUnusedMDFiles({
          accountId,
          id: meetingParam,
        });
      };
    };
    initialize();
  }, []);

  useEffect(() => {
    setAssignedKey((prevState) => prevState + 1);
    setTasksKey((prevState) => prevState + 1);
  }, [accountId]);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Edit Meeting')} | Ectools`}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={translate('Meetings')} breadcrumbItem={translate('Edit Meeting')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <SuccessModal
                    isOpen={successModal}
                    onClose={closeModalHandle}
                    verb="Edited"
                    buttonText="See Meetings"
                    buttonLink="/meetings"
                  />

                  <ErrorModal isOpen={errorModal} />

                  <CardTitle className="mb-4">{translate('Create New Meeting Log')}</CardTitle>

                  <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                      <LabelGroup htmlFor="date" label="Date">
                        <DateInput controlName="date" placeholder="Meeting Date" size={4} />
                        <SpinnerInput
                          controlName="minutes"
                          label="Minutes Long"
                          required
                          size={4}
                          min={1}
                        />
                      </LabelGroup>

                      <LabelGroup htmlFor="agenda" label="Meeting Agenda" required>
                        <TextInput controlName="agenda" placeholder="Meeting Agenda" required />
                      </LabelGroup>

                      <LabelGroup htmlFor="description" label="Description" required>
                        <MarkdownInput attachId={meetingParam} controlName="description" required />
                      </LabelGroup>

                      <NextSteps
                        key={assignedKey}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
                      />

                      <ParticipantsSelect accountId={accountId} />

                      <LabelGroup htmlFor="videoLink" label="Video Link">
                        <TextInput
                          controlName="videoLink"
                          placeholder="Type the Meeting Video URL (Vimeo or Youtube)"
                          example="https://vimeo.com/1234567890"
                          // Regex pattern to only match vimeo and youtube URLS
                          validation={{
                            pattern: {
                              value:
                                /(https?:\/\/(.+?\.)?(vimeo|youtube)\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/,
                              message: 'The URL needs to be from Vimeo or Youtube',
                            },
                          }}
                        />
                      </LabelGroup>

                      <LabelGroup htmlFor="relatedTasks" label="Related Tasks">
                        <SelectInput
                          asyncronous
                          isMulti
                          controlName="relatedTasks"
                          key={tasksKey}
                          cacheOptions
                          defaultOptions
                          loadOptions={(inputValue) => loadTasks(accountId, inputValue)}
                        />
                      </LabelGroup>

                      <SubmitButton
                        text="Edit Meeting"
                        disabled={Boolean(
                          Object.values(formState.errors).length || !updateSuccessfull,
                        )}
                        isLoading={isSending}
                      />
                    </form>
                  </FormProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EditMeeting;
