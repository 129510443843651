import styled from 'styled-components';

export const KeywordMonitoringStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .card-keyword-container {
    padding: 1.5rem;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #ffffff;
  }

  .chart-component {
    padding: 1.5rem;
    border: 1px solid #edf2f7;
    border-radius: 0.5rem;
  }

  .chart-subtitle {
    width: 7.8rem;
    padding: 0.5rem;
    gap: 0.75rem;
    border: 1px solid #edf2f7;
    border-radius: 0.25rem;
  }

  .chart-subtitle-color {
    border-radius: 9999px;
    height: 6px;
  }

  .chart-subtitle span:first-child {
    color: #718096;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .chart-subtitle span:last-child {
    color: #2d3748;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .span-title {
    color: #1a202c;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
  }

  .span-subtitle {
    color: #4a5568;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;

    .bold {
      font-weight: 700;
    }
  }

  .span-warning {
    margin: 0 auto;
    padding: 0.5rem 1rem;
    border: 1px solid #b7791f;
    border-radius: 0.5rem;
    background-color: #fff7ea;
    max-width: 38rem;

    span {
      color: #343a40;
      font-size: 0.8125rem;
      font-weight: 500;
    }

    button {
      color: #ffffff;
      background-color: #556ee6;
      height: 1.5rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
    }

    button:hover {
      color: #ffffff;
      background-color: #3b5bfc;
    }
  }

  .text-span {
    color: #343a40;
    font-size: 1.42188rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
