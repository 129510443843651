import styled from 'styled-components';
import { InputGroup } from 'reactstrap';

export const KeywordGroupsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .card-keyword-container {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 2rem;
    border-radius: 0.5rem;
    background: #ffffff;
  }

  .lines-by-page {
    width: 14rem;
    display: flex;
    align-items: center;

    label {
      width: 21rem !important;
      color: #1a202c;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }

    select {
      width: 5rem !important;
    }
  }
`;

export const InputGroupStyled = styled(InputGroup)`
  width: 32rem;
  height: 2.5rem;

  input {
    border-right: none;
  }
  input:focus-within {
    border-color: #ced4da;
  }
  span {
    background-color: #ffffff;
    border-left: none;
  }

  i {
    display: flex;
    font-size: 1.25rem;
    color: #718096;
  }
`;
