import styled from 'styled-components';

export const PerformanceChartStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;

  .container-chart-subtitle {
    height: min-content;
    display: flex;
    gap: 0.75rem;
  }

  .chart-subtitle {
    width: 100%;
    padding: 0.5rem;
    gap: 0.75rem;
    border: 1px solid #edf2f7;
    border-radius: 0.25rem;
  }

  .chart-subtitle-color {
    border-radius: 9999px;
    height: 6px;
  }

  .chart-subtitle span:first-child {
    color: #718096;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .chart-subtitle span:last-child {
    color: #2d3748;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .total-growth {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    gap: 0.25rem;
  }

  .total-growth-positive {
    color: #38a169;
  }

  .total-growth-negative {
    color: #e53e3e;
  }

  canvas {
    width: 100%;
  }
`;
