import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Button, Row, Col, Card, CardBody } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import '../../assets/scss/datatables.scss';

const ListPending = () => {
  const { t: translate } = useTranslation();
  const { SearchBar } = Search;
  const firebaseHelper = getFirebaseBackend();

  const [userData, setUserData] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);

  const actionButtons = (userInfo) => {
    return (
      <div style={{ display: 'flex', gap: '1em' }}>
        <Button
          className="d-flex align-items-center justify-content-center gap-1"
          style={{ border: 'none', backgroundColor: '#C6EEE0' }}
          onClick={(event) => onAcceptClick(userInfo, event)}
        >
          <i className="bx bx-check-circle" style={{ color: '#2B9E74' }} />
          <span style={{ color: '#2B9E74' }}>{translate('Accept')}</span>
        </Button>
        <Button
          className="d-flex align-items-center justify-content-center gap-1"
          style={{ border: 'none', backgroundColor: '#FCD5D5' }}
          onClick={(event) => onDenyClick(userInfo, event)}
        >
          <i className="bx bx-x-circle" style={{ color: '#A47B7B' }} />
          <span style={{ color: '#A47B7B' }}>{translate('Deny')}</span>
        </Button>
      </div>
    );
  };

  const fetchAllUsers = async () => {
    const users = await firebaseHelper.getAllUsers();
    setPendingUsers(users);
  };

  const fetchPending = async () => {
    const newUserData = await Promise.all(
      Object.values(pendingUsers)
        .filter(isPending)
        .map(async (userInfo) => {
          const account = await firebaseHelper.accountName(userInfo.account);

          return {
            id: userInfo.id,
            name: userInfo.name,
            email: userInfo.email,
            account: account,
            createdAt: new Date(userInfo.createdAt).toLocaleDateString(),
            action: actionButtons(userInfo),
          };
        }),
    );
    setUserData(newUserData);
  };

  const pendingTotal = (acc, cur) => (cur.status === 'Pending' ? ++acc : acc);
  const activatedTotal = (acc, cur) => (cur.status === 'Activated' ? ++acc : acc);

  const isPending = (value) => {
    return value.status === 'Pending';
  };

  const onAcceptClick = async (userInfo, event) => {
    const buttonElem = event.target.parentNode;
    const otherButtonElem = buttonElem.nextSibling;
    buttonElem.disabled = true;
    otherButtonElem.disabled = true;
    try {
      await firebaseHelper.activateUser(userInfo.id);
    } catch (error) {
      buttonElem.disabled = false;
      otherButtonElem.disabled = false;
    }
    fetchAllUsers();
  };

  const onDenyClick = async (userInfo, event) => {
    const buttonElem = event.target.parentNode;
    const otherButtonElem = buttonElem.previousSibling;
    buttonElem.disabled = true;
    otherButtonElem.disabled = true;
    try {
      await firebaseHelper.deactivateUser(userInfo.id);
    } catch (error) {
      buttonElem.disabled = false;
      otherButtonElem.disabled = false;
    }
    fetchAllUsers();
  };

  const columns = [
    {
      dataField: 'name',
      text: translate('Name'),
      sort: true,
    },
    {
      dataField: 'email',
      text: translate('Email'),
      sort: true,
    },
    {
      dataField: 'account',
      text: translate('Account'),
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: translate('Register Date'),
      sort: true,
      headerStyle: () => ({
        width: '200px',
        maxWidth: '200px',
      }),
    },
    {
      dataField: 'action',
      text: translate('Action'),
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'asc',
    },
  ];

  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: userData.length },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: userData.length,
    custom: true,
    sizePerPageList,
  };

  useEffect(() => {
    const initialize = async () => {
      if (!pendingUsers) return;

      await fetchPending();
    };
    initialize();
  }, [pendingUsers]);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content" style={{ minHeight: '100vh' }}>
        <MetaTags>
          <title>{translate('List Pending Users')} | Ectools</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12" className="mb-4">
              <h4 className="mb-0 font-size-18">
                {translate('Pending Users')} (
                {pendingUsers !== null ? Object.values(pendingUsers).reduce(pendingTotal, 0) : 0})
              </h4>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={userData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider keyField="id" columns={columns} data={userData} search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2 d-flex justify-content-between">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={translate('Search')}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="8" className="d-flex flex-row-reverse align-items-center">
                                <Link to="/create-user">
                                  <Button
                                    className="d-flex align-items-center justify-content-center gap-1"
                                    style={{
                                      backgroundColor: 'rgba(85, 110, 230, 1)',
                                      borderColor: 'rgba(85, 110, 230, 1)',
                                    }}
                                  >
                                    <i className="bx bx-plus-circle" />
                                    {translate('Create new User')}
                                  </Button>
                                </Link>
                                <Link to="/list-users">
                                  <p className="mx-4 mb-0" style={{ color: '#ADB5BD' }}>
                                    {translate('See all users')} (
                                    {pendingUsers !== null
                                      ? Object.values(pendingUsers).reduce(activatedTotal, 0)
                                      : 0}
                                    )
                                  </p>
                                </Link>
                                <Link to="/list-deactivated">
                                  <p className="mx-4 mb-0" style={{ color: '#ADB5BD' }}>
                                    {translate('Deactivated Users')}
                                  </p>
                                </Link>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    hover
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListPending;
