import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, InputGroup, Input, Button, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import 'boxicons';

const SpinnerInput = ({
  controlName,
  label = false,
  labelTwo,
  min = 0,
  max = Infinity,
  mb = 1,
  required = false,
  size = 12,
  step = 1,
  validation = {},
  freeInput = false,
}) => {
  const { t: translate } = useTranslation();
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const minusHandle = () => {
    if (getValues(controlName) > min) {
      setValue(controlName, getValues(controlName) - step);
    }
  };

  const plusHandle = () => {
    if (freeInput && getValues(controlName) === '') {
      setValue(controlName, 0);
    }

    if (getValues(controlName) < max) {
      setValue(controlName, getValues(controlName) + step);
    }
  };

  const changeHandle = (event) => {
    const value = event.target.value;

    if (freeInput && value === '') {
      setValue(controlName, '');
      return;
    }

    if (!value) {
      setValue(controlName, Number(min));
    }

    if (value >= min && value <= max) {
      setValue(controlName, Number(value));
    }
  };

  const returnEspecificError = () => {
    if (!controlName.includes('.')) {
      return errors[controlName];
    }

    const parts = controlName.split('.');
    const [key, rawIndex] = parts;
    const index = !isNaN(Number(rawIndex)) ? Number(rawIndex) : null;
    const errorKey = parts.slice(2).join('.');

    if (!errors[key] || index === null || !errors[key][index]) {
      return null;
    }

    const specificError = errors[key][index];
    return errorKey ? specificError[errorKey] : specificError;
  };

  useEffect(() => {
    if (!getValues(controlName)) {
      setValue(controlName, 0);
    }
  }, []);

  return (
    <Col lg={size}>
      {labelTwo && (
        <Label htmlFor={controlName} className="col-lg-12 mt-2">
          {`${translate(labelTwo)}${required ? ' *' : ''}`}
        </Label>
      )}
      <Controller
        control={control}
        rules={validation}
        name={controlName}
        render={({ field }) => (
          <Col className={`d-flex mb-${mb}`}>
            <InputGroup style={{ minWidth: '10em', maxWidth: '15em', height: '100%' }}>
              <Button
                className="d-flex justify-content-center align-items-center"
                style={{
                  maxHeight: '3rem',
                  zIndex: 0,
                  borderBlock: returnEspecificError() ? '2px solid red' : 0,
                  borderLeft: returnEspecificError() ? '2px solid red' : 0,
                  borderRight: 0,
                }}
                color="primary"
                onClick={minusHandle}
              >
                <box-icon type="regular" name="minus" color="white" size="1rem" />
              </Button>
              <Input
                {...field}
                id={controlName}
                type="number"
                className="form-control"
                value={field.value}
                style={{
                  maxHeight: '3rem',
                  borderBlock: returnEspecificError() ? '2px solid red' : '1px solid #ced4da',
                  borderInline: 0,
                }}
                onChange={changeHandle}
              />

              <Button
                className="d-flex justify-content-center align-items-center"
                style={{
                  maxHeight: '3rem',
                  zIndex: 0,
                  borderBlock: returnEspecificError() ? '2px solid red' : 0,
                  borderRight: returnEspecificError() ? '2px solid red' : 0,
                  borderLeft: 0,
                }}
                color="primary"
                onClick={plusHandle}
              >
                <box-icon type="regular" name="plus" color="white" size="1rem" />
              </Button>
            </InputGroup>
            {label && (
              <label htmlFor={controlName} style={{ margin: '0 1em', alignSelf: 'center' }}>
                {`${translate(label)}${required ? ' *' : ''}`}
              </label>
            )}
          </Col>
        )}
      />
    </Col>
  );
};

SpinnerInput.propTypes = {
  controlName: PropTypes.string,
  label: PropTypes.string,
  labelTwo: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  mb: PropTypes.number,
  required: PropTypes.bool,
  size: PropTypes.number,
  step: PropTypes.number,
  validation: PropTypes.object,
  freeInput: PropTypes.bool,
};

export default SpinnerInput;
