import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import 'boxicons';

import { ContainerShortcutsStyled } from './styles';

const ContainerShortcuts = ({ user, accountModules }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const userHasContentModule = user.modules?.content;

  const shortcutsData = [
    {
      title: translate('Create Task'),
      icon: 'calendar-edit',
      link: '/tasks-create',
      authorization:
        (user?.modules?.tasks && accountModules?.tasks) || user.adminStatus === 'Super Admin',
    },
    {
      title: translate('Create Meeting'),
      icon: 'bell',
      link: '/create-meeting',
      authorization: user.adminStatus === 'Super Admin',
    },
    {
      title: translate('Create Guideline'),
      icon: 'message-square-edit',
      link: '/create-guideline',
      authorization:
        userHasContentModule && accountModules?.content && user.adminStatus === 'Super Admin',
    },
  ];

  return (
    <ContainerShortcutsStyled>
      <span>{translate('Shortcuts')}</span>
      <div className="d-flex w-100 align-items-center justify-content-center gap-5">
        {shortcutsData.map(
          (button, index) =>
            button.authorization && (
              <Button
                key={`${button.icon}+${index}`}
                className="btn-quick-menu"
                onClick={() => {
                  navigate(button.link);
                }}
              >
                <box-icon name={button.icon} color="#74788d" />
                {button.title}
              </Button>
            ),
        )}
      </div>
    </ContainerShortcutsStyled>
  );
};

ContainerShortcuts.propTypes = {
  user: PropTypes.object,
  accountModules: PropTypes.object,
};

export default ContainerShortcuts;
