import React, { useEffect, useRef, useState } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { MetaTags } from 'react-meta-tags';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { Button, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import { MoonLoader } from 'react-spinners';
import _ from 'lodash';

import Breadcrumbs from '../../components/Breadcrumb';
import Shimmer from '../../components/Shimmer';
import ApprovalChat from './components/Approval-Chat';
import PageLoading from './components/Page-Loading';
import TableResumeReview from './components/Table-Resume-Review';
import ContainerEditContent from './components/Container-Edit-Content';
import { SendCardLayout } from './components/Send-Card/styles';
import { GuidelineLayout } from './styles';
import PerformanceData from './components/Performance-Data';
import TitleGuideline from './components/Title-Guideline';
import {
  guidelineDeliveryTypeOptions,
  guidelineTypeOptions,
  languageOptions,
  monthNumberLabel,
} from '../../constants';

const ReviewContent = () => {
  const firebaseHelper = getFirebaseBackend();

  const { id } = useParams();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const currentAccountId = user?.account;

  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [activeNav, setActiveNav] = useState('1');
  const [isSending, setIsSending] = useState(false);
  const [isCreatingGDocs, setIsCreatingGDocs] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [googleDocId, setGoogleDocId] = useState(null);

  const onSubmit = async () => {
    try {
      setIsSending(true);
      const firebaseToken = await firebaseHelper.getIdToken();

      await axios.post(
        `${process.env.REACT_APP_API_URL}/internalReview`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: process.env.REACT_APP_BUILD_TYPE !== 'production',
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );

      setTimeout(() => {
        setIsSending(true);
        navigate('/guidelines');
      }, 2000);
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const handleCreateGoogleDoc = async () => {
    setIsCreatingGDocs(true);

    const body = {
      accountId: currentAccountId,
      accountName: await getAccountName(),
      assignedTo: await getUserNames(content.assignedTo),
      content: content.content,
      deliveryDate: content.deliveryDate,
      publicationDate: content.publicationDate,
      descriptionSEO: content.descriptionSEO,
      id: id,
      language: getSelectLanguage(content.language),
      primaryKeyword:
        content.primaryKeyword.charAt(0).toUpperCase() + content.primaryKeyword.slice(1),
      secondaryKeywords: content.secondaryKeywords ? content.secondaryKeywords.join(', ') : '',
      productionDate: {
        month: getSelectMonth(Number(content.productionDate.month)),
        year: content.productionDate.year,
      },
      related: content.related.join(', '),
      searchVolume: content.searchVolume,
      totalSize: content.totalSize,
      slug: content.slug,
      synonyms: content.synonyms.join(', '),
      title: content.title,
      titleSEO: content.titleSEO,
      type: getSelectType(content.type),
      gDriveFolders: await getGDriveFolders(),
      deliveryType: getDeliveryType(content.deliveryType),
    };

    try {
      const response = await axios.post(process.env.REACT_APP_GDOCS_FLOW_WEBHOOK, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_GDOCS_FLOW_KEY}`,
        },
      });

      const { data } = response;

      await firebaseHelper.saveGoogleDocLink(currentAccountId, id, data.docsId);

      setGoogleDocId(data.docsId);

      setIsCreatingGDocs(false);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setIsCreatingGDocs(false);
    }
  };

  const getAccountName = async () => {
    const accountName = await firebaseHelper.accountName(currentAccountId);
    return accountName;
  };

  const getGDriveFolders = async () => {
    const folderLink = await firebaseHelper.getGDriveFolders(currentAccountId);
    return folderLink;
  };

  const getUserNames = async (users) => {
    const userPromises = users.map(async (userId) => await firebaseHelper.getUserName(userId));
    const resolvedUserNames = await Promise.all(userPromises);
    const names = resolvedUserNames.join(', ');

    return names;
  };

  const getSelectMonth = (value) => {
    const month = monthNumberLabel.find((month) => month.value === value);
    return month.label;
  };

  const getSelectType = (value) => {
    const type = guidelineTypeOptions.find((type) => type.value === value);
    return type.label;
  };

  const getSelectLanguage = (value) => {
    const language = languageOptions.find((language) => language.value === value);
    return language.label;
  };

  const getDeliveryType = (value) => {
    const deliveryType = guidelineDeliveryTypeOptions.find((type) => type.value === value);
    return deliveryType.label;
  };

  const fetchData = async () => {
    setIsLoading(true);
    const loadedGuideline = await firebaseHelper.getFirebaseGuideline(currentAccountId, id);

    if (loadedGuideline.column !== 'CONTENT_REVIEW') {
      navigate(`/guideline/${id}`);
    }

    setContent(loadedGuideline);
    setGoogleDocId(loadedGuideline.googleDocId);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  useEffect(() => {
    if (!isPageLoading) return;

    const db = getDatabase();
    const guidelineRef = ref(db, `guidelines/${currentAccountId}/${id}`);

    const unsubscribe = onValue(guidelineRef, async (snapshot) => {
      const data = snapshot.val();

      if (!data?.processing) navigate(`/guideline/${id}`);
    });

    return () => {
      off(guidelineRef, 'value', unsubscribe);
    };
  }, [isPageLoading]);

  return (
    <GuidelineLayout>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Content review')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs title={translate('Content')} breadcrumbItem={translate('Content review')} />
        {isPageLoading ? (
          <PageLoading
            title={translate('Recreating content')}
            description={translate(
              'This may take a few minutes, you can also wait for this process in the background and return later.',
            )}
            button={translate('Go back')}
            onClick={() => navigate('/guidelines')}
          />
        ) : (
          <div className="container-row">
            <div className="container-general-information">
              <div className="header-general-information">
                <div>
                  <button
                    aria-label="Back"
                    type="button"
                    id="back-button"
                    className="back-button"
                    onClick={() => navigate('/guidelines')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.21875 7.33117H13.3334V8.6645H5.21875L8.79475 12.2405L7.85208 13.1832L2.66675 7.99783L7.85208 2.8125L8.79475 3.75517L5.21875 7.33117Z"
                        fill="#2D3748"
                      />
                    </svg>
                  </button>
                  {content?.primaryKeyword && <TitleGuideline guideline={content} />}
                </div>
              </div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeNav === '1' ? 'actived' : ''}
                    onClick={() => setActiveNav('1')}
                  >
                    {translate('Summary')}
                  </NavLink>
                </NavItem>
                {!content?.googleDocId && (
                  <NavItem>
                    <NavLink
                      className={activeNav === '2' ? 'actived' : ''}
                      onClick={() => setActiveNav('2')}
                    >
                      {translate('Content')}
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    className={activeNav === '3' ? 'actived' : ''}
                    onClick={() => setActiveNav('3')}
                  >
                    {translate('Performance')}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeNav}>
                {isLoading ? (
                  <>
                    <Shimmer height="25em" style={{ marginBottom: '3em' }} />
                    <Shimmer height="10em" style={{ marginBottom: '3em' }} />
                    <Shimmer height="15em" />
                  </>
                ) : (
                  <TabPane tabId="1">
                    <TableResumeReview
                      data={content}
                      accountId={currentAccountId}
                      guidelineId={id}
                    />
                  </TabPane>
                )}
              </TabContent>
              <TabContent activeTab={activeNav}>
                <TabPane tabId="2">
                  <ContainerEditContent
                    content={content}
                    setIsPageLoading={setIsPageLoading}
                    noIA
                  />
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeNav}>
                <TabPane tabId="3">
                  <PerformanceData
                    primaryKeyword={content?.primaryKeyword}
                    secondaryKeywords={content?.secondaryKeywords}
                  />
                </TabPane>
              </TabContent>
            </div>
            <div className="container-side-column">
              <SendCardLayout>
                <p className="subtitle">{translate('Action')}</p>
                {isLoading ? (
                  <>
                    <Shimmer height="3rem" />
                    <Shimmer height="3rem" />
                  </>
                ) : (
                  <>
                    <Button onClick={onSubmit}>
                      {isSending ? (
                        <MoonLoader color="#fff" size={20} />
                      ) : (
                        translate('Complete review')
                      )}
                    </Button>
                    {googleDocId ? (
                      <Button
                        className="clear"
                        onClick={() =>
                          window.open(`https://docs.google.com/document/d/${googleDocId}/edit`)
                        }
                      >
                        <i className="bx bx-link-external" />
                        {translate('View Google doc')}
                      </Button>
                    ) : (
                      <Button
                        className="clear"
                        onClick={handleCreateGoogleDoc}
                        disabled={isCreatingGDocs}
                      >
                        {isCreatingGDocs ? (
                          <MoonLoader color="#000" size={20} />
                        ) : (
                          <>
                            <i className="bx bx-file" />
                            {translate('Create Google doc')}
                          </>
                        )}
                      </Button>
                    )}
                  </>
                )}
              </SendCardLayout>
              <ApprovalChat />
            </div>
          </div>
        )}
      </div>
    </GuidelineLayout>
  );
};

export default ReviewContent;
