import translationEnUS from './enUS/translation.json';
import common from './enUS/common.json';
import changelogEnUs from './enUS/changelog.json';
import countriesAndLangsEnUS from './enUS/countries-and-langs.json';

export const resourcesEnUS = {
  ...translationEnUS,
  ...changelogEnUs,
  ...countriesAndLangsEnUS,
  ...common,
};
