import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { map, size } from 'lodash';

import { formatDateTimestampNumber } from 'utils';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { TableKeywordsStyled } from './styles';
import Shimmer from 'components/Shimmer';
import ButtonTag from '../Button-Tag';
import ModalEditKeyword from '../Modal-Edit-Keyword';
import ModalDeleteKeyword from '../Modal-Delete-Keyword';
import TitleContents from '../Title-Contents';
import UserAvatar from 'components/UserAvatar';

const TableKeywords = ({
  keywords,
  isLoading,
  fetchGroup,
  fetchLimitUsedFromFirebase,
  groups,
  noEditKeywords,
}) => {
  const { t: translate } = useTranslation();
  const firebaseHelper = getFirebaseBackend();
  const { id: groupId } = useParams();

  const [userNames, setUserNames] = useState([]);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [keywordSelected, setKeywordSelected] = useState(null);

  const fetchUserNames = async () => {
    const names = {};
    for (const keyword of keywords) {
      if (keyword.createdBy && !names[keyword.createdBy]) {
        try {
          const response = await firebaseHelper.getUserName(keyword.createdBy);
          names[keyword.createdBy] = response;
        } catch (error) {
          console.error('Error fetching user: ', error);
        }
      }
    }
    setUserNames(names);
  };

  const toggleEditModal = () => {
    setIsOpenEditModal(!isOpenEditModal);
  };

  const toggleDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  useEffect(() => {
    if (keywords && keywords.length > 0) {
      fetchUserNames();
    }
  }, [keywords]);

  return (
    <TableKeywordsStyled>
      {isLoading ? (
        <Shimmer height="20em" />
      ) : (
        <Table>
          <thead>
            <tr>
              <th>{translate('Keywords')}</th>
              {groupId === 'contents' && (
                <th style={{ width: '24.8rem' }}>{translate('Content')}</th>
              )}
              <th style={{ width: '10.5rem' }}>{'Tags'}</th>
              <th style={{ width: '10.5rem' }}>{translate('Added')}</th>
              <th style={{ width: groupId === 'contents' ? '10.5rem' : '5rem' }}>
                {translate('Creator')}
              </th>
              {!noEditKeywords && <th style={{ width: '10.5rem' }}>{translate('Action')}</th>}
            </tr>
          </thead>
          <tbody>
            {keywords.length === 0 ? (
              <tr className="w-100">
                <td colSpan={5}>
                  <div className="d-flex flex-column gap-2 align-items-center justify-content-center p-4">
                    <svg
                      width="89"
                      height="56"
                      viewBox="0 0 89 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5156 39.147C4.76773 40.8857 0 43.2543 0 45.8647C0 51.201 19.9233 55.5269 44.5 55.5269C69.0767 55.5269 89 51.201 89 45.8647C89 43.2543 84.2323 40.8857 76.4844 39.147V43.359C76.4844 46.2894 74.6488 48.6911 72.382 48.6911H16.618C14.3513 48.6911 12.5156 46.288 12.5156 43.359V39.147Z"
                        fill="black"
                        fillOpacity="0.06"
                      />
                      <path
                        d="M57.8681 22.3699C57.8681 20.1545 59.2504 18.3256 60.965 18.3242H76.4844V43.3588C76.4844 46.2891 74.6487 48.6909 72.382 48.6909H16.618C14.3512 48.6909 12.5156 46.2878 12.5156 43.3588V18.3242H28.035C29.7496 18.3242 31.1319 20.1504 31.1319 22.3657V22.3961C31.1319 24.6115 32.5295 26.4004 34.2428 26.4004H54.7572C56.4705 26.4004 57.8681 24.5949 57.8681 22.3795V22.3699V22.3699Z"
                        fill="black"
                        fillOpacity="0.04"
                        stroke="black"
                        strokeOpacity="0.15"
                        strokeWidth="0.483696"
                      />
                      <path
                        d="M76.4844 18.4764L62.3751 2.60019C61.6979 1.51803 60.7091 0.86377 59.6675 0.86377H29.3325C28.2909 0.86377 27.3021 1.51803 26.6249 2.59881L12.5156 18.4778"
                        stroke="black"
                        strokeOpacity="0.15"
                        strokeWidth="0.483696"
                      />
                    </svg>
                    <span className="text-no-groups">
                      {keywords.length === 0
                        ? translate('No results found')
                        : translate('No groups added')}
                    </span>
                  </div>
                </td>
              </tr>
            ) : (
              keywords
                .filter((keyword) => keyword.name !== 'Desagrupado')
                .map((keyword, index) => {
                  return (
                    <tr key={`${keyword.id}${index}`}>
                      <td>{keyword.name.toLowerCase()}</td>
                      {groupId === 'contents' && (
                        <td>
                          <TitleContents guideline={keyword} />
                        </td>
                      )}
                      <td>
                        <div className="d-flex flex-wrap gap-2">
                          {keyword?.tags?.map((tag) => {
                            const tagTranslated =
                              tag === 'primary'
                                ? translate('Primary')
                                : tag === 'secondary'
                                  ? translate('Secondary')
                                  : tag;
                            return (
                              <ButtonTag
                                key={`${keyword.id}${tag}`}
                                tagName={tagTranslated}
                                isStatic
                              />
                            );
                          })}
                        </div>
                      </td>
                      <td>
                        {formatDateTimestampNumber(keyword.createdAt, translate('date-enUS'))}
                      </td>
                      <td>
                        <div className="avatar-element">
                          {groupId !== 'contents' && typeof keyword?.createdBy === 'string' ? (
                            <div className="avatar-group">
                              <div className="avatar-group-item">
                                <div
                                  alt={`${translate('Photo of')} ${userNames[keyword?.createdBy]}`}
                                  id={`card-${keyword?.createdBy}-${keyword?.id}`}
                                >
                                  <UserAvatar userId={keyword?.createdBy} />
                                </div>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`card-${keyword?.createdBy}-${keyword?.id}`}
                                >
                                  {userNames[keyword?.createdBy]}
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          ) : (
                            <div className="avatar-group">
                              {map(keyword.createdBy, (member, index) => {
                                if (!(index < 2)) return;
                                return (
                                  <div className="avatar-group-item" key={index}>
                                    <div
                                      alt={`${translate('Photo of')} ${userNames[keyword?.createdBy]}`}
                                      id={`card-${member}-${keyword?.id}`}
                                    >
                                      <UserAvatar userId={keyword?.createdBy} />
                                    </div>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`card-${member}-${keyword?.id}`}
                                    >
                                      {userNames[keyword?.createdBy[index]]}
                                    </UncontrolledTooltip>
                                  </div>
                                );
                              })}
                              {size(keyword?.createdBy) > 2 && (
                                <div className="avatar-group-item">
                                  <div className="avatar-xs">
                                    <span
                                      className="avatar-title rounded-circle text-white font-size-16"
                                      style={{ backgroundColor: 'black' }}
                                    >
                                      {`${size(keyword?.createdBy) - 2}+`}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </td>
                      {!noEditKeywords && (
                        <td>
                          <div className="d-flex align-items-center justify-content-center w-100 gap-2">
                            <Button
                              className="btn-action btn-action-edit"
                              onClick={() => {
                                toggleEditModal();
                                setKeywordSelected(keyword);
                              }}
                            >
                              <i className="bx bx-pencil" />
                            </Button>
                            <Button
                              className="btn-action btn-action-delete"
                              onClick={() => {
                                toggleDeleteModal();
                                setKeywordSelected(keyword);
                              }}
                            >
                              <i className="bx bx-trash" />
                            </Button>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })
            )}
          </tbody>
        </Table>
      )}
      <ModalEditKeyword
        isOpen={isOpenEditModal}
        toggle={toggleEditModal}
        keyword={keywordSelected}
        idSelected={keywordSelected?.id}
        fetchGroup={fetchGroup}
        groups={groups}
      />
      <ModalDeleteKeyword
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        keyword={keywordSelected}
        fetchGroup={fetchGroup}
        fetchLimitUsedFromFirebase={fetchLimitUsedFromFirebase}
      />
    </TableKeywordsStyled>
  );
};

TableKeywords.propTypes = {
  keywords: PropTypes.array,
  isLoading: PropTypes.bool,
  fetchGroup: PropTypes.func,
  fetchLimitUsedFromFirebase: PropTypes.func,
  groups: PropTypes.array,
  noEditKeywords: PropTypes.bool,
};

export default TableKeywords;
