import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CardAssignedDiv, SelectAssignedToDiv } from './styles';
import UserAvatar from 'components/UserAvatar';

const CardAssignedTo = ({
  assignedTo,
  setAssignedTo,
  title,
  teamData,
  isLoading = true,
  isMulti = false,
}) => {
  const { t: translate } = useTranslation();

  const handleAssignedToClick = (userId) => {
    const clickedUser = teamData.find((user) => user.value === userId);

    if (!isMulti) {
      setAssignedTo([clickedUser]);
    } else {
      if (!assignedTo.some((user) => user.value === userId)) {
        setAssignedTo([...assignedTo, clickedUser]);
      } else {
        setAssignedTo(assignedTo.filter((user) => user.value !== userId));
      }
    }
  };

  return (
    <CardAssignedDiv className="card-assigned" hasResponsibles={assignedTo.length || isLoading}>
      <p className="subtitle m-0">{title}</p>
      <div className="d-flex align-items-center">
        <UncontrolledDropdown>
          <div className="d-flex align-items-center">
            <div className="avatar-group">
              {_.map(assignedTo, (member, index) => {
                return (
                  <div className="avatar-group-item" key={index}>
                    <div
                      alt={`${translate('Photo of')} ${assignedTo[index]}`}
                      id={`guideline-${member.value}`}
                    >
                      <UserAvatar userId={member.value} />
                    </div>
                    <p className="avatar-name">{member.label}</p>
                  </div>
                );
              })}
            </div>
          </div>
          {(assignedTo.length > 0 || isLoading) && (
            <>
              <DropdownToggle
                className="dropdown-include"
                style={{ backgroundColor: 'transparent', border: 'none' }}
                caret
              >
                <p>{translate('Add')}</p>
                <i className="bx bx-plus" />
              </DropdownToggle>
              <DropdownMenu style={{ boxShadow: 'none', border: '1px solid #E2E8F0' }}>
                {assignedTo &&
                  teamData?.map((user, index) => {
                    const isUserAssigned = assignedTo
                      .map((user) => user.value)
                      .includes(user.value);

                    return (
                      <DropdownItem
                        key={user.value}
                        onClick={() => {
                          handleAssignedToClick(user.value);
                        }}
                        toggle={false}
                      >
                        <SelectAssignedToDiv>
                          <div>
                            <div className="avatar-group-item" key={index}>
                              <div
                                alt={`${translate('Photo of')} ${user.label}`}
                                id={`guideline-${user.value}`}
                              >
                                <UserAvatar userId={user.value} />
                              </div>
                            </div>
                            <div>{user.label}</div>
                          </div>
                          <div>
                            <span className={isUserAssigned ? 'remove' : 'add'}>
                              {isUserAssigned ? translate('Remover') : translate('Adicionar')}
                            </span>
                          </div>
                          <div>{user.label}</div>

                          <div>
                            <span className={isUserAssigned ? 'remove' : 'add'}>
                              {isUserAssigned ? translate('Remover') : translate('Adicionar')}
                            </span>
                          </div>
                        </SelectAssignedToDiv>
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </>
          )}
          {assignedTo.length === 0 && !isLoading && (
            <div className="error-text">
              * {translate('You must have at least one user assigned as responsible to approval')}
            </div>
          )}
        </UncontrolledDropdown>
      </div>
    </CardAssignedDiv>
  );
};

CardAssignedTo.propTypes = {
  assignedTo: PropTypes.array,
  setAssignedTo: PropTypes.func,
  title: PropTypes.string,
  teamData: PropTypes.array,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default CardAssignedTo;
