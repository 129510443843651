import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { Input, InputGroupText, Label } from 'reactstrap';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useAuth } from 'hooks/useAuth';

import Breadcrumbs from './components/Breadcrumb';
import ButtonAddKeyword from './components/Button-Add-Keyword';
import { InputGroupStyled, KeywordGroupsStyled } from './keywordGroupsStyles';
import TableKeywords from './components/Table-Keywords';
import CustomPagination from './components/Pagination';

const ModalAddKeyword = lazy(() => import('./components/Modal-Add-Keyword'));

const keywordGroup = () => {
  const { t: translate } = useTranslation();
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const { id: groupId } = useParams();
  const accountId = user.account;
  const isAdminStatus = ['Super Admin', 'Admin'].includes(user.adminStatus);
  const navigate = useNavigate();
  const location = useLocation();
  const [group, setGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [limit, setLimit] = useState(0);
  const [limitUsed, setLimitUsed] = useState(0);
  const [linesByPage, setLinesByPage] = useState(() => {
    return parseInt(localStorage.getItem('linesByPage')) || 10;
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchGroup = async () => {
    try {
      setIsLoading(true);
      // const response =
      //   groupId !== 'contents'
      //     ? await firebaseHelper.getKeywordGroup(accountId, groupId, linesByPage, currentPage)
      //     : await firebaseHelper.getKeywordGroupContent(accountId, linesByPage, currentPage);

      const response = await firebaseHelper.getKeywordGroup(
        accountId,
        groupId,
        linesByPage,
        currentPage,
      );

      if (filterValue) {
        const filteredKeywords = response.keywords.filter((keyword) =>
          keyword.name.toLowerCase().includes(filterValue.toLowerCase()),
        );

        setGroup({ ...response, keywords: filteredKeywords });

        setIsLoading(false);
        return;
      }
      setTotalPages(response.totalPages);
      setGroup(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching group: ', error);
    }
  };

  const fetchGroups = async () => {
    try {
      const responseGroups = await firebaseHelper.getKeywordGroupsIdAndName(accountId);
      setGroups(responseGroups);
    } catch (error) {
      console.error('Error fetching groups: ', error);
    }
  };

  const handleInputChange = (e) => {
    setFilterValue(e.target.value);
  };

  const setGroupAsDefault = async () => {
    try {
      await firebaseHelper.setGroupAsDefault(accountId, groupId);
      fetchGroup();
    } catch (error) {
      console.error('Error setting group as default: ', error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}&limit=${linesByPage}`);
  };

  const handleLinesByPageChange = (e) => {
    const newLinesByPage = parseInt(e.target.value);
    setLinesByPage(newLinesByPage);
    setCurrentPage(1);
    localStorage.setItem('linesByPage', newLinesByPage);
    navigate(`?page=1&limit=${newLinesByPage}`);
  };

  const fetchLimitUsedFromFirebase = async () => {
    try {
      const limit = await firebaseHelper.getUsedLimitKeyword(accountId);

      setLimitUsed(limit);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGroup();
  }, [linesByPage, currentPage]);

  useEffect(() => {
    fetchGroup();
  }, [filterValue]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page')) || 1;
    const limit = parseInt(query.get('limit')) || 10;
    setCurrentPage(page);
    setLinesByPage(limit);
    fetchGroup();
  }, [location.search]);

  useEffect(() => {
    const initialize = async () => {
      if (!isAdminStatus) {
        navigate('/');
      }

      const account = await firebaseHelper.getAccount(accountId);

      if (!account.modules?.keywordReport) {
        navigate('/');
      }

      setLimit(account?.limits.keywordReport || 0);
      fetchLimitUsedFromFirebase();
      fetchGroup();
      fetchGroups();
      navigate(`?page=${currentPage}&limit=${linesByPage}`);
    };

    initialize();
  }, [user, navigate]);

  return (
    <KeywordGroupsStyled>
      <div className="page-content">
        <MetaTags>
          <title>{`${group?.name ? group?.name : translate('Contents')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs
          separatedTitle={group?.name ? group?.name : translate('Contents')}
          title={translate('Keywords')}
          breadcrumbItem={translate('Groups')}
          showLimit={groupId === 'contents' ? false : true}
          isDefaultGroup={group?.isDefault}
          showDefaultGroupButton={
            group.name === 'Desagrupado' ? false : groupId === 'contents' ? false : true
          }
          setGroupAsDefault={setGroupAsDefault}
          limit={limit}
          limitUsed={limitUsed}
        />
        <div className="d-flex flex-column gap-4 mt-4">
          <div className="card-keyword-container">
            <div className="d-flex align-items-center justify-content-between w-100">
              <InputGroupStyled>
                <Input
                  type="text"
                  placeholder={translate('Search')}
                  onChange={(e) => handleInputChange(e)}
                />
                <InputGroupText>
                  <i className="bx bx-search" />
                </InputGroupText>
              </InputGroupStyled>
              {groupId !== 'contents' && <ButtonAddKeyword onClickFunction={toggleModal} />}
            </div>
            <TableKeywords
              keywords={group?.keywords}
              isLoading={isLoading}
              fetchGroup={fetchGroup}
              fetchLimitUsedFromFirebase={fetchLimitUsedFromFirebase}
              groups={groups}
              noEditKeywords={groupId === 'contents' ? true : false}
            />
            <div className="d-flex w-100 align-items-center justify-content-between">
              <div className="lines-by-page">
                <Label className="w-100" htmlFor="linesByPage">
                  {translate('Lines by page')}:
                </Label>
                <Input
                  type="select"
                  name="select"
                  id="linesByPage"
                  value={linesByPage}
                  onChange={handleLinesByPageChange}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Input>
              </div>
              <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                linesByPage={linesByPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {isOpenModal && (
          <ModalAddKeyword
            isOpen={isOpenModal}
            toggle={toggleModal}
            innerGroupId={groupId}
            fetchGroup={fetchGroup}
            limit={limit}
          />
        )}
      </Suspense>
    </KeywordGroupsStyled>
  );
};

export default keywordGroup;
